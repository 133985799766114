import { STORAGE, STORAGE_SETTINGS } from '../constants/keys';

export const setLocalStorage = (key, value) => {
    try {
        localStorage.setItem(key, value);
    } catch (err) {
        console.log(err);
    }
};

export const getLocalStorage = (key) => {
    try {
        const value = localStorage.getItem(key);
        if (value === null) return undefined;
        return value;
    } catch (err) {
        console.log(err);
        return undefined;
    }
};

export const deleteFromLocalStorage = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (err) {
        console.log(err);
    }
};

//#region User
export const getCurrentUser = () => getLocalStorage(STORAGE.USER);

export const setUserInfo = (value) => {
    setLocalStorage(STORAGE.USER, value);
};

export const isUserTokenExpired = () => {
    const user = getCurrentUser();
    if (!user) return false;

    const expires = user.expires;
    const now = +new Date();

    return expires - now <= 10000;
};

export const isUserLoggedIn = () => !!getLocalStorage(STORAGE.USER);

export const deleteUserInfo = () => deleteFromLocalStorage(STORAGE.USER);

//#endregion

//#region Language
export const setLangLocalStorage = (value) => 
{
    setLocalStorage(STORAGE.LANGUAGE, value);
}

export const getLangLocalStorage = () => getLocalStorage(STORAGE.LANGUAGE);
//#endregion

//#region Theme
export const setThemeLocalStorage = (value) =>
{
    setLocalStorage(STORAGE.THEME, value.replace('-theme', '') + '-theme');
}

export const getThemeLocalStorage = () => getLocalStorage(STORAGE.THEME);
//#endregion

//#region ThemeColor
export const setThemeColorLocalStorage = (value) =>
{
    setLocalStorage(STORAGE_SETTINGS.THEME_COLOR, value);
}

export const getThemeColorLocalStorage = () => getLocalStorage(STORAGE_SETTINGS.THEME_COLOR);
//#endregion

//#region GameId
export const setGameIdLocalStorage = (value) => 
{
    setLocalStorage(STORAGE.GAMEID, value);
}

export const getGameIdLocalStorage = () => getLocalStorage(STORAGE.GAMEID);
//#endregion

//#region Currency
export const setCurrencyLocalStorage = (value) =>
{
    setLocalStorage(STORAGE.CURRENCY, value);
}

export const getCurrencyLocalStorage = () => getLocalStorage(STORAGE.CURRENCY);
//#endregion

export const setBetSizeLocalStorage = (value) => 
{
    setLocalStorage(STORAGE.BETSIZE, value);
}

export const getBetSizeLocalStorage = () => getLocalStorage(STORAGE.BETSIZE);

export const setSuitLocalStorage = (value) => 
{
    setLocalStorage(STORAGE.SUIT, value);
}

export const getSuitLocalStorage = () => getLocalStorage(STORAGE.SUIT);