import React from 'react'

import classNames from 'classnames'

import { getSuitFromString } from '../../../../common/helpers/common'
import Card from '../../../common/Card'
import ImgIcon from '../../../common/ImgIcon'
import { isDesktop } from '../../../../common/helpers/common';

const CardFlip = ({ flipCardRef, cardAnimate, gameContainerThemes, oldCardNumber, card, theme, themeColor, cardNumber, getRank, oldCard }) => {
    const height = isDesktop() ? 105 : 59;
    const width = isDesktop() ? 75 : 42;
    const size = isDesktop() ? 40 : 25;
    return (
        <div className='card-display'>
            <div ref={flipCardRef} className={
                classNames(
                    'flip-card',
                    cardAnimate? 'active' : ''
                )
            }>
                <div className='flip-card-inner'>
                    <ImgIcon
                        icon={gameContainerThemes.SUITS.BACK}
                        alt={oldCardNumber}
                        width={width}
                        height={height}
                        containerClassName='card-back flip-card-front'
                        textCenter={true}
                        >
                            <span className='content number-bg'>
                                {oldCardNumber}
                            </span>
                        </ImgIcon>
                    { card.suit && <Card
                        theme={theme}
                        themeColor={themeColor}
                        cardWidth={width}
                        cardHeight={height}
                        size={size}
                        suit={getSuitFromString(card.suit)}
                        containerClassName='card-front white flip-card-back'
                        >
                            {getRank(card.rank)}
                        </Card> }
                </div>
            </div>
            <ImgIcon
                icon={gameContainerThemes.SUITS.BACK}
                alt={cardNumber}
                width={width}
                height={height}
                containerClassName='card-back'
                textCenter={true}
                >
                    <span className='content number-bg'>
                        {cardNumber}
                    </span>
                </ImgIcon>
            { oldCard !== false && <Card
                theme={theme}
                themeColor={themeColor}
                cardWidth={width}
                cardHeight={height}
                size={size}
                suit={getSuitFromString(oldCard.suit)}
                containerClassName='card-front white'
                >
                    {getRank(oldCard.rank)}
                </Card> }
        </div>
    )
}

export default CardFlip
