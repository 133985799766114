import { useEffect, useState } from 'react';
import useApi from './hooks/useApi';
import { API } from '../common/constants/api';
import { useStore } from '../store/StateProvider';
import { GAME } from '../common/constants/game/engine';

const { HOST, API_BASE, CLIENT } = API;

export function useTransactionInfo(token, page) {
    const [store] = useStore();
    const { game } = store;
    const [trigger, setTrigger] = useState();

    const { loading, error, response } = useApi({
        method: 'GET',
        trigger: trigger,
        url: `${HOST}${API_BASE}${CLIENT.TRANSACTIONS}?token=${encodeURIComponent(token)}&page=${encodeURIComponent(page)}`
    });

    useEffect(() => {
        if(page || game.gameState === GAME.GAME_STATE.ENDED)
            setTrigger(new Date());

    },[game.gameState, page]);

    return { error, loading, response };
}