import React, {useState} from 'react'

import classNames from 'classnames'

import SimpleModal from '../../common/SimpleModal'
import ImgIcon from '../../common/ImgIcon'
import { ICONS } from '../../../common/constants/iconWrapper'
import Results from '../../results/Results'

const RecentModal = ({ visible, theme, themeColor, engine, onClose }) => {
    const [trigger, setTrigger] = useState();

    const onRefresh = () => {
        setTrigger(new Date());
    }

    return (
        <SimpleModal
            containerClassName={
                classNames(
                    "gameSetting__container",
                    "recent-modal",
                    visible ? 'active' : ''
                )}
            title="RECENTS"
        >
            <Results 
                 theme={theme}
                 themeColor={themeColor}
                 engine={engine}
                 isModal={true}
                 trigger={trigger}
            />
            <div className='buttons__container'>
                <ImgIcon
                    icon={ICONS.THEME.CAR.COMMON.REFRESH}
                    alt="refresh"
                    containerClassName={"refresh-button__container"}
                    width="55"
                    height="55"
                    onClick={onRefresh}
                />

                <ImgIcon
                    onClick={onClose}
                    icon={ICONS.THEME.CAR.GAME.SETTING.CLOSE}
                    alt="close"
                />
            </div>
        </SimpleModal>
    )
}

export default RecentModal
