export const GAME = {
    ASSETS_LOADED: 'ASSETS_LOADED',
    LOAD_IMAGE: 'LOAD_IMAGE',
    PLAY_SOUND: 'PLAY_SOUND',
    STOP_SOUND: 'STOP_SOUND',
    BUY_AMOUNT: 'BUY_AMOUNT',
    BUY_CARD: 'BUY_CARD',
    CANCEL_BUY: 'CANCEL_BUY',
    PLACE_BUY: 'PLACE_BUY',
    SET_GAME_DATA: 'SET_GAME_DATA',
    SET_GAME_STORAGE: 'SET_GAME_STORAGE',
    BET_DETAIL: "BET_DETAIL",
    LAST_BET_DETAIL: "LAST_BET_DETAIL",

    CONFIG_DONE: 'CONFIG_DONE',
    GAME_JOINED: 'GAME_JOINED',
    BET_QUEUED: 'BET_QUEUED',
    BET_PLACED: 'BET_PLACED',
    BET_PLACING: 'BET_PLACING',
    BET_CONFIRMED_MSG: 'BET_CONFIRMED_MSG',
    PLAYER_BET: 'PLAYER_BET',
    CASHED_OUT: 'CASHED_OUT',
    WIN_POPUP: 'WIN_POPUP',
    CANCEL_BET: 'CANCEL_BET',
    UPDATE_BALANCE: 'UPDATE_BALANCE',

    UPDATE_THEME_COLOR: 'UPDATE_THEME_COLOR',

    ERROR_REDIRECT: {
        SERVER_MAINTENANCE_REMINDER: 'SERVER_MAINTENANCE_REMINDER',
        SERVER_MAINTENANCE_NOW: 'SERVER_MAINTENANCE_NOW',
        SHUT_DOWN: 'SHUT_DOWN',
        SHUTTING_DOWN: 'SHUTTING_DOWN',
        DISCONNECT: 'DISCONNECT',
    },

    GAME_CONNECTION: {
        GAME_CONNECTED: 'GAME_CONNECTED',
        GAME_DISCONNECTED: 'GAME_DISCONNECTED',
        GAME_ENTER : 'GAME_ENTER',
    },
    
    GAME_STATE: {
        STARTING: 'STARTING',
        STARTED: 'STARTED',
        ENDED: 'ENDED',
    },

    GAME_STATUS: {
        STARTING: "Starting",
        STARTED: "Started",
        COMPLETED: "Completed",
        VOIDED: "Voided"
    },

    SOCKET: {
        GAME_TICK: 'GAME_TICK',
    },

    //message
    MESSAGE: {
        WINNING: 'WINNING',
        INSUFFICIENT_BALANCE: 'INSUFFICIENT_BALANCE',
        ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',
        COUNTDOWN: 'COUNTDOWN',
        BUY_ACTION_ERROR: 'BUY_ACTION_ERROR'
    },
    MESSAGE_CLEAR: 'MESSAGE_CLEAR',
}