export const ACTIONS = {
    PAGE_URL: 'PAGE_URL',
    ERROR_REDIRECT_STATUSES: 'ERROR_REDIRECT_STATUSES',
    REDIRECT: 'REDIRECT',
    CLEAR_ERROR: 'CLEAR_ERROR',
    
    VERIFY_THIRD_PARTY_USER_SUCCESS: 'VERIFY_THIRD_PARTY_USER_SUCCESS',
    VERIFY_THIRD_PARTY_USER_FAILURE: 'VERIFY_THIRD_PARTY_USER_FAILURE',

    // settings
    ENABLE_SETTING: 'ENABLE_SETTING',
    DISABLE_SETTING: 'DISABLE_SETTING',
    ENABLE_SOUND: 'ENABLE_SOUND',
    DISABLE_SOUND: 'DISABLE_SOUND',
    SET_THEME_COLOR: 'SET_THEME_COLOR',
    SET_TEMP_THEME_COLOR: 'SET_TEMP_THEME_COLOR',
    ENABLE_PANEL: 'ENABLE_PANEL',
    DISABLE_PANEL: 'DISABLE_PANEL',
    SET_SETTINGS_STORAGE: 'SET_SETTINGS_STORAGE',
    SAVE_SETTING: 'SAVE_SETTING',
    OPEN_HISTORY_MODAL: 'OPEN_HISTORY_MODAL',
    CLOSE_HISTORY_MODAL: 'CLOSE_HISTORY_MODAL',
    OPEN_HELP_MODAL: 'OPEN_HELP_MODAL',
    CLOSE_HELP_MODAL: 'CLOSE_HELP_MODAL',
    OPEN_RECENT_MODAL: 'OPEN_RECENT_MODAL',
    CLOSE_RECENT_MODAL: 'CLOSE_RECENT_MODAL',
};