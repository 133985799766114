export const STORAGE = {
    USER: 'id',
    GAMEID: 'gameId',
    THEME: 'theme',
    LANGUAGE: 'lang',
    ANONYMOUS: 'anonymous',
    MINBUY: 'minBuy',
    MAXBUY: 'maxBuy',
    INCREMENT: 'increment',
    QUICKBUYBUTTON1: 'quickBuyButton1',
    QUICKBUYBUTTON2: 'quickBuyButton2',
    QUICKBUYBUTTON3: 'quickBuyButton3',
    QUICKBUYBUTTON4: 'quickBuyButton4',
    QUICKBUYBUTTON5: 'quickBuyButton5',
    CURRENCY: 'currency',
    BACKURL: 'backUrl',

    SUIT: 'betSuit',
    BETSIZE: 'betSize',
    ACCOUNT_LOCKED: 'accountLocked',
    CURRENCY_MULTIPLIER: 'currencyMultiplier',
    IS_CHINA: 'isChina',
    COLOR_ODDS: 'colorOdds',
    SUIT_ODDS: 'suitOdds',
    MULTI_BET: 'multiBet'
};

export const STORAGE_SETTINGS = {
    THEME_COLOR: 'themeColor',
    SOUND_ENABLE: 'soundEnable',
}

export const ERROR = {
    MAINTENANCE: 'maintenance',
    ERROR: 'error',
    LOADING: 'loading',
};

export const THEME_CLASS = {
    DEFAULT: 'card-theme',
    CAR: 'car-theme',
    OKBET: 'okbet-theme'
};

export const THEME_ENUM = {
    DEFAULT: 'default',
    BLACK: 'black',
    BLUE: 'blue',
    BROWN: 'brown',
    GREEN: 'green',
    PINK: 'pink',
}

export const CARD = {
    CLUB: 'CLUB',
    DIAMOND: 'DIAMOND',
    HEART: 'HEART',
    SPADE: 'SPADE',
};

export const CARD_COLOR = {
    RED: 'red',
    BLUE: 'blue',
}

export const BUY_AMOUNT_COIN_COLOR = {
    BLUE: 'BLUE',
    ORANGE: 'ORANGE',
    PURPLE: 'PURPLE',
    GREEN: 'GREEN',
    RED: 'RED',
};

export const GAME_RESULT = {
    WIN: 'win',
    LOSE: 'lose',
    DEFAULT: 'default'
};

export const FOOTER_ITEM = {
    HOME: 'HOME',
    RESULTS: 'RESULTS',
    TRANSACTIONS: 'TRANSACTIONS',
    HELP: 'HELP'
};

export const SETTINGS = {
    GAME: {
        CAR: {
            COLOR: {
                CLUB: '#7F8D9F',
                DIAMOND: '#7F8D9F',
                HEART: '#7F8D9F',
                SPADE: '#7F8D9F',
            }
        },
        WON_MSG_INTERVAL: 3000,
        TOTAL_CARD_TO_WIN: 9,
        GAME_HISTORY_RESULT_LIMIT: 50,
        COUNTDOWN_TIME: 5000,
        TICK_RATE: 1000,
        TOP_PREDICTING_LAPSE: 4000,
        SUIT_TOTAL: 4,
        CARD_TOTAL: 52,
        BET_BUTTON: {
            INITIAL_DISABLE_TIME: 1000,
        }
    }
}

export const CAR_COLOR = {
    BLACK: 'BLACK',
    RED: 'RED',
}