import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '../../common/Card';
import { CARD, THEME_CLASS } from '../../../common/constants/keys';
import './CardSelect.less';

const CardSelect = ({
    theme,
    themeColor='',
    disabled=false,
    onClick,
    selected
}) => {
    const width = (theme === THEME_CLASS.CAR || theme === THEME_CLASS.OKBET)? 36 : 34;
    const height = (theme === THEME_CLASS.CAR || theme === THEME_CLASS.OKBET)? 50 : 47;

    const clicked = (card) => {
        if (disabled) return;
        onClick(card);
    }

    return (
        <div className='cardSelect__container'>
        <Card 
            theme={theme}
            themeColor={themeColor}
            cardWidth={width}
            cardHeight={height}
            suit={CARD.SPADE} 
            onClick={() => clicked(CARD.SPADE)}
            selected={selected === CARD.SPADE}>3.8 : 1</Card>
        <Card 
            theme={theme}
            themeColor={themeColor}
            cardWidth={width}
            cardHeight={height}
            suit={CARD.HEART} 
            onClick={() => clicked(CARD.HEART)}
            selected={selected === CARD.HEART}>3.8 : 1</Card>
        <Card 
            theme={theme}
            themeColor={themeColor}
            cardWidth={width}
            cardHeight={height}
            suit={CARD.CLUB} 
            onClick={() => clicked(CARD.CLUB)}
            selected={selected === CARD.CLUB}>3.8 : 1</Card>
        <Card 
            theme={theme}
            themeColor={themeColor}
            cardWidth={width}
            cardHeight={height}
            suit={CARD.DIAMOND} 
            onClick={() => clicked(CARD.DIAMOND)}
            selected={selected === CARD.DIAMOND}>3.8 : 1</Card>
        </div>
    )
};

CardSelect.prototype = {
    theme: PropTypes.string.isRequired,
    themeColor: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.string,
}

export default CardSelect;