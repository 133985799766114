import { BrowserRouter } from 'react-router-dom';
import React from 'react';

import { SiteProvider } from '../provider/site/SiteProvider';
import { ImageProvider } from '../provider/image/ImageProvider';
import { AppProvider } from '../provider/app/AppProvider';
import { StateProvider } from '../../store/StateProvider';
import appReducer from '../../store/appReducer';
import AppRoutes from './routes/AppRoutes';
import '../../common/assets/styles/styles.less';

const App = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <StateProvider reducer={appReducer} initialState={{}}>
      <SiteProvider>
        <AppProvider>
          <ImageProvider>
            <AppRoutes />
          </ImageProvider>
        </AppProvider>
      </SiteProvider>
    </StateProvider>
  </BrowserRouter>
);

export default App;
