export const SPRITES = () => [
    { id:"suit-club", texId:"club", zIndex:2, scaleDivideBy:35, rendererEnable:true },
    { id:"suit-heart", texId:"heart", zIndex:2, scaleDivideBy:35, rendererEnable:true },
    { id:"suit-spade", texId:"spade", zIndex:2, scaleDivideBy:35, rendererEnable:true },
    { id:"suit-diamond", texId:"diamond", zIndex:2, scaleDivideBy:35, rendererEnable:true },

    { id:"arrow-up", texId:"arrow-up", zIndex:2, scaleDivideBy:40, visibility:false },
    { id:"arrow-down", texId:"arrow-down", zIndex:2, scaleDivideBy:40, visibility:false },

    { id:"background0", texId:"bg", zIndex:0, scaleDivideBy:1 },
    { id:"desktop-background0", texId:"desktop-bg", zIndex:0, scaleDivideBy:1 },
    { id:"start-line", texId:"start-line", zIndex:1, scaleDivideBy:1 },
    { id:"desktop-start-line", texId:"desktop-start-line", zIndex:1, scaleDivideBy:1 },

    { id:"ind-bg-blue", texId:"history-bg-blue", zIndex:1, scaleDivideBy:11, rendererEnable:false },
    { id:"ind-bg-brown", texId:"history-bg-brown", zIndex:1, scaleDivideBy:11, rendererEnable:false },
    { id:"ind-bg-green", texId:"history-bg-green", zIndex:1, scaleDivideBy:11, rendererEnable:false },
    { id:"ind-bg-black", texId:"history-bg-black", zIndex:1, scaleDivideBy:11, rendererEnable:false },
    { id:"ind-bg-pink", texId:"history-bg-pink", zIndex:1, scaleDivideBy:11, rendererEnable:false },
    { id:"ind-flag", texId:"history-flag", zIndex:2, scaleDivideBy:22, rendererEnable:false },
    
    { id:"history-bg-blue", texId:"history-bg-blue", zIndex:1, scaleDivideBy:11, rendererEnable:false },
    { id:"history-bg-brown", texId:"history-bg-brown", zIndex:1, scaleDivideBy:11, rendererEnable:false },
    { id:"history-bg-green", texId:"history-bg-green", zIndex:1, scaleDivideBy:11, rendererEnable:false },
    { id:"history-bg-black", texId:"history-bg-black", zIndex:1, scaleDivideBy:11, rendererEnable:false },
    { id:"history-bg-pink", texId:"history-bg-pink", zIndex:1, scaleDivideBy:11, rendererEnable:false },
    { id:"history-cover", texId:"history-cover", zIndex:3, scaleDivideBy:11, rendererEnable:false },

    
    // DEFAULT SIZE
    { id:"CLUB", texId:"car-club", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART", texId:"car-heart", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE", texId:"car-spade", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND", texId:"car-diamond", zIndex:1, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-booster-big", texId:"yellow-booster-big", zIndex:0, scaleDivideBy:1, rendererEnable:false },
    { id:"HEART-booster-big", texId:"green-booster-big", zIndex:0, scaleDivideBy:1, rendererEnable:false },
    { id:"SPADE-booster-big", texId:"blue-booster-big", zIndex:0, scaleDivideBy:1, rendererEnable:false },
    { id:"DIAMOND-booster-big", texId:"red-booster-big", zIndex:0, scaleDivideBy:1, rendererEnable:false },

    { id:"CLUB-booster-small", texId:"yellow-booster-small", zIndex:0, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-booster-small", texId:"green-booster-small", zIndex:0, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-booster-small", texId:"blue-booster-small", zIndex:0, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-booster-small", texId:"red-booster-small", zIndex:0, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-ind-booster-small", texId:"yellow-ind-booster-small", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-ind-booster-small", texId:"green-ind-booster-small", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-ind-booster-small", texId:"blue-ind-booster-small", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-ind-booster-small", texId:"red-ind-booster-small", zIndex:1, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-ind-booster-big", texId:"yellow-ind-booster-big", zIndex:1, scaleDivideBy:1, rendererEnable:false },
    { id:"HEART-ind-booster-big", texId:"green-ind-booster-big", zIndex:1, scaleDivideBy:1, rendererEnable:false },
    { id:"SPADE-ind-booster-big", texId:"blue-ind-booster-big", zIndex:1, scaleDivideBy:1, rendererEnable:false },
    { id:"DIAMOND-ind-booster-big", texId:"red-ind-booster-big", zIndex:1, scaleDivideBy:1, rendererEnable:false },

    { id:"CLUB-ind-booster-4x", texId:"yellow-ind-booster-4x", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-ind-booster-4x", texId:"green-ind-booster-4x", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-ind-booster-4x", texId:"blue-ind-booster-4x", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-ind-booster-4x", texId:"red-ind-booster-4x", zIndex:1, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-ind-booster-5x", texId:"yellow-ind-booster-5x", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-ind-booster-5x", texId:"green-ind-booster-5x", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-ind-booster-5x", texId:"blue-ind-booster-5x", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-ind-booster-5x", texId:"red-ind-booster-5x", zIndex:1, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-ind-booster-6x", texId:"yellow-ind-booster-6x", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-ind-booster-6x", texId:"green-ind-booster-6x", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-ind-booster-6x", texId:"blue-ind-booster-6x", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-ind-booster-6x", texId:"red-ind-booster-6x", zIndex:1, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-ind-booster-7x", texId:"yellow-ind-booster-7x", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-ind-booster-7x", texId:"green-ind-booster-7x", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-ind-booster-7x", texId:"blue-ind-booster-7x", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-ind-booster-7x", texId:"red-ind-booster-7x", zIndex:1, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-ind-booster-8x", texId:"yellow-ind-booster-8x", zIndex:1, scaleDivideBy:1, rendererEnable:false },
    { id:"HEART-ind-booster-8x", texId:"green-ind-booster-8x", zIndex:1, scaleDivideBy:1, rendererEnable:false },
    { id:"SPADE-ind-booster-8x", texId:"blue-ind-booster-8x", zIndex:1, scaleDivideBy:1, rendererEnable:false },
    { id:"DIAMOND-ind-booster-8x", texId:"red-ind-booster-8x", zIndex:1, scaleDivideBy:1, rendererEnable:false },



    // LARGE SIZE
    { id:"CLUB-largeSize", texId:"car-club-largeSize", zIndex:1, scaleDivideBy:25, rendererEnable:false },
    { id:"HEART-largeSize", texId:"car-heart-largeSize", zIndex:1, scaleDivideBy:25, rendererEnable:false },
    { id:"SPADE-largeSize", texId:"car-spade-largeSize", zIndex:1, scaleDivideBy:25, rendererEnable:false },
    { id:"DIAMOND-largeSize", texId:"car-diamond-largeSize", zIndex:1, scaleDivideBy:25, rendererEnable:false },
    
    { id:"CLUB-booster-big-largeSize", texId:"yellow-booster-big-largeSize", zIndex:0, scaleDivideBy:25, rendererEnable:false },
    { id:"HEART-booster-big-largeSize", texId:"green-booster-big-largeSize", zIndex:0, scaleDivideBy:25, rendererEnable:false },
    { id:"SPADE-booster-big-largeSize", texId:"blue-booster-big-largeSize", zIndex:0, scaleDivideBy:25, rendererEnable:false },
    { id:"DIAMOND-booster-big-largeSize", texId:"red-booster-big-largeSize", zIndex:0, scaleDivideBy:25, rendererEnable:false },

    { id:"CLUB-booster-small-largeSize", texId:"yellow-booster-small-largeSize", zIndex:0, scaleDivideBy:20, rendererEnable:false },
    { id:"HEART-booster-small-largeSize", texId:"green-booster-small-largeSize", zIndex:0, scaleDivideBy:20, rendererEnable:false },
    { id:"SPADE-booster-small-largeSize", texId:"blue-booster-small-largeSize", zIndex:0, scaleDivideBy:20, rendererEnable:false },
    { id:"DIAMOND-booster-small-largeSize", texId:"red-booster-small-largeSize", zIndex:0, scaleDivideBy:20, rendererEnable:false },

    { id:"CLUB-ind-booster-small-largeSize", texId:"yellow-ind-booster-small-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"HEART-ind-booster-small-largeSize", texId:"green-ind-booster-small-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"SPADE-ind-booster-small-largeSize", texId:"blue-ind-booster-small-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"DIAMOND-ind-booster-small-largeSize", texId:"red-ind-booster-small-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },

    { id:"CLUB-ind-booster-big-largeSize", texId:"yellow-ind-booster-big-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"HEART-ind-booster-big-largeSize", texId:"green-ind-booster-big-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"SPADE-ind-booster-big-largeSize", texId:"blue-ind-booster-big-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"DIAMOND-ind-booster-big-largeSize", texId:"red-ind-booster-big-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },

    { id:"CLUB-ind-booster-4x-largeSize", texId:"yellow-ind-booster-4x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"HEART-ind-booster-4x-largeSize", texId:"green-ind-booster-4x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"SPADE-ind-booster-4x-largeSize", texId:"blue-ind-booster-4x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"DIAMOND-ind-booster-4x-largeSize", texId:"red-ind-booster-4x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },

    { id:"CLUB-ind-booster-5x-largeSize", texId:"yellow-ind-booster-5x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"HEART-ind-booster-5x-largeSize", texId:"green-ind-booster-5x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"SPADE-ind-booster-5x-largeSize", texId:"blue-ind-booster-5x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"DIAMOND-ind-booster-5x-largeSize", texId:"red-ind-booster-5x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },

    { id:"CLUB-ind-booster-6x-largeSize", texId:"yellow-ind-booster-6x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"HEART-ind-booster-6x-largeSize", texId:"green-ind-booster-6x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"SPADE-ind-booster-6x-largeSize", texId:"blue-ind-booster-6x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"DIAMOND-ind-booster-6x-largeSize", texId:"red-ind-booster-6x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },

    { id:"CLUB-ind-booster-7x-largeSize", texId:"yellow-ind-booster-7x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"HEART-ind-booster-7x-largeSize", texId:"green-ind-booster-7x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"SPADE-ind-booster-7x-largeSize", texId:"blue-ind-booster-7x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"DIAMOND-ind-booster-7x-largeSize", texId:"red-ind-booster-7x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },

    { id:"CLUB-ind-booster-8x-largeSize", texId:"yellow-ind-booster-8x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"HEART-ind-booster-8x-largeSize", texId:"green-ind-booster-8x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"SPADE-ind-booster-8x-largeSize", texId:"blue-ind-booster-8x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },
    { id:"DIAMOND-ind-booster-8x-largeSize", texId:"red-ind-booster-8x-largeSize", zIndex:1, scaleDivideBy:20, rendererEnable:false },


    // SMALL SIZE
    { id:"CLUB-smallSize", texId:"car-club-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-smallSize", texId:"car-heart-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-smallSize", texId:"car-spade-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-smallSize", texId:"car-diamond-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-booster-big-smallSize", texId:"yellow-booster-big-smallSize", zIndex:0, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-booster-big-smallSize", texId:"green-booster-big-smallSize", zIndex:0, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-booster-big-smallSize", texId:"blue-booster-big-smallSize", zIndex:0, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-booster-big-smallSize", texId:"red-booster-big-smallSize", zIndex:0, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-booster-small-smallSize", texId:"yellow-booster-small-smallSize", zIndex:0, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-booster-small-smallSize", texId:"green-booster-small-smallSize", zIndex:0, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-booster-small-smallSize", texId:"blue-booster-small-smallSize", zIndex:0, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-booster-small-smallSize", texId:"red-booster-small-smallSize", zIndex:0, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-ind-booster-small-smallSize", texId:"yellow-ind-booster-small-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-ind-booster-small-smallSize", texId:"green-ind-booster-small-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-ind-booster-small-smallSize", texId:"blue-ind-booster-small-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-ind-booster-small-smallSize", texId:"red-ind-booster-small-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-ind-booster-big-smallSize", texId:"yellow-ind-booster-big-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-ind-booster-big-smallSize", texId:"green-ind-booster-big-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-ind-booster-big-smallSize", texId:"blue-ind-booster-big-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-ind-booster-big-smallSize", texId:"red-ind-booster-big-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-ind-booster-4x-smallSize", texId:"yellow-ind-booster-4x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-ind-booster-4x-smallSize", texId:"green-ind-booster-4x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-ind-booster-4x-smallSize", texId:"blue-ind-booster-4x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-ind-booster-4x-smallSize", texId:"red-ind-booster-4x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-ind-booster-5x-smallSize", texId:"yellow-ind-booster-5x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-ind-booster-5x-smallSize", texId:"green-ind-booster-5x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-ind-booster-5x-smallSize", texId:"blue-ind-booster-5x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-ind-booster-5x-smallSize", texId:"red-ind-booster-5x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-ind-booster-6x-smallSize", texId:"yellow-ind-booster-6x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-ind-booster-6x-smallSize", texId:"green-ind-booster-6x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-ind-booster-6x-smallSize", texId:"blue-ind-booster-6x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-ind-booster-6x-smallSize", texId:"red-ind-booster-6x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-ind-booster-7x-smallSize", texId:"yellow-ind-booster-7x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-ind-booster-7x-smallSize", texId:"green-ind-booster-7x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-ind-booster-7x-smallSize", texId:"blue-ind-booster-7x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-ind-booster-7x-smallSize", texId:"red-ind-booster-7x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },

    { id:"CLUB-ind-booster-8x-smallSize", texId:"yellow-ind-booster-8x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"HEART-ind-booster-8x-smallSize", texId:"green-ind-booster-8x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"SPADE-ind-booster-8x-smallSize", texId:"blue-ind-booster-8x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
    { id:"DIAMOND-ind-booster-8x-smallSize", texId:"red-ind-booster-8x-smallSize", zIndex:1, scaleDivideBy:12, rendererEnable:false },
];