import { useEffect } from 'react';

import intl from 'react-intl-universal';

import {
    getBrowserLocale,
    getLanguageByCode,
    getSupportedLocales,
} from '../../../common/helpers/locale';

export const useIntlProvider = (initDone, storage, callback) => {

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (!initDone) return;
        const currentLanguage = (storage && storage.lang)? storage.lang : 'en-US';
        const countryFound = getLanguageByCode(currentLanguage);
        const browserLocale = getBrowserLocale().indexOf('zh') !== -1 ? 'zh-CN' : 'en-US';
        
        intl.init({
            currentLocale: countryFound ? currentLanguage : browserLocale,
            locales: getSupportedLocales(),
        }).then(() => {
            if (callback)
                callback(true);
        });
    }, [initDone, storage]);
}