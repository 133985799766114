import React from 'react';
import { Redirect } from 'react-router-dom';
import { useVerifyThirdParty } from '../../services/userService';
import * as QueryString from "query-string";
import ErrorHandler from '../error_redirect_statuses/ErrorHandler';
import { ERROR, THEME_CLASS } from '../../common/constants/keys';
import { ROUTES } from '../../common/constants/routing';
import { getRefererUrl, getTheme } from '../../common/helpers/common';
import { DEFAULT } from '../../common/constants/misc';

const GameRedirection = ({ match, location }) => {
    const params = QueryString.parse(location.search);

    let refererUrl = getRefererUrl();
    let theme = THEME_CLASS.CAR;
    let lang = 'en-US';
    const { error, loading, response } = useVerifyThirdParty(params.payload, params.signature, refererUrl, params.platform);

    if (error) {
        theme = getTheme(error.data.result.theme ?? DEFAULT.THEME);
        return <ErrorHandler topBar={true} theme={theme} language={error.data.result.language} status={ ERROR.ERROR } url={error.data ? error.data.backurl : refererUrl} />;
    }
    if (loading || !response) {
        return <ErrorHandler topBar={true} theme={theme} language={lang} status={ ERROR.LOADING } url={refererUrl} />;
    }
    if (response.data.isMaintenance) {
        theme = getTheme(response.data.result.theme);
        return <ErrorHandler topBar={true} theme={theme} language={response.data.result.language} status={ ERROR.MAINTENANCE } url={response.data.backUrl} />;
    }

    if (!loading && response) {
        return <Redirect to={ROUTES.LANDING} />;
    }
}

export default GameRedirection;