import React, { useEffect, useState } from 'react';
import { useStore } from '../../../../store/StateProvider';
import ImgIcon from '../../../common/ImgIcon';
import './GraphicsBottomContainer.less';
import BuyAmount from '../BuyAmount';
import { enablePanel } from '../../../../common/actions/settingsActions';
import { getThemeGameContainer } from '../../../../common/helpers/theme';
import { THEME_CLASS } from '../../../../common/constants/keys';
import Controls from './Controls';

const GraphicsBottomContainer = ({
    theme,
    themeColor
}) => {
    const themes = getThemeGameContainer(theme, themeColor);
    const [store, dispatch] = useStore();
    const [buyAmount, setBuyAmount] = useState(0);
    const [panel, setPanel] = useState('');

    useEffect(() => {
        if (theme === THEME_CLASS.DEFAULT) {
            setPanel(
                <>
                <ImgIcon 
                    size={65}
                    icon={themes.CHAT}
                    alt="chat"
                    containerClassName="chat"
                />
                <BuyAmount>{buyAmount}</BuyAmount>
                <ImgIcon 
                    size={65}
                    icon={themes.PANEL}
                    alt="panel"
                    containerClassName="panel"
                    onClick={panelClicked}
                />
                </>
            );
        } else if (theme === THEME_CLASS.CAR || theme === THEME_CLASS.OKBET) {
            setPanel(<Controls
                        theme={theme}
                        themeColor={themeColor} />);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeColor]);

    useEffect(() => {
        if (!store.hasOwnProperty("game")) return;
        if ("buyAmount" in store.game) setBuyAmount(store.game.buyAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.game]);

    const panelClicked = () => {
        enablePanel(dispatch);
    }

    return (
        <div className="graphicsBottom__container">
            { panel }
        </div>
    )
};

export default GraphicsBottomContainer;