import { Progress } from 'antd';
import classNames from 'classnames';
import Button from '../common/Button';
import React, {useState, useEffect} from 'react';
import { useStore } from '../../store/StateProvider';
import './Flash.less';
import { getThemeFlash } from '../../common/helpers/theme';
import { getTranslation } from '../../common/helpers/locale';
import { isDesktop } from '../../common/helpers/common';

const Flash  = ({
    theme,
    themeColor,
    progress,
    start
}) => {
    const themes = getThemeFlash(theme);
    const [store] = useStore();
    const [bottomInfo, setBottomInfo] = useState('');
    let { game } = store;
    
    useEffect(() => {
        if (progress < 100) {
            setBottomInfo(
                <>
                <Progress
                    percent={progress}
                    showInfo={false}
                    />
                <div className='text'>
                    {getTranslation("LOADING_GAME")}&nbsp;{progress}%
                </div>
                </>
            );
        }else{
            setBottomInfo(
                <Button
                    containerClassName="button"
                    onClick={start}
                    icon={themes.BUTTON}
                    >
                        {getTranslation("START")}
                    </Button>
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress]);
    
    return (
        <div className='flash'>
            <div className={
                classNames(
                    'flash__container',
                    theme,
                    themeColor
                )
            }>
                <div className='main'>
                    <div className={
                        classNames(
                            'icon',
                            game.storage.lang
                        )
                    }></div>
                    <div className='bottom'>
                        {bottomInfo}
                    </div>
                </div>
            </div>
            {
                !isDesktop() && <div className='footer'></div>
            }
        </div>
    )
};

export default Flash;