import React from 'react'

import classNames from 'classnames'

import { ICONS } from '../../../common/constants/iconWrapper'
import { THEME_ENUM } from '../../../common/constants/keys'
import { getTranslation } from '../../../common/helpers/locale'
import ImgIcon from '../../common/ImgIcon'

import SimpleModal from '../../common/SimpleModal'

const SettingsModal = ({ 
        anonymous, 
        soundEnable, 
        visible, 
        settingsColor, 
        confirmSettingModal = ()=>{},
        _changeSoundEnable = ()=>{},
        _changeThemeColor = ()=>{},
        revertSetting = ()=>{}
      }) => {

    return (
            <SimpleModal
                containerClassName={
                    classNames(
                        "gameSetting__container",
                        "setting-modal",
                        visible? 'active' : ''
                    )}
                title="SETTING"
                >
                    <div className="setting__container">
                        <div className='section'>
                            <div className='title'>
                                {getTranslation("BACKGROUND_MUSIC")}
                            </div>
                            <div className='row'>
                                <div className='txt'>
                                    {getTranslation("ON")}
                                </div>
                                <ImgIcon
                                    onClick={() => _changeSoundEnable(true)}
                                    icon={(soundEnable)? ICONS.THEME.CAR.GAME.SETTING.SELECTED : ICONS.THEME.CAR.GAME.SETTING.UNSELECT}
                                    alt="on"
                                    />
                                <div className='txt'>
                                    {getTranslation("OFF")}
                                </div>
                                <ImgIcon
                                    onClick={() => _changeSoundEnable(false)}
                                    icon={(!soundEnable)? ICONS.THEME.CAR.GAME.SETTING.SELECTED : ICONS.THEME.CAR.GAME.SETTING.UNSELECT}
                                    alt="off"
                                    />
                            </div>
                        </div>
                        <div className={classNames('section', {'anonymous' : anonymous})}>
                            <div className='title'>
                                {getTranslation("CHOOSE_THEME")}
                            </div>
                            <div className='colors row'>
                                <div className={
                                    classNames(
                                        settingsColor === THEME_ENUM.GREEN? 'active' : ''
                                    )}
                                    onClick={() => _changeThemeColor(THEME_ENUM.GREEN)}>
                                    <ImgIcon
                                        icon={ICONS.THEME.CAR.GAME.SETTING.COLOR.GREEN}
                                        alt={THEME_ENUM.GREEN}
                                        />
                                </div>
                                <div className={
                                    classNames(
                                        settingsColor === THEME_ENUM.BROWN? 'active' : ''
                                    )}
                                    onClick={() => _changeThemeColor(THEME_ENUM.BROWN)}>
                                    <ImgIcon
                                        icon={ICONS.THEME.CAR.GAME.SETTING.COLOR.BROWN}
                                        alt={THEME_ENUM.BROWN}
                                        />
                                </div>
                                <div className={
                                    classNames(
                                        settingsColor === THEME_ENUM.BLUE? 'active' : ''
                                    )}
                                    onClick={() => _changeThemeColor(THEME_ENUM.BLUE)}>
                                    <ImgIcon
                                        icon={ICONS.THEME.CAR.GAME.SETTING.COLOR.BLUE}
                                        alt={THEME_ENUM.BLUE}
                                        />
                                </div>
                                <div className={
                                    classNames(
                                        settingsColor === THEME_ENUM.BLACK? 'active' : ''
                                    )}
                                    onClick={() => _changeThemeColor(THEME_ENUM.BLACK)}>
                                    <ImgIcon
                                        icon={ICONS.THEME.CAR.GAME.SETTING.COLOR.BLACK}
                                        alt={THEME_ENUM.BLACK}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='buttons__container'>
                        <ImgIcon
                            onClick={confirmSettingModal}
                            icon={ICONS.THEME.CAR.GAME.SETTING.CONFIRM}
                            alt="confirm"
                            />
                        <ImgIcon
                            onClick={revertSetting}
                            icon={ICONS.THEME.CAR.GAME.SETTING.CLOSE}
                            alt="close"
                            />
                    </div>
                </SimpleModal>
    )
}

export default SettingsModal
