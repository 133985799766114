import React, {useState} from 'react';

import { getTranslation } from '../../common/helpers/locale'
import GameHash from '../common/GameHash'
import TransactionResults from './TransactionResults'
import { DropdownTriggerIcon } from '../common/DropdownTriggerIcon';
import classNames from 'classnames';

const MobileTransaction = ({
    gameCreated,
    game,
    gameContainerThemes,
    transactionDetails,
    lang,
    result,
    res,
    date,
    profit,
}) => {
    const [show, setShow] = useState(false);

    const onShowClick = (key) => {
        setShow({...show, [key]: !show[key]})
    }

    return (
        <div className={classNames("item", {
            void: result === "VOID"
        })}>
            <div className='title'>
                {getTranslation("GAME_ID")}&nbsp;{gameCreated}{game.gameSession.index}

                <DropdownTriggerIcon 
                    onClick={()=>{onShowClick(game.displayId)}}
                    show={show[game.displayId]}
                />
            </div>
            <div className='main'>
                <TransactionResults
                    gameContainerThemes={gameContainerThemes}
                    transactionDetails={transactionDetails}
                    lang={lang}
                    result={result}
                    res={res}
                />
                {
                    show[game.displayId] && (
                        <>
                            <div>
                                <span className="detail-title">{getTranslation("TRANSACTION_ID")}:</span>
                                <span>{game.displayId}</span>
                            </div>
                            <div>
                                <span className="detail-title">{getTranslation("DATE")}:</span>
                                <span>{date}&nbsp;GMT</span>
                            </div>
                            <div>
                                <span className="detail-title">{getTranslation("HASH")}:</span>
                                <span style={{display: 'flex'}}><GameHash hash={game.gameSession.hash}/></span>
                            </div>
                            <div className='profit'>
                                <span className="detail-title">{getTranslation("WIN_AMOUNT")}:</span>&nbsp;
                                {result === "VOID" ? <div>{result}</div> : profit}
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default MobileTransaction
