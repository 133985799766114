export const CDN_ICONS = {
    COMMON: {
        CLOSE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/controls/close.svg'},
        HAMBURGER_MENU: { default: 'https://static-cardracing.pypc.net/assets/images/common/hamburger-menu.svg'},
        VOIDED_CAR_IMAGE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/void-game-result.png' },
    },
    BUY_AMOUNT_COIN: {
        BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/common/buy-amount-coin/coin-blue.svg'},
        ORANGE: { default: 'https://static-cardracing.pypc.net/assets/images/common/buy-amount-coin/coin-orange.svg'},
        PURPLE: { default: 'https://static-cardracing.pypc.net/assets/images/common/buy-amount-coin/coin-purple.svg'},
        GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/common/buy-amount-coin/coin-green.svg'},
        RED: { default: 'https://static-cardracing.pypc.net/assets/images/common/buy-amount-coin/coin-red.svg'},
    },
    HEADER: {
        LEFT_ARROW: { default: 'https://static-cardracing.pypc.net/assets/images/header/left-arrow.svg'},
        ADVANTPLAY_LOGO: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/flash/ad-logo.png'},
        OKBET_LOGO: { default: 'https://static-cardracing.pypc.net/assets/images/okbet/flash_logo.png'},
        SETTING: { default: 'https://static-cardracing.pypc.net/assets/images/header/setting.svg'}
    },
    FOOTER: {
        HOME: { default: 'https://static-cardracing.pypc.net/assets/images/footer/home.svg'},
        RESULTS: { default: 'https://static-cardracing.pypc.net/assets/images/footer/results.svg'},
        TRANSACTIONS: { default: 'https://static-cardracing.pypc.net/assets/images/footer/transactions.svg'},
        HELP: { default: 'https://static-cardracing.pypc.net/assets/images/footer/help.svg'}
    },
    THEME: {
        CAR: {
            COMMON: {
                PAGINATION_BACKGROUND: {
                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/transactions/refresh-and-pagination-bg-blue.png'},
                    BROWN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/transactions/refresh-and-pagination-bg-brown.png'},
                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/transactions/refresh-and-pagination-bg-green.png'},
                    BLACK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/transactions/refresh-and-pagination-bg-black.png'},
                    PINK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/transactions/refresh-and-pagination-bg-pink.png'},
                },
                LEFT_ARROW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/transactions/left-arrow.png'},
                RIGHT_ARROW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/transactions/right-arrow.png'},
                REFRESH: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/results/refresh.png'},
                CLOSE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/message/close.png'},
            },
            GAME: {
                MISC: {
                    LOADING: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/misc/pre-loading_x264.mp4'},
                    MAINTENANCE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/misc/maintenance-icon.png'},
                    ERROR: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/misc/error-icon.svg'},
                },
                SETTING: {
                    SELECTED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/setting/setting-selected.png'},
                    UNSELECT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/setting/setting-unselected.png'},
                    COLOR: {
                        GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/setting/btn-green.png'},
                        BROWN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/setting/btn-brown.png'},
                        BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/setting/btn-blue.png'},
                        BLACK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/setting/btn-black.png'}
                    },
                    CONFIRM: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/setting/confirm.png'},
                    CLOSE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/setting/close.png'},
                },
                FLASH: {
                    BUTTON: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/flash/btn.png'},
                },
                MESSAGE: {
                    WINNING: {
                        ARROW_LEFT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/message/win-arrow-left@3x.png'},
                        ARROW_RIGHT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/message/win-arrow-right@3x.png'},
                        HIGHLIGHT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/message/win-highlight@3x.png'},
                        RIBBON: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/message/win-ribbon.png'},
                        RIBBON2: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/message/win-ribbon2.png'},
                        STAR: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/message/win-star@3x.png'},
                    },
                    ACCOUNT_LOCKED: {
                        LOCKED_ACCESS: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/message/locked-access.png'},
                        LOCKED_BG: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/message/locked-bg.png'},
                    }
                },
                GRAPHICS_CONTAINER: {
                    REBET_ICON: {default: "https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/common/rebet-icon.png"},
                    REBET_CANCEL_ICON: {default: "https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/common/rebet-icon-cancel.png"},
                    CHIPS:{
                        BLUE: {default:"https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/common/chip-blue.png"},
                        ORANGE: {default:"https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/common/chip-orange.png"},
                        PURPLE: {default:"https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/common/chip-purple.png"},
                        GREEN: {default:"https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/common/chip-green.png"},
                        RED: {default:"https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/common/chip-red.png"},
                    },
                    SUITS: {
                        BACK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/suits/poker@6x.png'},
                        CLUB: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/suits/suit-club.png'},
                        DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/suits/suit-diamond.png'},
                        HEART: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/suits/suit-heart.png'},
                        SPADE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/suits/suit-spade.png'}
                    },
                    CONTROLS: {
                        INPUT_PROGRESS_BAR: {
                            FILL: {
                                BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/btn-progress-bar-blue.svg'},
                                BROWN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/btn-progress-bar-brown.svg'},
                                GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/btn-progress-bar-green.svg'},
                                BLACK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/btn-progress-bar-black.svg'},
                                PINK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/btn-progress-bar-pink.svg'},
                            },
                            EMPTY: {
                                BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/btn-progress-bar-empty.svg'},
                                BROWN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/btn-progress-bar-empty-brown.svg'},
                                GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/btn-progress-bar-empty-green.svg'},
                                BLACK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/btn-progress-bar-empty-black.svg'},
                                PINK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/btn-progress-bar-empty-pink.svg'},
                            }
                        },
                        BET_INCREMENT_BUTTON: {
                            CLICK: {
                                BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/blue-btn-click.png'},
                                BROWN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/brown-btn-click.png'},
                                GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/green-btn-click.png'},
                                BLACK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/black-btn-click.png'},
                                PINK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/pink-btn-click.png'},
                            },
                            WITHOUT_CLICK: {
                                BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/blue-btn-without-click.png'},
                                BROWN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/brown-btn-without-click.png'},
                                GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/green-btn-without-click.png'},
                                BLACK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/black-btn-without-click.png'},
                                PINK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/pink-btn-without-click.png'},
                            }
                        },
                        PLACE_BET_BUTTON: {
                            BUY: {
                                BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/buyblue@3x.png'},
                                BROWN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/buybrown@3x.png'},
                                GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/buygreen@3x.png'},
                                BLACK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/buygrey@3x.png'},
                                PINK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/buypink@3x.png'},
                            },
                            BUYING: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/buyyellow@3x.png'},

                            BUY_BTN:{
                                BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/buy-btn-blue.png'},
                                BROWN: { default:'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/buy-btn-brown.png'},
                                GREEN: { default:'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/buy-btn-green.png'},
                                BLACK: { default:'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/buy-btn-black.png'},
                                PINK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/buy-btn-pink.png'},
                            },
                            BUYING_BTN: {default:'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/buy-btn-buying.png'},
                            COIN_ICON: {default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/coin-icon.png'},
                        },
                        BUY_AMOUNT: {
                            BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/blue-long-bg.png'},
                            BROWN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/brown-long-bg.png'},
                            GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/green-long-bg.png'},
                            BLACK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/black-long-bg.png'},
                            PINK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/pink-long-bg.png'},
                        },
                        SUIT_CHOICE: {
                            BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/blue-long-bg.png'},
                            BROWN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/brown-long-bg.png'},
                            GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/green-long-bg.png'},
                            BLACK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/black-long-bg.png'},
                            PINK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/controls/pink-long-bg.png'},
                        }
                    },
                    GRAPHICS_MAIN: {
                        TEXTURES: {
                            HIGHLIGHT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/road-higlight@3x.png'},
                            BACKGROUND: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/road-cut.jpg'},
                            DESKTOP_BACKGROUND: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/desktop-road.jpg'},
                            START_LINE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/start-line@3x.png'},
                            DESKTOP_START_LINE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/desktop-start-line.png'},
                            ARROW: {
                                UP: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/common/triangle-bottom.svg'},
                                DOWN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/common/triangle-top.svg'},
                            },
                            TRAFFIC: {
                                STEP_1: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/traffic_light_for_3@3x.png'},
                                STEP_2: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/traffic_light_for_2@3x.png'},
                                STEP_3: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/traffic_light_for_1@3x.png'},
                                STEP_GO: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/traffic_light_for_go@3x.png'},
                            },
                            HISTORY: {
                                BACKGROUND: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/history-indicator-bg-blue.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/history-indicator-bg-green.png'},
                                    BROWN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/history-indicator-bg-brown.png'},
                                    BLACK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/history-indicator-bg-black.png'},
                                    PINK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/history-indicator-bg-pink.png'},
                                },
                                COVER: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/cover.png'},
                                FLAG: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/history-indicator-flag.png'},
                            },
                            SUIT: {
                                CLUB: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/suit-club.svg'},
                                HEART: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/suit-heart.svg'},
                                SPADE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/suit-spade.svg'},
                                DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/suit-diamond.svg'},
                            },
                            CAR: {
                                CLUB: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-club@6x.png'},
                                HEART: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-heart@6x.png'},
                                SPADE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-spade@6x.png'},
                                DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-diamond@6x.png'},
                                SMALL_SIZE: {
                                    CLUB: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-club@0.6x.svg'},
                                    HEART: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-heart@0.6x.svg'},
                                    SPADE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-spade@0.6x.svg'},
                                    DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-diamond@0.6x.svg'}
                                },
                                DEFAULT_SIZE: {
                                    CLUB: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-club@1x.svg'},
                                    HEART: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-heart@1x.svg'},
                                    SPADE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-spade@1x.svg'},
                                    DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-diamond@1x.svg'}
                                },
                                LARGE_SIZE: {
                                    CLUB: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-club@3x.png'},
                                    HEART: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-heart@3x.png'},
                                    SPADE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-spade@3x.png'},
                                    DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/car-diamond@3x.png'}
                                },
                            },
                            BOOSTER: {
                                BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/blue-booster.png'},
                                YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/yellow-booster.png'},
                                RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/red-booster.png'},
                                GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/green-booster.png'},
                                SMALL_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/blue-booster-smallSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/yellow-booster-smallSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/red-booster-smallSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/green-booster-smallSize.png'},
                                },
                                LARGE_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/blue-booster@largeSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/yellow-booster@largeSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/red-booster@largeSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/green-booster@largeSize.png'},
                                },
                            },
                            BOOSTER_SMALL: {
                                BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/blue-booster@small.png'},
                                YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/yellow-booster@small.png'},
                                RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/red-booster@small.png'},
                                GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/green-booster@small.png'},
                                SMALL_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/blue-booster@small-smallSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/yellow-booster@small-smallSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/red-booster@small-smallSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/green-booster@small-smallSize.png'},
                                },
                                LARGE_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/blue-booster@small-largeSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/yellow-booster@small-largeSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/red-booster@small-largeSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/green-booster@small-largeSize.png'},
                                },
                            },
                            IND_BOOSTER: {
                                BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/3X.png'},
                                YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/3X.png'},
                                RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/3X.png'},
                                GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/3X.png'},
                                SMALL_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/3X-smallSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/3X-smallSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/3X-smallSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/3X-smallSize.png'},
                                },
                                LARGE_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/3X-largeSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/3X-largeSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/3X-largeSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/3X-largeSize.png'},
                                },
                            },
                            IND_BOOSTER_SMALL: {
                                BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/2X.png'},
                                YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/2X.png'},
                                RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/2X.png'},
                                GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/2X.png'},
                                SMALL_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/2X-smallSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/2X-smallSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/2X-smallSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/2X-smallSize.png'},
                                },
                                LARGE_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/2X-largeSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/2X-largeSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/2X-largeSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/2X-largeSize.png'},
                                },
                            },
                            IND_BOOSTER_4X: {
                                BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/4X.png'},
                                YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/4X.png'},
                                RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/4X.png'},
                                GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/4X.png'},
                                SMALL_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/4X-smallSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/4X-smallSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/4X-smallSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/4X-smallSize.png'},
                                },
                                LARGE_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/4X-largeSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/4X-largeSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/4X-largeSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/4X-largeSize.png'},
                                },
                            },
                            IND_BOOSTER_5X: {
                                BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/5X.png'},
                                YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/5X.png'},
                                RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/5X.png'},
                                GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/5X.png'},
                                SMALL_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/5X-smallSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/5X-smallSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/5X-smallSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/5X-smallSize.png'},
                                },
                                LARGE_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/5X-largeSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/5X-largeSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/5X-largeSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/5X-largeSize.png'},
                                },
                            },
                            IND_BOOSTER_6X: {
                                BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/6X.png'},
                                YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/6X.png'},
                                RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/6X.png'},
                                GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/6X.png'},
                                SMALL_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/6X-smallSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/6X-smallSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/6X-smallSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/6X-smallSize.png'},
                                },
                                LARGE_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/6X-largeSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/6X-largeSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/6X-largeSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/6X-largeSize.png'},
                                },
                            },
                            IND_BOOSTER_7X: {
                                BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/7X.png'},
                                YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/7X.png'},
                                RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/7X.png'},
                                GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/7X.png'},
                                SMALL_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/7X-smallSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/7X-smallSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/7X-smallSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/7X-smallSize.png'},
                                },
                                LARGE_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/7X-largeSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/7X-largeSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/7X-largeSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/7X-largeSize.png'},
                                },
                            },
                            IND_BOOSTER_8X: {
                                BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/8X.png'},
                                YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/8X.png'},
                                RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/8X.png'},
                                GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/8X.png'},
                                SMALL_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/8X-smallSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/8X-smallSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/8X-smallSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/8X-smallSize.png'},
                                },
                                LARGE_SIZE: {
                                    BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/8X-largeSize.png'},
                                    YELLOW: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/8X-largeSize.png'},
                                    RED: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/8X-largeSize.png'},
                                    GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-main/textures/8X-largeSize.png'},
                                },
                            },
                        }
                    },
                    GRAPHTICS_TOP_CONTAINER: {
                        GAME_ID: {
                            BLACK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-top-container/black-short-bg.png'},
                            BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-top-container/blue-short-bg.png'},
                            BROWN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-top-container/brown-short-bg.png'},
                            GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-top-container/green-short-bg.png'},
                            PINK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-top-container/pink-short-bg.png'},
                        },
                        BALANCE: {
                            BLACK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-top-container/black-short-bg.png'},
                            BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-top-container/blue-short-bg.png'},
                            BROWN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-top-container/brown-short-bg.png'},
                            GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-top-container/green-short-bg.png'},
                            PINK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-top-container/pink-short-bg.png'},
                        },
                        SETTING: {
                            BLACK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-top-container/black-setting-btn.png'},
                            BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-top-container/blue-setting-btn.png'},
                            BROWN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-top-container/brown-setting-btn.png'},
                            GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-top-container/green-setting-btn.png'},
                            PINK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-top-container/pink-setting-btn.png'},
                        },
                    },
                    GRAPHICS_BOTTOM_CONTAINER: {
                        CARD_DISPLAY: {
                            BLACK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-bottom-container/card-display/black-card-display-bg.png'},
                            BLUE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-bottom-container/card-display/blue-card-display-bg.png'},
                            BROWN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-bottom-container/card-display/brown-card-display-bg.png'},
                            GREEN: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-bottom-container/card-display/green-card-display-bg.png'},
                            PINK: { default: 'https://static-cardracing.pypc.net/assets/images/themes/car/game/graphics-container/graphics-bottom-container/card-display/pink-card-display-bg.png'},
                        }
                    }
                }
            }
        },
        DEFAULT: {
            BACKGROUND: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/background.png'},
            GAME: {
                MISC: {
                    LOADING: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/misc/loading-icon.svg'},
                    MAINTENANCE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/misc/maintenance-icon.png'},
                    ERROR: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/misc/error-icon.svg'},
                },
                MESSAGE: {
                    WINNING: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/message/winning-icon.svg'},
                },
                GRAPHICS_CONTAINER: {
                    SUITS: {
                        CLUB: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/suits/icon-club.svg'},
                        DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/suits/icon-diamond.svg'},
                        HEART: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/suits/icon-heart.svg'},
                        SPADE: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/suits/icon-spade.svg'}
                    },
                    CONTROLS: {
                        PLACE_BET_BUTTON: {
                            BUY: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/controls/buy@3x.png'},
                            BUYING: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/controls/wait@3x.png'},
                        }
                    },
                    GRAPHTICS_TOP_CONTAINER: {
                        GAME_ID: {
                            LEFT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-top-container/game-id@3x_01.png'},
                            CENTER: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-top-container/game-id@3x_02.png'},
                            RIGHT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-top-container/game-id@3x_03.png'},
                        },
                        BALANCE: {
                            LEFT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-top-container/balance@3x_01.png'},
                            CENTER: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-top-container/balance@3x_02.png'},
                            RIGHT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-top-container/balance@3x_03.png'},
                        },
                        SOUND: {
                            LEFT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-top-container/sound@3x1_02.png'},
                            CENTER: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-top-container/sound@3x1_03.png'},
                            RIGHT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-top-container/sound@3x1_04.png'},
                        },
                    },
                    GRAPHICS_BOTTOM_CONTAINER: {
                        CHAT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-bottom-container/icon-chat.svg'},
                        PANEL: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-bottom-container/icon-panel.svg'},
                        BUY_AMOUNT: {
                            HEADER: {
                                LEFT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-bottom-container/buy-amount-container/title@3x2_01.png'},
                                CENTER: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-bottom-container/buy-amount-container/title@3x2_02.png'},
                                RIGHT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-bottom-container/buy-amount-container/title@3x2_03.png'},
                            },
                            LEFT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-bottom-container/buy-amount-container/amount@3x_01.png'},
                            CENTER: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-bottom-container/buy-amount-container/amount@3x_02.png'},
                            RIGHT: { default: 'https://static-cardracing.pypc.net/assets/images/themes/default/game/graphics-container/graphics-bottom-container/buy-amount-container/amount@3x_03.png'},
                        }
                    }
                }
            }
        }
    }
};