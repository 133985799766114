import React from 'react';
import PropTypes from 'prop-types';
import ImgIcon from '../common/ImgIcon';
import classNames from 'classnames';
import './Button.less';

const Button = ({ 
    containerClassName,
    onClick,
    icon,
    children,
    ...rest
}) => {
    return (
        <ImgIcon 
            icon={icon}
            alt={children}
            containerClassName={classNames(
                containerClassName,
                'button__container'
            )}
            onClick={onClick}
            textCenter={true}
            {...rest}
            >
            <span className='content' onClick={onClick}>
                {children}
            </span>
        </ImgIcon>
    )
};

Button.prototype = {
    containerClassName: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string.isRequired,
}

export default Button;