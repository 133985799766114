import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { CARD, CARD_COLOR, THEME_CLASS, THEME_ENUM } from '../../common/constants/keys';
import ImgIcon from './ImgIcon';
import classNames from 'classnames';
import { getThemeGameContainer } from '../../common/helpers/theme';
import './Card.less';

const Card = ({
    theme,
    themeColor='',
    suit,
    cardWidth=34,
    cardHeight=47,
    size=20,
    selected=false,
    onClick,
    containerClassName,
    children,
    ...rest
}) => {
    const themes = getThemeGameContainer(theme, (theme === THEME_CLASS.DEFAULT)? '' : THEME_ENUM.DEFAULT);
    const [color, setColor] = useState(CARD_COLOR.RED);

    useEffect(() => {
        if (theme === THEME_CLASS.DEFAULT) {
            switch (suit) {
                case CARD.SPADE:
                case CARD.CLUB:
                    setColor(CARD_COLOR.BLUE);
                    break;
                case CARD.DIAMOND:
                case CARD.HEART:
                    setColor(CARD_COLOR.RED);
                    break;
                default:
                    break;
            }
        } else if (theme === THEME_CLASS.CAR || theme === THEME_CLASS.OKBET) {
            setColor(suit);
        }
    }, [suit]);

    const getIcon = (suit) => {
        return themes.SUITS[suit];
    }

    return (
        <div className={classNames(
            'cardObj__container',
            containerClassName,
            selected ? 'active' : ''
        )} onClick={()=> onClick && onClick({ children: children, suit: suit})}>
            <div style={{ width: cardWidth, height: cardHeight }} className='container'>
                <ImgIcon
                    icon={getIcon(suit)}
                    alt={suit}
                    size={size}
                    containerClassName={classNames(color, "card__object")}>
                    <div className='content'>
                        {children}
                    </div>
                </ImgIcon>
            </div>
        </div>
    )
};

Card.protoTypes = {
    theme: PropTypes.string.isRequired,
    themeColor: PropTypes.string,
    suit: PropTypes.string.isRequired,
    cardWidth: PropTypes.number.isRequired,
    cardHeight: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    containerClassName: PropTypes.string,
};

export default Card;