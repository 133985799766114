import React from 'react';

import './Maintenance.less';
import { ICONS } from '../../../common/constants/iconWrapper';
import ImgIcon from '../../common/ImgIcon';
import { getTranslation } from '../../../common/helpers/locale';
import { THEME_CLASS } from '../../../common/constants/keys';

export const Maintenance = ({
    theme=THEME_CLASS.CAR,
    themeColor
}) => {
    return (
        <>
        { theme === THEME_CLASS.CAR? (
            <>
            <ImgIcon 
                icon={ICONS.THEME.CAR.GAME.MISC.MAINTENANCE}
                alt={getTranslation("MAINTENANCE")}
                width={299}
                height={169}
            />
            <div className='normal__text'>
                {getTranslation("SORRY_WE_ARE_DOWN_FOR")}
            </div>
            <div className='big__text'>
                {getTranslation("MAINTENANCE")}
            </div>
            </>
        ) : (
            <>
            <ImgIcon 
                icon={ICONS.THEME.DEFAULT.GAME.MISC.MAINTENANCE}
                alt={getTranslation("MAINTENANCE")}
                width={120}
                height={74}
            />
            <div className='normal__text'>
                {getTranslation("SORRY_WE_ARE_DOWN_FOR")}
            </div>
            <div className='big__text'>
                {getTranslation("MAINTENANCE")}
            </div>
            </>
        )}
        </>
    );
};