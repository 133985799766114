import { SPRITES } from '../../common/constants/game/graphics';
import Sprite from './Sprite';
import Texture from './Texture';
import { getThemeTextures } from '../../common/helpers/theme';
import Car from './Car';
import { CARD, THEME_CLASS } from '../../common/constants/keys';
import { isDesktop } from '../../common/helpers/common';

class Renderer {
    static instance = null;
    static getInstance() {
        if (!Renderer.instance) {
            Renderer.instance = new Renderer();
        }

        return Renderer.instance;
    }

    constructor() {
        this.ctx = null;
        this.data = {
            textures: {
                loaded: false,
            },
            objs: {}
        };
        this.textures = {};
        this.sprites = {};
        this.smallCanvasWidth = 480;
    }

    ready = () => {
        return (this.data.textures.loaded);
    }

    getCarSpr = (suit) => {
        if (this.canvasWidth < this.smallCanvasWidth) {
            suit += '-smallSize';
            return this.sprites[suit];
        }
        else if(isDesktop()) {
            suit += '-largeSize';
            return this.sprites[suit];
        }
        return this.sprites[suit];
    }

    getFlameBig = (suit) => {
        var suited = suit+'-booster-big';
        if (this.canvasWidth < this.smallCanvasWidth) {
            suited += '-smallSize';
            return this.sprites[suited];
        }
        else if (isDesktop()){
            suited += '-largeSize';
            return this.sprites[suited];
        }
        return this.sprites[suited];
    }

    getFlameSmall = (suit) => {
        var suited = suit+'-booster-small';
        if (this.canvasWidth < this.smallCanvasWidth) {
            suited += '-smallSize';
            return this.sprites[suited];
        }
        else if (isDesktop()){
            suited += '-largeSize';
            return this.sprites[suited];
        }
        return this.sprites[suited];
    }

    getIndFlame = (suit, ind) => {
        var suited = suit+'-ind-booster-'+ind;
        if (this.canvasWidth < this.smallCanvasWidth) {
            suited += '-smallSize';
            return this.sprites[suited];
        }
        else if (isDesktop()){
            suited += '-largeSize';
            return this.sprites[suited];
        }
        return this.sprites[suited];
    }

    setup = (canvasWidth) => {
        this.canvasWidth = canvasWidth;
        for (var id in this.sprites) {
            this.scaleSpr(canvasWidth, id);
        }
    }

    createSpr = (canvasWidth, id, tex, opts) => {
        this.canvasWidth = canvasWidth;
        if (this.sprites[id]) {
            this.sprites[id].tex = tex;
            return this.sprites[id];
        }
        this.sprites[id] = new Sprite(this.ctx, id, tex, opts);
        this.scaleSpr(canvasWidth, id);
        return this.sprites[id];
    }

    scaleSpr = (canvasWidth, id) => {
        if (CARD.hasOwnProperty(id.replace('-smallSize', ''))) {
            return;
        }
        this.sprites[id].scale(canvasWidth / this.sprites[id].tex.width / this.sprites[id].scaleDivideBy);
    }

    getSpr = (id) => {
        return this.sprites[id];
    }

    getTexture = (id) => {
        return this.textures[id];
    }

    getCar = (id) => {
        return this.data.objs[id];
    }

    createCar = (renderer, ctx, suit, opts) => {
        this.data.objs[suit] = new Car(renderer, ctx, suit, opts);
        return this.data.objs[suit];
    }

    getObj = (id) => {
        switch (this.theme) {
            case THEME_CLASS.CAR:
            case THEME_CLASS.OKBET:
                return this.getCar(id);
            default:
                return null;
        }
    }

    init = (callback, theme) => {
        var self = this;
        var promises = [];
        self.theme = theme;
        self.themeTextures = getThemeTextures(theme);
        for (var x in this.themeTextures) {
            var tex = this.themeTextures[x];
            var width = ("width" in tex)? tex.width: 0;
            var height = ("height" in tex)? tex.height : 0;
            promises.push(
                new Promise((resolve, reject) => {
                    new Texture({ id:tex.id, src:tex.src, numOfFrames:tex.frames, imageId:0, width:width, height:height }, (res) => {
                        self.textures[res.id] = res;
                        resolve(true);
                    });
                })
            );
        }

        Promise.all(promises).then(result => {
            self.data.textures.loaded = true;
            callback(true);
        });
    }

    restart = () => {
        for (var x in this.data.objs) {
            var obj = this.data.objs[x];
            obj.restart();
        }
    }

    start = (ctx, canvasWidth, theme, themeColor) => {
        var self = this;
        self.ctx = ctx;
        self.canvasWidth = canvasWidth;
        for (var x in SPRITES()) {
            var sprite = SPRITES()[x];
            self.createSpr(canvasWidth, sprite.id, self.getTexture(sprite.texId), sprite);
        }
    }

    run = () => {
        var self = this;
        var animats = self.orderByZIndex();
        for (var x in animats) {
            var objs = animats[x];
            for (var o in objs) {
                var obj = objs[o];
                if (obj.rendererEnable) {
                    obj.animate();
                }
            }
        }
        for (var x in self.data.objs) {
            var obj = self.data.objs[x];
            obj.animate();
        }
    }

    stop = () => {
        var self = this;
        for (var x in self.sprites) {
            delete self.sprites[x];
            self.sprites[x] = undefined;
        }
        self.sprites = {};
    }


    clearSpr = (sprite) => {
        var self = this;
        delete self.sprites[sprite];
    }

    orderByZIndex() {
        var ordered = {};
        for (var x in this.sprites) {
            var obj = this.sprites[x];
            if (!ordered.hasOwnProperty(obj.zIndex)) {
                ordered[obj.zIndex] = [];
            }
            ordered[obj.zIndex].push(obj);
        }
        return ordered;
    }
}

export default Renderer;