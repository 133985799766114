import React from 'react';
import { THEME_CLASS } from '../../../common/constants/keys';
import { getTranslation } from '../../../common/helpers/locale';
import './InsufficientBalance';

const InsufficientBalance = ({
    theme,
    themeColor
}) => {
    return (
        <>
        { theme === THEME_CLASS.CAR? (
            <>
            <div className='img'>
                <div className='top-up flickering'>
                    {getTranslation("TOP_UP")}
                </div>
            </div>
            <div>
                <h1>{getTranslation("INSUFFICIENT_BALANCE")}</h1>
                <div className="content">
                    {getTranslation("INSUFFICIENT_BALANCE_CONTENT")}
                </div>
            </div>
            </>
        ) : (
            <>
            <div className='img'></div>
            <h1>{getTranslation("INSUFFICIENT_BALANCE")}</h1>
            <div className="content">
                {getTranslation("INSUFFICIENT_BALANCE_CONTENT")}
            </div>
            </>
        )}
        </>
    )
};

export default InsufficientBalance;