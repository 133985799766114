import React from 'react';
import { withRouter } from 'react-router-dom';

import Footer from './Footer';
import Header from './Header';
import classNames from 'classnames';

const Layout = ({
    containerClassName,
    backToHeader,
    defaultFooter = true,
    children,
}) => {
    const getContentClassName = () => {
        if (defaultFooter)
            return 'layout__content-with-header-footer';
        else return 'layout__content-with-header-only';
    };

    return (
        <div className="wrapper">
            <div className={defaultFooter ? 'layout' : 'layout_no-footer'}>
                <div className="layout__header">
                    <Header 
                        title={backToHeader.title}
                        backUrl={backToHeader.path}
                    />
                </div>
                <div id="content" className={classNames(getContentClassName(), containerClassName)}>
                    {children}
                </div>
                { defaultFooter && (
                    <div className="layout__footer">
                        <Footer />
                    </div>
                )}
            </div>
            <div id="handheld-detection"></div>
        </div>
    );
};

export default withRouter(Layout);