import React, { useEffect, useState } from 'react';

import { getTranslation } from '../../common/helpers/locale';
import { getSuitFromString, isDesktop } from '../../common/helpers/common';
import { useTransactionInfo } from '../../services/pageServices';
import './Transactions.less';
import { THEME_ENUM } from '../../common/constants/keys';
import moment from 'moment';
import Pagination from '../common/Pagination';
import { getCurrentUser } from '../../common/helpers/localStorage';
import { GAME } from '../../common/constants/game/engine';
import { getThemeGameContainer } from '../../common/helpers/theme';
import MobileTransaction from './MobileTransaction';
import DesktopTransaction from './DesktopTransaction';
import classNames from 'classnames';

const Transactions = ({
    theme,
    themeColor,
    engine,
    lang,
    isModal = false
}) => {
    const [info, setInfo] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { response } = useTransactionInfo(getCurrentUser(), page);

    useEffect(() => {
        if (response) {
            var result = response.data.result;
            const gameContainerThemes = getThemeGameContainer(theme, THEME_ENUM.DEFAULT);

            setTotalPages(result.totalPage);
            let data = result.result;
            var rows = data.map((game, i) => {
                var result = "VOID";
                var profit = getTranslation("VOID");
                if (game.gameSession.status === GAME.GAME_STATUS.COMPLETED) {
                    var res = game.gameSession.forceResult || game.gameSession.result;
                    var transactionDetails = game.transactionDetails;
                    result = getSuitFromString(res.suit);
                    var cashOut = game.cashOut;
                    var cls = cashOut > 0? 'up' : 'down';
                    profit = (
                        <>
                        <span className={cls}>
                            {(cashOut > 0)? '+' : ''}
                            &nbsp;
                            {cashOut? cashOut.toLocaleString(undefined, {minimumFractionDigits: 2}) : cashOut}
                        </span>
                        </>
                    );
                } else if (game.gameSession.status === GAME.GAME_STATUS.VOIDED && !cashOut) {
                    profit = '-';
                }
                var gameCreated = moment.utc(game.gameSession.created).format('yyyyMMDD');
                var date = moment
                .utc(game.gameSession.created)
                .format('DD/MM/yyyy HH:mm:ss');

                return isDesktop() ? 
                    <React.Fragment key={i}>
                        <DesktopTransaction 
                            gameCreated={gameCreated}
                            game={game}
                            gameContainerThemes={gameContainerThemes}
                            transactionDetails={transactionDetails}
                            lang={lang}
                            result={result}
                            res={res}
                            date={date}
                            profit={profit}
                        />
                    </React.Fragment>
                : 
                    <React.Fragment key={i}>
                        <MobileTransaction 
                            gameCreated={gameCreated}
                            game={game}
                            gameContainerThemes={gameContainerThemes}
                            transactionDetails={transactionDetails}
                            lang={lang}
                            result={result}
                            res={res}
                            date={date}
                            profit={profit}
                        />
                    </React.Fragment>
            });
            setInfo(rows);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    return (
        <div className='results__container transactions'>
            {
                !isModal && (
                    <div className='head'>
                        {getTranslation("HISTORY")}
                    </div>
                )
            }
            
            <div className={classNames({main: !isModal}, "container-modal")}>
                <div className='container'>
                    {
                        isModal && (
                            <div className={classNames('item')}>
                                <div>
                                    {getTranslation("GAME_ID")}
                                </div>
                                {/* <div>
                                    {getTranslation("TRANSACTION_ID")}
                                </div> */}
                                <div>
                                    {getTranslation("MY_BETS")}
                                </div>
                                <div>
                                    {getTranslation("WIN_AMOUNT")}
                                </div>
                                <div></div>
                                {/* <div>
                                    {getTranslation("DATE_&_TIME")}
                                </div> */}
                            </div>
                        )
                    }
                    {info}
                </div>
            </div>
            <div className='bottom'>
                <Pagination
                    theme={theme}
                    themeColor={themeColor}
                    onClick={setPage}
                    page={page}
                    total={totalPages}
                    />
            </div>
        </div>
    );
};

export default Transactions;