import React from 'react';
import GraphicsBottomContainer from './BottomContainer/GraphicsBottomContainer';
import GraphicsTopContainer from './TopContainer/GraphicsTopContainer';
import GraphicsMain from './GraphicsMain';
import Controls from './BottomContainer/Controls';
import { THEME_CLASS } from '../../../common/constants/keys';

const GraphicsContainer = ({
    theme,
    themeColor
}) => {
    const controls = () => {
        if (theme === THEME_CLASS.DEFAULT) {
            return <Controls
                        theme={theme}
                        themeColor={themeColor}
                        />
        }
    }

    return (
        <>
            <GraphicsTopContainer
                theme={theme}
                themeColor={themeColor}
                />
            <GraphicsMain
                theme={theme}
                themeColor={themeColor} />
            <GraphicsBottomContainer
                theme={theme}
                themeColor={themeColor} />
            { controls() }
        </>
    );
};

export default GraphicsContainer;