import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './SimpleModal.less';
import { getTranslation } from '../../common/helpers/locale';

const SimpleModal = ({
    containerClassName,
    title,
    children
}) => {
    return (
        <div className={
            classNames(
                'modal__container',
                containerClassName
            )
        }>
            <div className='main'>
                <div className='head'>
                    {getTranslation(title)}
                </div>
                {children}
            </div>
        </div>
    )
}

SimpleModal.prototype = {
    containerClassName: PropTypes.string,
}

export default SimpleModal;