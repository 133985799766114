import { SETTINGS } from '../../common/constants/keys';
import Event from '../../common/helpers/events';
import { GAME } from '../../common/constants/game/engine';
const { default: Vec } = require("./Vec");

const opts = {
    speed: 0.00001,
    incrementSpeed: 0.0018,
    boostInterval: 2000
}

class Car {
    constructor(renderer, ctx, suit, { speed=opts.speed, incrementSpeed=opts.incrementSpeed, maxSpeed=2 }) {
        this.ctx = ctx;
        this.renderer = renderer;
        this.suit = suit;
        this.flame = '';
        this.speed = speed;
        this.incrementSpeed = incrementSpeed;
        this.maxSpeed = maxSpeed;
        this.speedState = SPEEDSTATE.NORMAL;
        this.speedNum = 0;
        this.objs = {
            body: this.renderer.getCarSpr(suit),
            flame_big: this.renderer.getFlameBig(suit),
            flame_small: this.renderer.getFlameSmall(suit),
            ind_flame_big: this.renderer.getIndFlame(suit, 'big'),
            ind_flame_small: this.renderer.getIndFlame(suit, 'small'),
            ind_flame_4x: this.renderer.getIndFlame(suit, '4x'),
            ind_flame_5x: this.renderer.getIndFlame(suit, '5x'),
            ind_flame_6x: this.renderer.getIndFlame(suit, '6x'),
            ind_flame_7x: this.renderer.getIndFlame(suit, '7x'),
            ind_flame_8x: this.renderer.getIndFlame(suit, '8x'),
        }
        this.childProps = {
            flame_big: { x: 0, y: (this.objs.body.getDimension().height/2+this.objs.flame_big.getDimension().height/2) / this.objs.body.size },
            flame_small: { x: 0, y: (this.objs.body.getDimension().height/2+this.objs.flame_small.getDimension().height/2) / this.objs.body.size },
            ind_flame_big: { x: 0, y: (this.objs.flame_big.getDimension().height/2+this.objs.ind_flame_big.getDimension().height/2) / this.objs.body.size + 10 },
            ind_flame_small: { x: 0, y: (this.objs.flame_small.getDimension().height/2+this.objs.ind_flame_small.getDimension().height/2) / this.objs.body.size +10 },
            ind_flame_4x: { x: 0, y: (this.objs.flame_big.getDimension().height/2+this.objs.ind_flame_4x.getDimension().height/2) / this.objs.body.size +10 },
            ind_flame_5x: { x: 0, y: (this.objs.flame_big.getDimension().height/2+this.objs.ind_flame_5x.getDimension().height/2) / this.objs.body.size +10 },
            ind_flame_6x: { x: 0, y: (this.objs.flame_big.getDimension().height/2+this.objs.ind_flame_6x.getDimension().height/2) / this.objs.body.size +10 },
            ind_flame_7x: { x: 0, y: (this.objs.flame_big.getDimension().height/2+this.objs.ind_flame_7x.getDimension().height/2) / this.objs.body.size +10 },
            ind_flame_8x: { x: 0, y: (this.objs.flame_big.getDimension().height/2+this.objs.ind_flame_8x.getDimension().height/2) / this.objs.body.size +10 },
        }
        this.data = {
            posY: 0,
            ind_flame: {
                opacity: 0,
                speed: 0.1,
                increment: 1,
            }
        }
        this.restart();
    }

    restart() {
        this.speedState = SPEEDSTATE.NORMAL;
        this.speed=opts.speed;
        this.incrementSpeed=opts.incrementSpeed;
        delete this.objs.body.pos;
        this.objs.body.pos = new Vec();
        this.setFlame('');
        this.active();
        this.attachAll();
    }

    boost(posY) {
        if (this.speedState === SPEEDSTATE.BOOST) return;
        this.speedState = SPEEDSTATE.BOOST;
        this.data.posY = posY;
        if (this.getFlame()) {
            this.enableFlame(false);
        }
    }

    boost2x(posY) {
        if (this.speedState === SPEEDSTATE.BOOST2X) return;
        Event.broadcast(GAME.PLAY_SOUND, {
            name: "TURBO",
            loop: false,
            cancel: "",
            simultaneous: 1
        });
        this.speedState = SPEEDSTATE.BOOST2X;
        this.data.posY = posY;
        this.setFlame('small');
        this.closingFlame();
    }

    boost3x(posY, num='big') {
        if (this.speedState === SPEEDSTATE.BOOST3X) return;
        Event.broadcast(GAME.PLAY_SOUND, {
            name: "TURBO_CAR",
            loop: false,
            cancel: "",
            simultaneous: 1
        });
        this.speedState = SPEEDSTATE.BOOST3X;
        this.speedNum = (num === 'big')? num : num+'x';
        this.data.posY = posY;
        this.setFlame('big');
        this.closingFlame();
    }

    closingFlame() {
        var self = this;
        setTimeout(() => {
            self.setFlame('');
            switch (self.speedState) {
                case SPEEDSTATE.BOOST2X:
                    Event.broadcast(GAME.STOP_SOUND, {
                        name: "TURBO"
                    });
                    break;
                case SPEEDSTATE.BOOST3X:
                    Event.broadcast(GAME.STOP_SOUND, {
                        name: "TURBO_CAR"
                    });
                    break;
                default:
                    break;
            }
            this.speedState = SPEEDSTATE.NORMAL;
        }, SETTINGS.GAME.TICK_RATE);
    }

    setFlame(flame) {
        if (this.flame) {
            this.enableFlame(false);
        }
        this.flame = flame;
        if (this.flame) {
            this.enableFlame();
        }
    }

    getFlame() {
        return this.objs["flame_"+this.flame];
    }

    getTranslation() {
        return this.objs.body.pos;
    }

    translate(x, y) {
        this.objs.body.translate(x, y);
    }

    rotate(rot) {
        this.objs.body.rotate(rot);
    }

    getRotate() {
        return this.objs.body.angle;
    }

    scale(size) {
        this.objs.body.scale(size);
    }

    getDimension() {
        return this.objs.body.getDimension();
    }
    
    active(enable=true) {
        for (var x in this.objs) {
            var obj = this.objs[x];
            if (obj.id.indexOf('booster')>=0) {
                obj.active(false);
                continue;
            }
            obj.active(enable);
        }
        if (this.getFlame()) {
            this.enableFlame(enable);
        }
    }

    enableFlame(enable=true) {
        if (!this.flame) return;
        this.getFlame().active(enable);
        var flameNum = (this.flame === 'small')? this.flame : this.speedNum;
        this.objs['ind_flame_'+flameNum].active(enable);
    }

    orderByZIndex() {
        var ordered = {};
        for (var x in this.objs) {
            var obj = this.objs[x];
            if (!ordered.hasOwnProperty(obj.zIndex)) {
                ordered[obj.zIndex] = [];
            }
            ordered[obj.zIndex].push(obj);
        }
        return ordered;
    }

    run() {
        if (this.flame) {
            if (this.data.ind_flame.opacity > 2) 
                this.data.ind_flame.increment = -1;
            else if (this.data.ind_flame.opacity < -2)
                this.data.ind_flame.increment = 1;
            this.data.ind_flame.opacity += this.data.ind_flame.increment * this.data.ind_flame.speed;
            var flameNum = (this.flame === 'small')? this.flame : this.speedNum;
            this.objs['ind_flame_'+flameNum].setOpacity(this.data.ind_flame.opacity);
        }
    }

    animate() {
        this.run();
        var animats = this.orderByZIndex();
        for (var x in animats) {
            var objs = animats[x];
            for (var o in objs) {
                var obj = objs[o];
                obj.animate();
            }
        }
    }

    isDetached(which) {
        if (!this.objs.hasOwnProperty(which)) return false;
        return this.objs[which].parent === undefined;
    }

    detach(which) {
        if (!this.objs.hasOwnProperty(which)) return false;
        var obj = this.objs[which];
        obj.copyParent(this.objs.body);
        obj.setParent(undefined, { x: this.childProps[which].x, y: this.childProps[which].y });
    }

    attach(which) {
        if (!this.objs.hasOwnProperty(which)) return false;
        var obj = this.objs[which];
        obj.setParent(this.objs.body, this.childProps[which]);
    }

    attachAll() {
        for (var x in this.objs) {
            if (x === "body") continue;
            if (this.isDetached(x)) {
                this.attach(x);
            }
        }
    }
}

const SPEEDSTATE = {
    NORMAL: "NORMAL",
    BOOST: "BOOST",
    BOOST2X: "BOOST2X",
    BOOST3X: "BOOST3X"
};

export default Car;