import Vec from './Vec';

class Sprite {
    constructor(ctx, id, tex, { visibility=true, rendererEnable=true, scaleDivideBy=1, parent=undefined, offset={}, zIndex=0, rigidbody={ vel:new Vec(), acc:new Vec(), maxVel:new Vec(999, 999) } }) {
        this.ctx = ctx;
        this.id = id;
        this.tex = tex;
        this.scaleDivideBy = scaleDivideBy;
        this.parent = parent;
        this.offset = offset;
        this.zIndex = zIndex;
        this.rigidbody = rigidbody;
        this.visibility = visibility;
        this.opacity = 1;
        this.time = Date.now();
        this.centerPivot = true;
        this.rendererEnable = rendererEnable;

        this.oldPos = new Vec();
        this.pos = new Vec();
        this.size = 1;
        this.angle = 0;
    }

    assignCtx(ctx) {
        this.ctx = ctx;
    }

    setOpacity(opacity) {
        this.opacity = opacity;
    }

    setCenterPivot(enable=true) {
        this.centerPivot = enable;
    }

    setParent(parent, opts={}) {
        this.parent = parent;
        this.offset = ("x" in opts && "y" in opts)? new Vec(opts.x, opts.y) : {};
        if ("rotate" in opts) this.angle = opts.rotate;
        if ("zIndex" in opts) this.zIndex = opts.zIndex;
    }

    copyParent(sprite) {
        this.translate(sprite.pos.x, sprite.pos.y);
        this.scale(sprite.size);
        this.rotate(sprite.angle);
        this.setOpacity(sprite.opacity);
        this.active(sprite.visibility);
        this.copyRigidBody(sprite.rigidbody);
    }

    copyRigidBody(body) {
        this.rigidbody = {
            vel: new Vec(body.vel.x, body.vel.y),
            acc: new Vec(body.acc.x, body.acc.y),
            maxVel: new Vec(body.maxVel.x, body.maxVel.y),
        }
    }

    setOffset(offset) {
        this.offset = offset;
    }

    rotate(rot) {
        this.angle = rot;
        return this;
    }

    translate(x, y) {
        this.pos.x = x;
        this.pos.y = y;
        return this;
    }

    scale(size) {
        this.size = size;
        return this;
    }

    getDimension() {
        return { width: this.size*this.tex.width, height: this.size*this.tex.height };
    }

    move(vel=undefined) {
        this.rigidbody.vel = (vel === undefined)? this.rigidbody.vel : vel;
        this.rigidbody.vel.x += this.rigidbody.acc.x;
        this.rigidbody.vel.y += this.rigidbody.acc.y;
        if (this.rigidbody.vel.x > this.rigidbody.maxVel.x) {
            this.rigidbody.vel.x = this.rigidbody.maxVel.x;
        }
        if (this.rigidbody.vel.y > this.rigidbody.maxVel.y) {
            this.rigidbody.vel.y = this.rigidbody.maxVel.y;
        }
        this.translate(this.pos.x+this.rigidbody.vel.x, this.pos.y+this.rigidbody.vel.y);
    }

    active(active=true) {
        this.visibility = active;
        return this;
    }

    animate(pos=-1, width=0, height=0 ) {
        if (!this.visibility) {
            return false;
        }
        
        currentTime = Date.now();
        this.ctx.save();
        this.ctx.globalAlpha = this.opacity;
        var self = (this.parent === undefined)? this : this.parent;
        var x = self.pos.x;
        var y = self.pos.y;
        var offsetX = ("x" in this.offset)? this.offset.x : 0;
        var offsetY = ("y" in this.offset)? this.offset.y : 0;
        x -= this.tex.subImgWidth/2;
        y -= this.tex.height/2;
        var angle = (this.parent === undefined)? this.angle : self.angle + this.angle;
        this.ctx.translate(x+this.tex.subImgWidth/2, y+this.tex.height/2);
        this.ctx.scale(self.size, self.size);
        this.ctx.rotate(angle*Math.PI/180);
        this.ctx.translate(((this.centerPivot)? -this.tex.subImgWidth/2 : 0)+offsetX, ((this.centerPivot)? -this.tex.height/2 : 0)+offsetY);
        if (pos>=0) {
            this.ctx.drawImage(this.tex.img, pos * this.tex.subImgWidth, 0, this.tex.subImgWidth, this.tex.imgHeight, 0, 0, this.tex.subImgWidth + width, this.tex.imgHeight + height);
        }else{
            this.ctx.drawImage(this.tex.img, this.tex.imageId * this.tex.subImgWidth, 0, this.tex.subImgWidth, this.tex.imgHeight, 0, 0, this.tex.subImgWidth + width, this.tex.imgHeight + height);
            var diff = currentTime - this.time;
            if (diff >= 100/this.tex.speed) {
                ++this.tex.imageId;
                this.tex.imageId = this.tex.imageId % this.tex.numOfFrames;
                this.time = currentTime;
            }
        }
        this.ctx.restore();
    }
}
var currentTime;

export default Sprite;