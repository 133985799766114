import classNames from 'classnames';
import React, {useState} from 'react'
import { getTranslation } from '../../common/helpers/locale';
import { DropdownTriggerIcon } from '../common/DropdownTriggerIcon'
import GameHash from '../common/GameHash';
import TransactionResults from './TransactionResults'

const DesktopTransaction = ({
    gameCreated,
    game,
    gameContainerThemes,
    transactionDetails,
    lang,
    result,
    res,
    date,
    profit
}) => {
    const [show, setShow] = useState(false);

    const onShowClick = (key) => {
        setShow({...show, [key]: !show[key]})
    }


    return (
        <div className={classNames("item", {
            void: result === "VOID"
        })}>
            <div className="container-item">
                <div className='game-id'>
                    {gameCreated}{game.gameSession.index}
                </div>
                {/* <div>
                    {game.displayId}
                </div> */}
                <div>
                    <TransactionResults 
                        gameContainerThemes={gameContainerThemes}
                        transactionDetails={transactionDetails}
                        lang={lang}
                        result={result}
                        res={res}
                    />
                </div>
                <div className="profit">
                    {result === "VOID" ? result : profit}
                </div>
                <DropdownTriggerIcon 
                    onClick={()=>{onShowClick(game.displayId)}}
                    show={show[game.displayId]}/>
                {/* <div className='date'>
                    {date}
                </div> */}
            </div>
            
            {
                show[game.displayId] && (
                    <>
                        <div className="bet-details">
                            <div>
                                <span>{getTranslation("TRANSACTION_ID")}:</span>
                            </div>
                            <div>
                                <span>{game.displayId}</span>
                            </div>
                        </div>

                        <div className="bet-details">
                            <div>
                                <span>{getTranslation("DATE")}:</span>
                            </div>
                            <div>
                                <span>{date}&nbsp;GMT</span>
                            </div>
                        </div>

                        <div className="bet-details">
                            <div>
                            <span>{getTranslation("HASH")}:</span>
                            </div>
                            <div>
                                <span style={{display: 'flex'}}><GameHash hash={game.gameSession.hash}/></span>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default DesktopTransaction
