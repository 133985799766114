class Events {
    constructor() {
        this.listeners = {};
    }

    generateId = (id) => {
        return "evt_" + id;
    }

    register = (id, callback) => {
        this.listeners[this.generateId(id)] = callback;
    }

    broadcast = (e, payload=null) => {
        for (var x in this.listeners) {
            var sub = this.listeners[x];
            sub(e, payload);
        }
    }
}

var Event = new Events();

export default Event;