import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import { isUserLoggedIn } from '../../../common/helpers/localStorage';

import Layout from '../layouts/Layout';
import { ROUTES } from '../../../common/constants/routing';
import { useStore } from '../../../store/StateProvider';
import GameModal from '../../common/GameModal';
import ServerMaintenanceReminder from '../../game/message/ServerMaintenanceReminder';
import { STORAGE_SETTINGS, THEME_CLASS } from '../../../common/constants/keys';
import { GAME } from '../../../common/constants/game/engine';
import classNames from 'classnames';
import { isDesktop } from '../../../common/helpers/common';
import { POPUP_WIDTH } from '../../../common/constants/misc';

const LayoutRoute = ({
    component: Component,
    privateRoute,
    containerClassName,
    backToHeader,
    defaultFooter,
    ...rest
}) => {
    const [store] = useStore();
    const { game, settings } = store;
    const [modal, setModal] = useState(<></>);


    useEffect(()=>{
        const DEVICE = isDesktop() ? 'desktop' : 'mobile';

        if ("message" in game && game.message) {
            const {content, modalProps} = getModalContent(game.message.type, game.message, game.storage.theme, DEVICE)

            if(content){
                setModal(<GameModal
                    theme={game.storage.theme}
                    themeColor={settings[STORAGE_SETTINGS.THEME_COLOR]}
                    containerClassName={classNames(
                        "gameMessage__container",
                        game.message.type,
                        DEVICE
                    )}
                    onClose={closeModal}
                    {...modalProps}
                    >
                        {content}
                    </GameModal>);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[game.message])
    
    const closeModal = () => setModal(<></>);

    const getModalContent = (type, payload, theme, device) => {
        switch(type)
        {
            case GAME.ERROR_REDIRECT.SERVER_MAINTENANCE_REMINDER:
                return {
                    content: <ServerMaintenanceReminder
                                theme={theme}
                                start={payload.maintenance_start}
                                end={payload.maintenance_end}
                            />, 
                    modalProps: {
                        width:(theme === THEME_CLASS.CAR)? POPUP_WIDTH.MAINTENANCE[device] : 330
                    }
                };
            default: 
                return {};
        }
    }

    const layoutItem = () => (
        <Route
            {...rest}
            render={(matchProps) => (
                <div className='root__wrapper'>
                    {modal && modal}
                    <Layout
                        containerClassName={containerClassName}
                        backToHeader={backToHeader}
                        defaultFooter={defaultFooter}
                    >
                        <Component {...matchProps} />
                    </Layout>
                </div>
            )}
        />
    );

    return privateRoute ? (
        isUserLoggedIn() ? (
            layoutItem()
        ) : (
            <Redirect to={ROUTES.LOGIN} />
        )
    ) : (
        layoutItem()
    );
};

LayoutRoute.defaultProps = {
    backToHeader: {
        path: '/', 
        title: "CARD_RACING"
    },
    containerClassName: null,
    privateRoute: false,
    defaultFooter: true,
};

LayoutRoute.protoTypes = {
    backToHeader: PropTypes.shape({
        path: PropTypes.string,
        title: PropTypes.string.isRequired,
    }),
    component: PropTypes.elementType.isRequired,
    containerClassName: PropTypes.string,
    privateRoute: PropTypes.bool,
    defaultFooter: PropTypes.bool,
};

export default LayoutRoute;