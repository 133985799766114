import React from 'react';
import { THEME_CLASS } from '../../../common/constants/keys';
import { getTranslation } from '../../../common/helpers/locale';
import './AccountLocked.less';

const AccountLocked = ({
    theme,
    themeColor
}) => {
    return (
        <>
        { theme === THEME_CLASS.CAR? (
            <>
            <div className='img'>
                <div className='access-denied flickering'>
                    {getTranslation("ACCESS_DENIED")}
                </div>
            </div>
            <div>
                <h1>{getTranslation("ACCOUNT_LOCKED")}</h1>
                <div className="content">
                    {getTranslation("ACCOUNT_LOCKED_CONTENT")}
                    <br />
                    {getTranslation("ACCOUNT_LOCKED_CONTENT_2")}
                </div>
            </div>
            </>
        ) : (
            <>
            <div className='img'></div>
            <h1>{getTranslation("ACCOUNT_LOCKED")}</h1>
            <div className="content">
                {getTranslation("ACCOUNT_LOCKED_CONTENT")}
                <br />
                {getTranslation("ACCOUNT_LOCKED_CONTENT_2")}
            </div>
            <div className="contact-us">
                {getTranslation("CONTACT_US")}
            </div>
            </>
        )}
        </>
    )
};

export default AccountLocked;