import useApi from './hooks/useApi';
import { API } from '../common/constants/api';
import { useEffect } from 'react';
import { useDispatch } from '../store/StateProvider';
import { verifyThirdPartyFailure, verifyThirdPartySuccess } from '../common/actions/userActions';
import { configsService } from './configsService';
import {  getCurrentUser } from '../common/helpers/localStorage';

const { HOST, API_BASE, CLIENT, HOST_GAME_LAUNCHER } = API;

export function useVerifyThirdParty(payload, signature, refererUrl, platform) {
    const dispatch = useDispatch();

    let oldSessionId = getCurrentUser();
    if (oldSessionId === undefined)
    {
        oldSessionId = '';
    }
    let oldCurrency = undefined;
    if (oldCurrency === undefined)
    {
        oldCurrency = '';
    }

    if (platform === undefined)
    { 
        platform = '';
    }

    const { loading, error, response } = useApi({
        method: 'GET',
        trigger: payload,
        url: `${HOST_GAME_LAUNCHER}${API_BASE}${CLIENT.VERIFY_THIRD_PARTY}?payload=${encodeURIComponent(payload)}&signature=${encodeURIComponent(signature)}&refererUrl=${encodeURIComponent(refererUrl)}&oSession=${encodeURIComponent(oldSessionId)}&oCurrency=${encodeURIComponent(oldCurrency)}&platform=${encodeURIComponent(platform)}`,
    });

    useEffect(() => {
        configsService(response, error);
        if (error) {
            if (error.data) {
                verifyThirdPartyFailure(dispatch, error.data);
                return;
            }
        }

        if (response) {
            verifyThirdPartySuccess(dispatch, response.data);
        }
    }, [response, error]);

    return { error, loading, response };
}


export function useVerifyGameSetting(payload) {
    const { loading, error, response } = useApi({
        method: 'GET',
        trigger: Object.keys(payload).length !== 0,
        url: `${HOST_GAME_LAUNCHER}${API_BASE}${CLIENT.VERIFY_GAME_SETTING}?payload=${encodeURIComponent(
            payload.payload,
        )}&signature=${encodeURIComponent(payload.signature)}`,
    });

    useEffect(() => {
        configsService(response);

        if (response) {
        }
    }, [response, error]);

    return { error, loading, response };
}
