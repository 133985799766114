import React, { useState } from 'react'

import { CopyFilled } from '@ant-design/icons';
import { Popover } from 'antd';
import { getTranslation } from '../../common/helpers/locale';

import './CopyButton.less';

const CopyButton = ({ valueCopy }) => {
    const [visible, setVisible] = useState(false);
    const [timeoutVar, setTimeoutVar] = useState();

    const copyText = () => {
        navigator.clipboard.writeText(valueCopy);
        setVisible(true);

        if(timeoutVar) {
            clearTimeout(timeoutVar);
        }
        setTimeoutVar(
            setTimeout(()=>{setVisible(false)}, 500)
        );
    }

    return (
        <Popover content={getTranslation('COPIED')}  trigger="click" visible={visible} overlayClassName="copy-button">
            <CopyFilled  onClick={copyText} style={{fontSize: '13px', marginLeft: '8px', color: 'white'}}/>
        </Popover>
    )
}

export default CopyButton