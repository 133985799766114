import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';

import './BorderContainer.less';

const BorderContainer = ({
    centerAlign = true,
    containerClassName,
    minWidth,
    leftRightWidth,
    height,
    leftImg,
    centerImg,
    rightImg,
    children,
    onClick,
    ...rest
}) => {
    const getLeftImg = () => {
        if (leftImg) {
            return (
                <div style={{ backgroundImage: 'url('+leftImg.default+')', backgroundSize: leftRightWidth+'px'+' 100%', width: leftRightWidth+'px', height: height+'px' }}></div>
            );
        }
    }
    const getRightImg = () => {
        if (rightImg) {
            return (
                <div style={{ backgroundImage: 'url('+rightImg.default+')', backgroundSize: leftRightWidth+'px'+' 100%', width: leftRightWidth+'px', height: height+'px' }}></div>
            );
        }
    }
    const style = () => {
        let styles = {
            backgroundImage: 'url('+centerImg.default+')', 
            backgroundSize: '100% '+height+'px', 
            height: height, 
            width: `calc(100% - ${leftRightWidth}px * 2)`
        }
        if (minWidth) {
            styles.minWidth = minWidth;
        }
        return styles;
    }
    return (
        <div className={classNames('borderItem', containerClassName)} style={{ height: height+'px' }} onClick={onClick}>
            {getLeftImg()}
            <div className={classNames('main', centerAlign ? 'center' : '')} style={style()}>
                {children}
            </div>
            {getRightImg()}
        </div>
    );
};

BorderContainer.protoTypes = {
    centerAlign: PropTypes.bool,
    containerClassName: PropTypes.string,
    minWidth: PropTypes.number,
    leftRightWidth: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    leftImg: PropTypes.string.isRequired,
    centerImg: PropTypes.string.isRequired,
    rightImg: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

export default BorderContainer;