import React from 'react'

import { Col, Row } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';

import { getTranslation } from '../../common/helpers/locale';
import { getStringFromSuit, getSuitFromString } from '../../common/helpers/common';
import ImgIcon from '../common/ImgIcon';
import { CAR_COLOR } from '../../common/constants/keys';

const TransactionResults = ({ gameContainerThemes, transactionDetails, lang, result, res}) => {
    return (
        <div>
            <Row justify="space-between" align="middle">
                <Col className="transaction__suit-container">
                    <Row justify="space-between" align="middle">
                    {
                        _.map(gameContainerThemes.SUITS, (suit, index)=>{
                            if(index !== "BACK"){
                            const selectedSuit =_.find(transactionDetails, (detail) => {return detail.betValue === getStringFromSuit(index)});
                                return  (
                                <Col 
                                    className={classNames("transaction__background bet-options__suit", {active: selectedSuit !== undefined })} key={index} > 
                                    { result !== "VOID" && <ImgIcon icon={suit} width={20} height={20}/> }
                                    {
                                        result === index ? <span className={classNames("transaction__win-text", lang)}>{getTranslation("WIN")}</span> : ''
                                    }
                                </Col>)
                            }
                        })
                    }     
                    {
                        _.map(CAR_COLOR, (color) => {

                            const selectedColor =_.find(transactionDetails, (detail) => {return detail.betValue.toLocaleUpperCase() === color});

                            return (
                            <Col key={color} className={classNames("transaction__background transaction__color",
                                {
                                    "transaction__black": color === CAR_COLOR.BLACK
                                },
                                {
                                    "transaction__red" : color === CAR_COLOR.RED
                                },
                                {
                                    "active" : selectedColor !== undefined
                                })}>
                                {
                                    (res && res.color.toLocaleUpperCase() === color) ? <span className={classNames("transaction__win-text", lang)}>{getTranslation("WIN")}</span> : ''
                                }
                            </Col>
                            )
                        })
                    }
                    </Row>
                    
                    <Row justify="space-between" align="middle" className="transaction__payout-container">
                    {
                        result === "VOID" ? <span style={{textAlign:'center', fontStyle: 'italic'}}>{result}</span> : ''
                    }
                    {
                        result !== "VOID" && 
                        _.map(gameContainerThemes.SUITS, (suit, index)=>{
                            if(index !== "BACK"){
                                return  (
                                <Col 
                                    className={classNames("transaction__payout-info")} key={index} > 
                                    {
                                        _.map(transactionDetails,(detail) => {
                                            return detail.betType === 'Suit' && getSuitFromString(detail.betValue) === index ? 
                                                <span className={classNames({"transaction__payout-info-negative" : detail.winLose < 0})} key={detail.betValue}>
                                                    {`${detail.winLose > 0 ? "+" : ""}${detail.winLose.toFixed(2)}`}
                                                </span>
                                                : null
                                        })
                                    }
                                </Col>)
                            }
                        })
                    }     
                    {
                        result !== "VOID" && 
                        _.map(CAR_COLOR, (color) => {
                            return (
                            <Col className={classNames("transaction__payout-info")} key={color}>
                                {
                                    _.map(transactionDetails, (detail) => {
                                        return detail.betType === "Color" && detail.betValue.toLocaleUpperCase() === color ? 
                                        <span className={classNames({"transaction__payout-info-negative" : detail.winLose < 0})} key={detail.betValue}>
                                            {`${detail.winLose > 0 ? "+" : ""}${detail.winLose.toFixed(2)}`}
                                        </span> 
                                        : null
                                    })   
                                }
                            </Col>
                            )
                        })
                    }
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default TransactionResults
