import React from 'react'
import { enableSetting } from '../../../../common/actions/settingsActions'

import { ICONS } from '../../../../common/constants/iconWrapper'
import { getTranslation } from '../../../../common/helpers/locale'
import { useStore } from '../../../../store/StateProvider'
import BorderContainer from '../../../common/BorderContainer'
import ImgIcon from '../../../common/ImgIcon'

const CarTopContainer = ({ themes, sessionId, balance}) => {

    const dispatch = useStore()[1];
    
    const toggleSetting = () => {
        enableSetting(dispatch);
    }

    return (
        <>
            <BorderContainer
                centerAlign={false}
                containerClassName="bar-top game-id"
                minWidth={120}
                leftRightWidth={0}
                height={40}
                centerImg={themes.GAME_ID}
                >
                <div className="top">{ sessionId }</div>
                <label className="title">{getTranslation("GAME_ID")}</label>
                </BorderContainer>
            <BorderContainer
                centerAlign={false}
                containerClassName="bar-top balance"
                minWidth={120}
                leftRightWidth={0}
                height={40}
                centerImg={themes.BALANCE}
                >
                    <div className="top">{Number(balance).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    <label className="title">{getTranslation("BALANCE")}</label>
                </BorderContainer>
            <BorderContainer
                centerAlign={false}
                containerClassName="bar-top setting"
                minWidth={96}
                leftRightWidth={0}
                height={40}
                centerImg={themes.SETTING}
                onClick={toggleSetting}
                >
                <div className="top">
                    <ImgIcon
                        icon={ICONS.COMMON.HAMBURGER_MENU}
                        />
                </div>
                <label className="title">{getTranslation("SETTING")}</label>
                </BorderContainer>
        </>
    )
}

export default CarTopContainer
