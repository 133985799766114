import React from 'react';

import { UpOutlined, DownOutlined } from '@ant-design/icons';

import './DropdownTriggerIcon.less';

export const DropdownTriggerIcon = ({ show, onClick }) => {
  return (
    <div className="drop-down-trigger">
        <div onClick={onClick}>
            { show ? <UpOutlined /> : <DownOutlined /> }
        </div>
    </div>
  )
};
