import React, { useEffect, useState } from 'react';
import { ROUTES } from '../../../common/constants/routing';

import Engine from '../../../engine/Engine';

export const AppProvider = (props) => {
    const engine = Engine.getInstance();
    const [initDone, setInitDone] = useState(false);

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (
            window.location.pathname.includes(ROUTES.MAINTENANCE) || 
            window.location.pathname.includes(ROUTES.ERROR)
        ) {
            setInitDone(true);
        } else {
            engine.init();
        }
    }, []);

    useEffect(() => {
        let engineDone = false;
        if (engine.service.data.connection) {
            if (engine.service.data.connecting) {
                engine.service.maintenanceNotification();
                engine.service.shutDownNotification();
            }
            engineDone = true;
        }
        if (engineDone) {
            setInitDone(true);
        }
    }, [engine.service.data.connection]);

    // ============================================
    // RENDER
    // ============================================

    return initDone && <>{props.children}</>;
}