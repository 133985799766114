import { ACTIONS } from '../constants/actions';

export default function userReducer(user, { type, payload = null }) {
    switch (type) {
        case ACTIONS.ERROR_REDIRECT_STATUSES:
            return {
                ...user,
                errorRedirect: payload
            };
        case ACTIONS.PAGE_URL:
            return {
                ...user,
                pageUrl: payload
            };
        case ACTIONS.VERIFY_THIRD_PARTY_USER_FAILURE:
            return {
                ...user,
                error: payload
            }
        case ACTIONS.VERIFY_THIRD_PARTY_USER_SUCCESS:
            return {
                ...user,
                success: payload
            };
        default:
            return user;
    }
}