import _ from 'lodash';
import { CARD, CAR_COLOR } from '../constants/keys';
import { GAME } from '../constants/game/engine';
import { isInteger } from './common';

const GameLib = {
    parseBet: (betString) => {
        betString = String(betString);
    
        if (!/^\d+$/.test(betString)) {
            return new Error('Bet should contain only digits');
        }
    
        var bet = parseInt(betString);
    
        if (bet < 1) {
            return new Error('Bet should be at least 1');
        }
        
        if (_.isNaN(bet) || Math.floor(bet) !== bet) {
            return new Error('Bet should be an integer greater than or equal to one');
        }
    
        return true;
    },

    parseCard: (cardString) => {
        if (!(cardString in CARD) && !(cardString in CAR_COLOR)) return new Error('Invalid card');
        return true;
    },

    getRealBuyAmount: (buyAmount) => {
        return buyAmount;
    },

    canUserBet: (betInvalid, cardInvalid) => {
        if (betInvalid instanceof Error) {
            return new Error(betInvalid);
        }
        if (cardInvalid instanceof Error) {
            return new Error(cardInvalid);
        }
        return true;
    },

    currentPlay: (engine) => {
        if (!engine.username) {
            return undefined;
        }else{
            return engine.bets[engine.username];
        }
    },

    currentPlaying: function(engine) {
        var player = this.currentPlay(engine);
        return player && player.bet && !player.stoppedCard;
    },

    isUserWon: function(engine, result=undefined) {
        var player = this.currentPlay(engine);
        if (player !== undefined && ((result && player.suit === result.suit) || player.stoppedCard)) {
            return player;
        }
        return undefined;
    },

    isUserLose: function(engine) {
        var player = this.currentPlay(engine);
        if (player !== undefined && (!("stoppedCard" in player) || !player.stoppedCard || player.stoppedCard === undefined) && engine.gameState === GAME.GAME_STATE.ENDED) {
            return player;
        }
        return undefined;
    },

    notPlaying: function(engine) {
        var currentPlay = this.currentPlay(engine);
        return !(engine.gameState === GAME.GAME_STATE.STARTED && currentPlay && !currentPlay.stoppedCard);
    },

    isBetting: (engine) => {
        if (!engine.username) return false;
        if (engine.nextBet.amount) return true;
        for (var x in engine.joiners) {
            if (engine.joiners[x] === engine.username) {
                return true;
            }
        }
        return false;
    },

    calculateWonAmount: (bet, result) => {
        var calcRank = (isInteger(result.rank))? parseInt(result.rank) : 10;
        return bet * calcRank;
    }
};

export default GameLib;