import { CARD, CAR_COLOR, STORAGE } from "../constants/keys";
import { getLocalStorage } from "./localStorage";

export const isMobileOrSmall = () => {
    if (!document.getElementById('handheld-detection')) {
        return false;
    }
    return window.getComputedStyle(document.getElementById('handheld-detection'), null).display === 'none';
}

export const getRefererUrl = () => {
    let refererUrl = document.referrer;
    if (refererUrl === '' || refererUrl === undefined)
    {  
        refererUrl = window.location.origin;
    }
    return refererUrl;
}

export const getSuitFromCard = (str) => {
    return str.slice(-1);
}

export const getSuitFromString = (str) => {
    switch (str) {
        case "c":
            return CARD.CLUB;
        case "h":
            return CARD.HEART;
        case "s":
            return CARD.SPADE;
        case "d":
            return CARD.DIAMOND;
        default:
            return null;
    }
}

export const getStringFromSuit = (suit) => {
    switch (suit) {
        case CARD.CLUB:
            return "c";
        case CARD.HEART:
            return "h";
        case CARD.SPADE:
            return "s";
        case CARD.DIAMOND:
            return "d";
        case CAR_COLOR.BLACK:
            return "Black";
        case CAR_COLOR.RED:
            return "Red";
        default:
            return null;
    }
}

export const getResultTotalSize = (result) => {
    var total = 0;
    for (var x in result) {
        total += result[x].count;
    }
    return total;
}

export const isCardsEqual = (c1, c2) => {
    return (c1.rank === c2.rank && c1.suit === c2.suit);
}

export const getRealCurrency = (currency) => {
    return currency.replace('-F2P', '');
};

export const getCurrencyMultiples = (currency) => {
    switch (currency) {
        case "VND":
        case "IDR":
        case "VND-F2P":
        case "IDR-F2P":
            return 1000;
        
        default:
            return 1;
    }
};

export const isNumber = (nVal) => {
    return typeof nVal === "number" && isFinite(nVal) && nVal > -9007199254740992 && nVal < 9007199254740992;
};

export const isInteger = (nVal) => {
    return typeof nVal === "number" && isFinite(nVal) && nVal > -9007199254740992 && nVal < 9007199254740992 && Math.floor(nVal) === nVal;
}

export const setBodyProps = (theme, themeColor) => {
    document.body.classList.remove(...document.body.classList);
    document.body.classList.add(theme);
    document.body.classList.add(themeColor);
}

export const toBool = (str) => {
    if (typeof(str) === 'string') {
        return str === 'true';
    }
    else if (typeof(str) === 'boolean') {
        return str;
    }
    else return undefined;
}

export const getTheme = (theme) => {
    return theme.replace('-theme', '') + '-theme';
}

export const getThemeType = (theme) => {
    return theme.replace('-theme', '');
}

export const getRank = (rank) => {
    switch (rank) {
        case 'T':
            return 10;
        default:
            return rank;
    }
}

export const isChina = () => getLocalStorage(STORAGE.IS_CHINA);

export const getLocalizedSoundName = (soundName, lang) => {
    let postfix = '';

    if (lang === 'zh-CN') 
        postfix = "_CN";
    else if (lang === 'id-ID')
        postfix = "_ID";
    else if (lang === 'th-TH')
        postfix = "_TH";
    else if (lang === 'vi-VN')
        postfix = "_VN";
    else
        postfix = "_EN";
    
    return soundName + postfix;
};

export const detectMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
};

export const setIsDesktop = () => window.isDesktop = !detectMobile();

export const isDesktop = () => window.isDesktop;

export const getHashVerifyLink = () => {
    return window.location.origin.includes('localhost')
        ? 'http://localhost:15100'
        : window.location.origin.includes('funplay')
        ? 'https://hash-mini.sit.advantplay.net'
        : window.location.origin.replace('cardracing', 'hash-mini');
};
