import React, { useState, useEffect } from 'react';
import Event from '../../common/helpers/events';
import { useStore } from '../../store/StateProvider';
import { GAME } from '../../common/constants/game/engine';
import GraphicsContainer from './GraphicsContainer/GraphicsContainer';
import GameModal from '../common/GameModal';
import classNames from 'classnames';
import Winning from './message/Winning';
import AccountLocked from './message/AccountLocked';
import InsufficientBalance from './message/InsufficientBalance';
import GameLib from '../../common/helpers/game';
import { getSuitFromString, isDesktop, setBodyProps, toBool } from '../../common/helpers/common';
import { GAME_RESULT, THEME_CLASS, SETTINGS, STORAGE_SETTINGS } from '../../common/constants/keys';
import { clearMessage, showWinning, showInsufficientBalance, showServerMaintenanceReminder, showBuyActionError } from '../../common/actions/gameActions';
import { disablePanel, enablePanel, disableSetting, setThemeColor, enableSound, disableSound, saveSetting, closeHistoryModal, closeRecentModal, closeHelpModal } from '../../common/actions/settingsActions';
import Sound from '../../engine/Audio';
import BuyError from './message/BuyError';
import SettingsModal from './Modals/SettingsModal';
import HistoryModal from './Modals/HistoryModal';
import HelpModal from './Modals/HelpModal';
import RecentModal from './Modals/RecentModal';
import { POPUP_WIDTH } from '../../common/constants/misc';

const Home = ({
    theme, 
    themeColor,
    engine
}) => {
    const [store, dispatch] = useStore();
    const { game, settings } = store;
    const [messageModal, setMessageModal] = useState(null);
    const [settingModal, setSettingModal] = useState(false);
    const [historyModal, setHistoryModal] = useState(false)
    const [helpModal, setHelpModal] = useState(false)
    const [recentModal, setRecentModal] = useState(false)
    const [triggerState, setTriggerState] = useState('');
    const [timedOut, setTimedOut] = useState(false);
    const [soundEnable, setSoundEnable] = useState(false);
    const [settingsColor, setSettingsColor] = useState('');
    const [oldSoundEnable, setOldSoundEnable] = useState('');
    const [oldThemeColor, setOldThemeColor] = useState('');
    const [anonymous, setAnonymous] = useState(false);
    const [backgroundOST, setBackgroundOST] = useState(false);

    const on = (event, payload=null) => {
        switch (event) {
            case GAME.GAME_STATE.STARTING:
            case GAME.GAME_STATE.STARTED:
            case GAME.GAME_STATE.ENDED:
                _onGameStateChange(event);
                break;
            case GAME.MESSAGE.INSUFFICIENT_BALANCE:
                showInsufficientBalance(dispatch);
                break;
            case GAME.BET_PLACED:
            case GAME.BET_PLACING:
            case GAME.BET_QUEUED:
            case GAME.PLAYER_BET:
            case GAME.CASHED_OUT:
            case GAME.CANCEL_BET:
            case GAME.WIN_POPUP:
                setTriggerState(event);
                break;
            case GAME.ERROR_REDIRECT.SERVER_MAINTENANCE_REMINDER:
                showServerMaintenanceReminder(dispatch, payload);
                break;
            case GAME.MESSAGE.BUY_ACTION_ERROR:
                showBuyActionError(dispatch);
                break;
            case GAME.PLAY_SOUND:
                Sound.play(payload.name, payload.loop, payload.cancel, payload.simultaneous);
                break;
            case GAME.STOP_SOUND:
                Sound.stop(payload.name);
                break;
            default:
                break;
        }
    }
    Event.register("Home", on);

    const _onGameStateChange = (event) => {
        setTriggerState(event);
        ctrlPanel(event);
        _onSoundChange(event);
    }

    const _onSoundChange = (event) => {
        switch (event) {
            case GAME.GAME_STATE.STARTING:
                Event.broadcast(GAME.PLAY_SOUND, {
                    name: "PLACE_BETS",
                    loop: false,
                    cancel: "ALL",
                    simultaneous: 1
                });
                break;
            case GAME.GAME_STATE.STARTED:
                if (backgroundOST) {
                    Event.broadcast(GAME.PLAY_SOUND, {
                        name: "BACKGROUND_ALT",
                        loop: true,
                        cancel: "ALL",
                        simultaneous: 1
                    });
                    setBackgroundOST(!backgroundOST);
                }else{
                    Event.broadcast(GAME.PLAY_SOUND, {
                        name: "BACKGROUND",
                        loop: true,
                        cancel: "ALL",
                        simultaneous: 1
                    });
                    setBackgroundOST(!backgroundOST);
                }
                break;
            case GAME.GAME_STATE.ENDED:
                //
                break;
            default:
                break;
        }
    }

    const ctrlPanel = (event) => {
        if (event in GAME.GAME_CONNECTION || event in GAME.SOCKET) {
            return;
        }
        if (event === GAME.GAME_STATE.STARTING) {
            enablePanel(dispatch);
        }else{
            disablePanel(dispatch);
        }
    }

    useEffect(() => {
        if (!game || !("anonymous" in game.storage)) return;
        setAnonymous(game.storage.anonymous);
    }, [game.storage.anonymous]);

    useEffect(() => {
        if ("gameState" in game) {
            ctrlPanel(game.gameState);
            if (game.gameState === GAME.GAME_STATE.STARTING) {
                setTimedOut(true);
            }
        }
    }, [game.gameState]);

    useEffect(() => {
        if (triggerState !== GAME.WIN_POPUP && triggerState !== GAME.CASHED_OUT) return;
        var player = GameLib.isUserWon(engine.data, engine.data.currentResult);
        var playerWon = (player !== undefined)? true : false;
        if (playerWon && !player.stoppedCard) {
            console.log('wait for cashedout');
            return;
        }
        if (player === undefined) {
            player = GameLib.currentPlay(engine.data);
        }
        var isPlaying = (player !== undefined)? true : false;
        if (player === undefined && !(engine.data.username in engine.data.spectacles)) {
            engine.data.spectacles[engine.data.username] = {};
            player = engine.data.spectacles[engine.data.username];
        }
        if ((!("know" in player) || player.know < SETTINGS.GAME.WON_MSG_INTERVAL)) {
            if (!("know" in player)) {
                player.know = 0;
            }
            setTimeout(() => {
                var suit = (playerWon)? getSuitFromString(player.stoppedCard.suit) : getSuitFromString(engine.data.gameHistory[0].result.suit);
                var status = (playerWon)? GAME_RESULT.WIN : ((isPlaying)? GAME_RESULT.LOSE : GAME_RESULT.DEFAULT);
                const winningTrack = "WINNING__"+suit;
                if (playerWon) {
                    Event.broadcast(GAME.PLAY_SOUND, {
                        name: "CONGRATULATIONS",
                        loop: false,
                        cancel: "",
                        simultaneous: 1
                    });
                    setTimeout(() => {
                        Event.broadcast(GAME.PLAY_SOUND, {
                            name: winningTrack,
                            loop: false,
                            cancel: "",
                            simultaneous: 1
                        });
                    }, game.storage.lang === 'zh-CN' ? 1000 : 2000);
                }else{
                    Event.broadcast(GAME.PLAY_SOUND, {
                        name: winningTrack,
                        loop: false,
                        cancel: "",
                        simultaneous: 1
                    });
                }
                showWinning(dispatch, {
                    profit: (playerWon)? player.profit.toLocaleString(undefined, {minimumFractionDigits: 2}) : 0,
                    suit: suit,
                    status: status
                });
            }, SETTINGS.GAME.TICK_RATE);
        }
    }, [triggerState]);

    useEffect(() => {
        if (timedOut) {
            clearMessage(dispatch);
            setTimedOut(false);
        }
    }, [timedOut]);

    useEffect(() => {
        if (!game) return;
        const closeModal = () => {
            setMessageModal(<></>);
        }
        
        if ("message" in game && game.message) {
            let type = game.message.type;
            const DEVICE = isDesktop() ? 'desktop' : 'mobile';
            let classes = [type, DEVICE];
            let gameModalExtras = {};
            const modal = (msg, payload, classes) => {
                switch (msg) {
                    case GAME.MESSAGE.WINNING:
                        classes.push(payload.status);
                        gameModalExtras = {
                            width:(theme === THEME_CLASS.CAR || theme === THEME_CLASS.OKBET)? POPUP_WIDTH.WINNING[DEVICE] : 600,
                            closable:false
                        };
                        return <Winning
                                    theme={theme}
                                    profit={payload.profit}
                                    type={payload.suit}
                                    status={payload.status}
                                />;
                    case GAME.MESSAGE.INSUFFICIENT_BALANCE:
                        gameModalExtras = {
                            width:(theme === THEME_CLASS.CAR || theme === THEME_CLASS.OKBET)? POPUP_WIDTH.INSUFFICIENT_BALANCE[DEVICE] : 330
                        };
                        return <InsufficientBalance
                                    theme={theme}
                                    themeColor={settingsColor}
                                />;
                    case GAME.MESSAGE.ACCOUNT_LOCKED:
                        gameModalExtras = {
                            width:(theme === THEME_CLASS.CAR || theme === THEME_CLASS.OKBET)? POPUP_WIDTH.ACCOUNT_LOCKED[DEVICE] : 330
                        };
                        return <AccountLocked
                                    theme={theme}
                                    themeColor={settingsColor}
                                />;
                    case GAME.MESSAGE.BUY_ACTION_ERROR:
                        gameModalExtras = {
                            width:(theme === THEME_CLASS.CAR || theme === THEME_CLASS.OKBET)? POPUP_WIDTH.BUY_ERROR[DEVICE] : 330
                        };
                        return <BuyError />
                    default:
                        return;
                }
            }
            const content = modal(type, game.message, classes);
            
            if(content){
                setMessageModal(
                    <GameModal
                        theme={theme}
                        themeColor={settingsColor}
                        containerClassName={classNames(
                            "gameMessage__container",
                            classes
                        )}
                        onClose={closeModal}
                        {...gameModalExtras}
                        >
                            {content}
                        </GameModal>);
            }
        }

        return () => {
            closeModal();
        }
    }, [game.message]);

    useEffect(() => {
        if (!settings) return;
        if (STORAGE_SETTINGS.THEME_COLOR in settings) {
            setSettingsColor(settings[STORAGE_SETTINGS.THEME_COLOR]);
        }
    }, [settings[STORAGE_SETTINGS.THEME_COLOR]]);

    useEffect(() => {
        if (!settings) return;
        if (STORAGE_SETTINGS.SOUND_ENABLE in settings) {
            setSoundEnable(toBool(settings[STORAGE_SETTINGS.SOUND_ENABLE]));
        }
    }, [settings[STORAGE_SETTINGS.SOUND_ENABLE]]);

    useEffect(() => {
        if (!settings) return;
        if(settings.historyModalVisible){
            setHistoryModal(true);
        }else{
            setHistoryModal(false);
        }
        
        if(settings.helpModalVisible){
            setHelpModal(true);
        }else{
            setHelpModal(false);
        }

        if(settings.recentModalVisible){
            setRecentModal(true);
        }else{
            setRecentModal(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    useEffect(() => {
        if (settings.settingEnable) {
            setOldSoundEnable(soundEnable);
            setOldThemeColor(settingsColor);
            setSettingModal(true)
        }else{
            setSettingModal(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[settings.settingEnable])

    useEffect(() => {
        if (settings && settings.soundEnable) {
            Sound.unmute();
        }
        
        return () => {
            Sound.mute();
        }
    }, []);

    const confirmSettingModal = () => {
        if (settingsColor !== oldThemeColor || soundEnable !== oldSoundEnable) {
            saveSetting(dispatch);
        }
        closeModal("setting");
    }
    
    const _changeThemeColor = (clr) => {
        if (anonymous) return;
        setThemeColor(dispatch, clr);
    }

    useEffect(() => {
        if (!settings || !("themeColor" in settings)) return;
        setBodyProps(theme, settings.themeColor);
    }, [settings.themeColor]);

    useEffect(() => {
        if (!settings || !("soundEnable" in settings)) return;
        _onSoundChange(engine.data.gameState);
    }, [settings.soundEnable]);
    
    const _changeSoundEnable = (enable) => {
        if (enable) {
            enableSound(dispatch);
        }else{
            disableSound(dispatch);
        }
    }

    const revertSetting = () => {
        _changeSoundEnable(oldSoundEnable);
        _changeThemeColor(oldThemeColor);
        closeModal("setting");
    }
    
    const closeModal = (aspect) => {
        switch(aspect){
            case "setting": 
                disableSetting(dispatch);
                break;
            case "history":
                closeHistoryModal(dispatch);
                break;
            case "recent": 
                closeRecentModal(dispatch);
                break;
            case "help": 
                closeHelpModal(dispatch);
                break;
            default:
                break;
        }
    }

    return (
        <div className="game__container">
            {messageModal && messageModal}
            {(theme === THEME_CLASS.CAR || theme === THEME_CLASS.OKBET) && (
                <>
                    <SettingsModal 
                        anonymous={anonymous}
                        soundEnable={soundEnable}
                        visible={settingModal}
                        settingsColor={settingsColor}
                        confirmSettingModal={confirmSettingModal}
                        _changeSoundEnable={_changeSoundEnable}
                        _changeThemeColor={_changeThemeColor}
                        revertSetting={revertSetting}
                    />
                    <HistoryModal 
                        visible={historyModal}
                        theme={theme} 
                        themeColor={themeColor}
                        engine={engine}
                        lang={game.storage.lang}
                        onClose={() => closeModal("history")}
                    />
                    <HelpModal 
                        visible={helpModal} 
                        theme={theme} 
                        themeColor={themeColor}
                        engine={engine}
                        onClose={() =>  closeModal("help")}
                    />
                    <RecentModal
                        visible={recentModal}
                        theme={theme} 
                        themeColor={themeColor}
                        engine={engine}
                        onClose={() => closeModal("recent")}
                    />
                </>
            )}
            <GraphicsContainer
                theme={theme}
                themeColor={settingsColor}
            />
        </div>
    )
}

export default Home;