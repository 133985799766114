import { GAME } from '../constants/game/engine';

export default function gameReducer(game, { type, payload = null }) {
    switch (type) {
        case GAME.SET_GAME_STORAGE:
            return {
                ...game,
                ...payload
            };
        case GAME.SET_GAME_DATA:
            return {
                ...game,
                ...payload
            };
        case GAME.GAME_CONNECTION.GAME_CONNECTED:
            return {
                ...game,
                ...payload,
                isConnected: true
            };
        case GAME.GAME_CONNECTION.GAME_DISCONNECTED:
            return {
                ...game,
                isConnected: false
            };
        case GAME.GAME_CONNECTION.GAME_ENTER:
            return{
                ...game,
                isEnter: true
            }
        case GAME.BUY_AMOUNT:
            return {
                ...game,
                buyAmount: payload
            };
        case GAME.BUY_CARD:
            return {
                ...game,
                buyCard: payload
            };
        case GAME.CANCEL_BUY:
            return {
                ...game,
                placeBet: -1
            };
        case GAME.PLACE_BUY:
            return {
                ...game,
                placeBet: Date.now()
            }
        case GAME.MESSAGE.COUNTDOWN:
            return {
                ...game,
                gameMessage: {
                    ...payload,
                    type: type,
                },
            };
        case GAME.MESSAGE.WINNING:
        case GAME.MESSAGE.INSUFFICIENT_BALANCE:
        case GAME.MESSAGE.ACCOUNT_LOCKED:
        case GAME.ERROR_REDIRECT.SERVER_MAINTENANCE_REMINDER:
        case GAME.MESSAGE.BUY_ACTION_ERROR:
            return {
                ...game,
                message: {
                    ...payload,
                    type: type,
                },
            };
        case GAME.MESSAGE_CLEAR:
            return {
                ...game,
                message: null
            };
        case GAME.LOAD_IMAGE:
            return {
                ...game,
                loadImage: ("loadImage" in game)? game.loadImage + payload : payload
            };
        case GAME.ASSETS_LOADED:
            return {
                ...game,
                assetsLoaded: payload
            };
        case GAME.BET_DETAIL:
            return{
                ...game,
                betDetail: payload
            }
        case GAME.LAST_BET_DETAIL:
            return{
                ...game,
                lastBetDetails: payload
            }
        default:
            return game;
    }
}