import React from 'react'

import classNames from 'classnames'
import { Col, Row } from 'antd'

import ImgIcon from '../../../common/ImgIcon'
import { getTranslation } from '../../../../common/helpers/locale'
import Countdown from './Countdown'
import BetOptions from './BetOptions'
import Chips from './Chips'
import BetButtonWithTotal from '../BetButtonWithTotal'
import CardFlip from './CardFlip'

const CarBottomContainer = ({ 
        progress, 
        game, 
        handleRebetClick, 
        gameContainerThemes, 
        flipCardRef,
        cardAnimate,
        oldCardNumber,
        card,
        theme,
        themeColor,
        getRank,
        cardNumber,
        oldCard,
        disabled,
        chips,
        handleBetOptionClick,
        getChipValueByColor,
        selectOption,
        colorOdds,
        suitOdds,
        handleChipClick,
        selectChip,
        minBuy,
        maxBuy,
        themes,
        _newPlaceBet,
        _cancelBet,
        betInvalid,
        currencyDesc,
        clearBets,
        suitDisable,
        colorDisable
 }) => {
    return (
        <div className='controlsTop__container'>
            <div className='aboveTop__container'>
                {
                    progress < 100 ? (
                    <div className={classNames("bet-setting-btn", game.storage.lang, {
                        cancel: game.lastBetDetails?.length === undefined || game.lastBetDetails?.length === 0
                    })} onClick={handleRebetClick} >    
                        <ImgIcon
                            icon={game.lastBetDetails?.length === undefined || game.lastBetDetails?.length === 0 ? gameContainerThemes.REBET_CANCEL_ICON : gameContainerThemes.REBET_ICON}
                            height={35}    
                            width={35}
                        />
                        {getTranslation("REBET").toLocaleUpperCase()}
                    </div>
                    ) :
                (
                    <CardFlip 
                        flipCardRef={flipCardRef}
                        cardAnimate={cardAnimate}
                        gameContainerThemes={gameContainerThemes}
                        oldCardNumber={oldCardNumber}
                        card={card}
                        theme={theme}
                        themeColor={themeColor}
                        cardNumber={cardNumber}
                        getRank={getRank}
                        oldCard={oldCard}
                    />
                )}
                <Countdown progress={progress} game={game}/>
            </div>
            <div className={
                classNames(
                    'main',
                    disabled? 'disabled' : ''
                )
            }>

                <div className="bet-panel">
                    <BetOptions
                        gameContainerThemes={gameContainerThemes}
                        chips={chips}
                        handleBetOptionClick={handleBetOptionClick}
                        getChipValueByColor={getChipValueByColor}
                        selectOption={selectOption}
                        suitOdds={suitOdds}
                        colorOdds={colorOdds}
                        suitDisable={suitDisable}
                        colorDisable={colorDisable}
                    />
                    <div className={classNames("bet-chips__container")}>
                        <Row justify="space-between" align="middle">
                            <Col className="bet-chips__chip-container">
                                <Chips
                                    chips={gameContainerThemes.CHIPS} 
                                    getChipValueByColor={getChipValueByColor} 
                                    selectChip={selectChip}
                                    handleChipClick={handleChipClick}
                                />
                            </Col>
                            <Col className="bet-chips__clear-chip-container" onClick={disabled ? ()=>{} : clearBets}>
                                {getTranslation("CLEAR_BETS").toLocaleUpperCase()}
                            </Col>
                        </Row>
                    </div>
                    
                    <div style={{marginBottom: "10px"}}>
                        <BetButtonWithTotal
                            engine={game}
                            theme={theme}
                            min={minBuy}
                            max={maxBuy}
                            btnWidth={"80%"}
                            btnHeight={60}
                            btnBuy={themes.BUY_BUTTON}
                            btnBuying={themes.BUYING_BUTTON}
                            placeBet={_newPlaceBet}
                            cancelBet={_cancelBet}
                            buyAmount={game.buyAmount}
                            betInvalid={betInvalid}
                            cardInvalid={false}
                            betAmountInvalid={game.buyAmount < minBuy || game.buyAmount > maxBuy}
                        />
                    </div>

                    <span className="bet__currency-info">{currencyDesc}</span>
                </div>
            </div>
        </div>
    )
}

export default CarBottomContainer
