import Renderer from './loader/Renderer';
import Engine from '../engine/Engine';
import { GAME } from '../common/constants/game/engine';
import { getSuitFromString,getResultTotalSize, isDesktop } from '../common/helpers/common';
import { SETTINGS, THEME_CLASS } from '../common/constants/keys';

class Graphics {
    static instance = null;
    static getInstance() {
        if (!Graphics.instance) {
            Graphics.instance = new Graphics();
        }

        return Graphics.instance;
    }
    
    constructor() {
        this.rendering = false;
        this.animFrame = null;
        this.parentNode = null;
        this.fontFamily = "Gotham";
        this.onWindowResizeBinded = this.onWindowResize.bind(this);
        this.onChangeBinded = this.onChange.bind(this);
        this.restarted = false;
        this.background = isDesktop() ? "desktop-background" : "background";

        this.service = Renderer.getInstance();
        this.engine = Engine.getInstance();
    }

    restart = () => {
        this.data = {
            result: {},
            currentResult: {},
            oldResult: [],
            firstRoundOver: false,
            car: {
                maxSpeed:  isDesktop() ? 20 : 8,
                speed: 0,
                carSpeed: isDesktop() ? 7.5 : 3,
                topPadding: 160,
                bottomPadding: isDesktop() ? 300 : 100,
                arrow: {
                    interval: 0,
                    up: false
                }
            }
        };
        this.setup();
        this.service.restart();
    }

    init = (callback, theme, themeColor) => {
        this.changeTheme(theme, themeColor)
        this.service.init(callback, theme);
    }

    start = (canvas, parentNode) => {
        var self = this;
        self.rendering = true;
        self.parentNode = parentNode;
        if (!canvas.getContext) {
            return console.error('No canvas');
        }
        self.ctx = canvas.getContext('2d');
        self.ctx.imageSmoothingEnabled = false;   
        self.ctx.webkitImageSmoothingEnabled = false;
        self.ctx.mozImageSmoothingEnabled = false;
        var _parentNode = self.parentNode;
        self.canvasWidth = _parentNode.clientWidth;
        self.canvasHeight = _parentNode.clientHeight;
        self.canvas = canvas;

        self.service.start(self.ctx, self.canvasWidth, self.theme, self.themeColor);

        if (this.theme === THEME_CLASS.CAR || this.theme === THEME_CLASS.OKBET) {
            for (var x in this.engine.data.result) {
                self.service.createCar(self.service, self.ctx, getSuitFromString(x), {});
            }
        }

        this.restart();

        window.addEventListener('resize', self.onWindowResizeBinded);

        self.animFrame = window.requestAnimationFrame(self.run.bind(self));
    }

    ready = () => {
        if (!this.service.ready()) {
            return false;
        }
        return true;
    }

    run = () => {
        var self = this;
        if (!self.rendering) {
            return;
        }

        // style adjust to expected size
        var scale = Math.floor(window.devicePixelRatio);
        
        if(scale < 1){
            scale = 1;
        }

        if(this.engine.data.gameState === GAME.GAME_STATE.STARTING){
            self.canvas.style.width = self.ctx.canvas.parentNode.clientWidth + 'px';
        }
        else{
            self.canvas.style.width = self.canvasWidth + 'px';
        }
        
        // enlarge canvas size based on device pixel ratio
        // prevent text blur
        self.canvas.width = Math.floor(self.canvasWidth * scale);
        self.canvas.height = Math.floor(self.canvasHeight * scale);
        self.ctx.scale(scale, scale);

        this.clear();
        this.calcGameData();
        this.drawBackground();
        this.drawObjects();
        this.service.run(); // draw
        this.drawGameData();
        if (!this.data.firstRoundOver) this.data.firstRoundOver = true;

        this.animFrame = window.requestAnimationFrame(this.run.bind(this));
    }

    stop = () => {
        var self = this;
        self.rendering = false;
        this.service.stop();
    }

    clear = () => {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    calcGameData = () => {
        if (this.engine.data.gameState === GAME.GAME_STATE.STARTING && !this.restarted) {
            this.restarted = true;
            this.restart();
        }
        if (this.engine.data.gameState === GAME.GAME_STATE.ENDED) {
            this.restarted = false;
        }

        this.data.result = this.engine.data.result;
        this.data.currentResult = this.engine.data.currentResult;
        this.data.oldResult = this.engine.data.oldResult;
    }

    drawGameData = () => {
        var self = this;
        if (self.theme === THEME_CLASS.CAR || self.theme === THEME_CLASS.OKBET) {
            // game result
            var bg = self.service.getSpr("ind-bg-"+self.themeColor);
            var flag = self.service.getSpr("ind-flag");
            var padding = 10;
            var historyBgPadding = self.canvasWidth * 0.01
            var posX = bg.getDimension().width/2 + padding;
            var posY = bg.getDimension().height/2;
            var backgroundPadding = 80;
            var backgroundPosXAdjust = 12;
            var backgroundScale = this.canvasWidth * 0.00032;
            var flagScale = this.canvasWidth * 0.00035;
            var suitScale = this.canvasWidth * 0.00023;
            var historySuitSize = isDesktop() ? this.canvasWidth * (this.canvasWidth < 1500 ? 0.07 : 0.045) : 40;
            var flagPosY = bg.pos.y - bg.getDimension().height/2 + flag.getDimension().height + padding;
            var linePadding = flag.getDimension().height/2 + padding;

            // For zoom use.
            var posAdjust =  isDesktop() ? 
                                self.canvasHeight * 0.31 :
                            ((self.canvasHeight > self.canvasWidth && self.canvasWidth >= 600) || self.canvasHeight > 700) ? 
                                self.canvasHeight * 0.41 : 
                            self.canvasHeight < 365 ? 
                                self.canvasHeight * 0.52 :
                                self.canvasHeight * 0.47;

            var widthAdjust = (bg.getDimension().width / bg.size) * 0.4;
            var posY2 = posY + posAdjust + padding;

            if(isDesktop()){
                posX += backgroundPosXAdjust;
                posY += backgroundPadding;
                bg.scale(backgroundScale);
                flag.scale(flagScale);
            }

            if(isDesktop() && self.engine.data.gameState === GAME.GAME_STATE.STARTING) { 
                widthAdjust -= 5;
                var historyBgHeightAdjust = 100;
                var historyPosX = posX + self.canvasWidth * 0.165;
                bg.translate(historyPosX, posY2);
                bg.animate(0, -widthAdjust, -historyBgHeightAdjust);
                flagPosY = bg.pos.y - bg.getDimension().height/2 + flag.getDimension().height;
                flag.scale(flagScale * 0.65);
                flag.translate(historyPosX - flag.getDimension().width / 2, flagPosY);
                flag.animate();

                var historyLinePosY = flagPosY + linePadding;
                var historyLinePosYEnd = historyLinePosY + bg.getDimension().height - (historyBgHeightAdjust * backgroundScale) - flag.getDimension().height - padding - linePadding * 2 - 10;
                var lineXAdjust = (flag.getDimension().width / 2) + 1;
                self.ctx.save();
                self.ctx.beginPath();
                self.ctx.moveTo(historyPosX - lineXAdjust, historyLinePosY);
                self.ctx.lineTo(historyPosX - lineXAdjust, historyLinePosYEnd);
                self.ctx.strokeStyle = '#fff';
                self.ctx.stroke();
                self.ctx.restore();

                for (var z in self.data.result) {
                    var historySuit = this.service.getSpr('suit-'+getSuitFromString(z).toLowerCase());
                    self.ctx.save();
                    self.ctx.shadowColor='#fff';
                    self.ctx.shadowBlur=5;

                    historySuit.scale(this.canvasWidth * 0.00015);
                    historySuit.translate(historyPosX - lineXAdjust, historyLinePosYEnd);
                    historySuit.animate();
                    historySuit.ctx.restore();
                }
            }
            else{
                bg.translate(posX, posY - historyBgPadding + 1.5);
                bg.animate();
                flag.translate(posX + 1.5, flagPosY);
                flag.animate();
                var linePosY = flagPosY + linePadding;
                var linePosYEnd = linePosY + bg.getDimension().height - flag.getDimension().height - padding - linePadding*2;
                self.ctx.save();
                self.ctx.beginPath();
                self.ctx.moveTo(posX, linePosY);
                self.ctx.lineTo(posX, linePosYEnd);
                self.ctx.strokeStyle = '#fff';
                self.ctx.stroke();
                self.ctx.restore();
                var lineHeight = linePosYEnd - linePosY;
                var lineSubHeight = lineHeight / (SETTINGS.GAME.TOTAL_CARD_TO_WIN-1);
                for (var x in self.data.result) {
                    var car = this.service.getObj(getSuitFromString(x));
                    var suit = this.service.getSpr('suit-'+getSuitFromString(x).toLowerCase());
                    var sPosY = linePosYEnd - ((this.data.car.bottomY - car.getTranslation().y) / this.data.car.roadingHeight * lineHeight);
                    self.ctx.save();
                    self.ctx.shadowColor='#fff';
                    self.ctx.shadowBlur=5;

                    if(isDesktop()){
                        suit.scale(suitScale);
                    }
                    suit.translate(posX, sPosY);
                    suit.animate();
                    self.ctx.restore();
                }
            }
    
            // history
            bg = self.service.getSpr("history-bg-"+self.themeColor);
            var cover = self.service.getSpr("history-cover");
            var cardPadding, cardTop, cardWidth, cardHeight, bgHeightAdjust;
            
            if(self.engine.data.gameState === GAME.GAME_STATE.STARTING) {
                var posX2 = self.canvasWidth - bg.getDimension().width/2 - padding - (self.canvasWidth * 0.13);
                var zoomHistoryScale = 60;
                var MAX_GAMES_SHOWED = 8;

                cardPadding = 0.08*bg.getDimension().width;
                cardTop = 0.55*bg.getDimension().width;
                cardWidth = bg.getDimension().width*0.263;
                cardHeight = 1.36 * cardWidth;
                lineSubHeight = cardHeight + cardPadding;
                bgHeightAdjust = (((cardHeight) * (MAX_GAMES_SHOWED + 2.5)) - bg.getDimension().height) / bg.size;

                if(isDesktop()){
                    MAX_GAMES_SHOWED = 13;
                    posX2 -= this.canvasWidth * 0.025;
                    bg.scale(backgroundScale);
                    cover.scale(backgroundScale);
                    zoomHistoryScale = historySuitSize + (this.canvasWidth * 0.03);
                    cardWidth *= 1.1;
                    bgHeightAdjust = (((cardPadding + cardHeight) * (MAX_GAMES_SHOWED + 0.8) + cardPadding) - bg.getDimension().height) / bg.size;
                }

                bg.translate(posX2, posY2);
                bg.animate(-1, -widthAdjust, bgHeightAdjust);

                if (self.engine.data.gameHistory) {
                    self.engine.data.gameHistory.slice(0, MAX_GAMES_SHOWED).map((game, i) => {
                        var cardPosY = (lineSubHeight * i + cardTop) + posAdjust * (this.canvasWidth < 1500 ? 0.99 : 0.97);
                        var cardPosX = posX2;
                        if(isDesktop()){
                            cardPosX += 1.5;
                        }
                        return self.drawCard(getSuitFromString(game.result.suit), i+"zoom", cardPosX - (cardWidth * 1.25), cardPosY, cardWidth, cardHeight, zoomHistoryScale);
                    });
                }
                cover.translate(posX2, posY2);
                cover.animate(-1, -widthAdjust, bgHeightAdjust);
            }
            else{
                posX = self.canvasWidth - bg.getDimension().width/2 - padding;
                if(isDesktop()){
                    posX -= backgroundPosXAdjust;
                    bg.scale(backgroundScale);
                    cover.scale(backgroundScale);
                }
                bg.translate(posX, posY - historyBgPadding);
                cardPadding = 0.08*bg.getDimension().width;
                cardTop = 0.6*bg.getDimension().width;
                cardWidth = bg.getDimension().width*0.375;
                cardHeight = 1.30 * cardWidth;
                posX = posX - cardWidth/2;
                const MAX_GAMES_SHOWED = 15;
                lineSubHeight = cardHeight + cardPadding;
    
                bgHeightAdjust = (((cardPadding + cardHeight) * (MAX_GAMES_SHOWED + 1) + cardPadding) - bg.getDimension().height) / bg.size;
                bg.animate(-1, 0, bgHeightAdjust);
    
                if (self.engine.data.gameHistory) {
                    self.engine.data.gameHistory.slice(0, MAX_GAMES_SHOWED).map((game, i) => {
                        var suit = getSuitFromString(game.result.suit);
                        var cardPosY = (lineSubHeight * i + cardTop) - historyBgPadding - padding;
                        var cardPosX = posX;
                        if(isDesktop()){
                            cardPosY = cardPosY + backgroundPadding - (self.canvasWidth > 1000 ? 1.5 : 0);
                            cardPosX -= 1;
                        }
                        self.service.clearSpr(suit+'-'+i+"zoom");
                        return self.drawCard(suit, i, cardPosX, cardPosY, isDesktop() ? cardWidth + 2 : cardWidth, cardHeight, historySuitSize);
                    });
                }
                cover.translate(posX+cardPadding*2, posY - historyBgPadding);
                cover.animate(-1, 0, bgHeightAdjust);
            }
        }
    }

    drawCard = (suit, i, x, y, w, h, scale = 40 ) => {
        var self = this;
        self.roundRect(self.ctx, x, y, w, h, 2, '#F7F7F7', false);
        var name = suit+'-'+i;
        var sprite = self.service.createSpr(self.canvasWidth, name, self.service.getTexture(suit.toLowerCase()), { zIndex:2, scaleDivideBy: scale });
        sprite.translate(x+(w/2), y+(h/2));
        sprite.animate();
    }
    
    roundRect = (ctx, x, y, width, height, radius, fill, stroke) => {
        if (typeof stroke === 'undefined') {
          stroke = true;
        }
        if (typeof radius === 'undefined') {
          radius = 5;
        }
        if (typeof radius === 'number') {
          radius = {tl: radius, tr: radius, br: radius, bl: radius};
        } else {
          var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
          for (var side in defaultRadius) {
            radius[side] = radius[side] || defaultRadius[side];
          }
        }
        ctx.beginPath();
        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + width - radius.tr, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
        ctx.lineTo(x + width, y + height - radius.br);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
        ctx.lineTo(x + radius.bl, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);
        ctx.closePath();
        if (fill) {
            ctx.fillStyle = fill;
            ctx.fill();
        }
        if (stroke) {
            ctx.strokeStyle = stroke;
            ctx.stroke();
        }
    }

    drawRoad = (posX, posY) => {
        var half = this.data.car.totalRoad/3;
        var rHeight = this.service.getSpr(this.background+"0").getDimension().height;
        var ePosY = posY%(half*rHeight);
        for (var i=0; i<this.data.car.totalRoad; i++) {
            var y = ePosY - (((i+1)-2*half)*rHeight);
            this.service.getSpr(this.background+i).translate(posX, y);
        }
    }

    drawBackground = () => {
        var road = this.service.getSpr(this.background+"0");
        var line = this.service.getSpr(isDesktop() ? "desktop-start-line": "start-line");
        
        if(isDesktop()){ 
            this.service.clearSpr("start-line");
            this.service.clearSpr("background0");
        }
        else {
            this.service.clearSpr("desktop-start-line");
            this.service.clearSpr("desktop-background0");
        }

        var club = this.service.getObj("CLUB");
        var posX = this.canvasWidth/2;
        var lineTop = club.getDimension().height + 40;
        var totalresults = getResultTotalSize(this.data.result);
        var totalPerCard = SETTINGS.GAME.CARD_TOTAL / SETTINGS.GAME.SUIT_TOTAL;
        var firstStartLine = this.canvasHeight - (line.getDimension().height / 2) - this.data.car.bottomPadding - lineTop;
        var roadRatio = (totalresults === SETTINGS.GAME.SUIT_TOTAL)? 0 : (totalresults - SETTINGS.GAME.SUIT_TOTAL) / totalPerCard;
        var roadSpeed = roadRatio * this.data.car.maxSpeed * this.data.car.carSpeed;
        this.data.car.speed = (roadSpeed >= this.data.car.maxSpeed)? this.data.car.maxSpeed : roadSpeed;
        var posY;

        if (totalresults === SETTINGS.GAME.SUIT_TOTAL) {
            posY = this.canvasHeight - road.getDimension().height/2;
            this.drawRoad(posX, posY);
            posY = firstStartLine;
            line.translate(posX, posY);
        }else{
            posY = (this.engine.data.tick.lag)? road.pos.y : road.pos.y + this.data.car.speed;
            this.drawRoad(posX, posY);
            if (this.engine.data.gameState === GAME.GAME_STATE.ENDED) {
                var elapsed = Date.now() - this.engine.data.tick.lastTick;
                var ratio = (elapsed === 0) ? 0 : elapsed / SETTINGS.GAME.TICK_RATE;
                var sDist = this.data.car.topPadding-(this.data.car.maxSpeed*60)*(SETTINGS.GAME.TICK_RATE/1000);
                posY = sDist + ratio * (this.data.car.topPadding+Math.abs(sDist)-line.getDimension().height/2);
            } else {
                line.pos.y = (line.pos.y === 0)? this.canvasHeight - (((3-(totalresults-4))/2) * (this.canvasHeight-firstStartLine)) : line.pos.y;
                posY = (this.engine.data.tick.lag)? line.pos.y : line.pos.y + this.data.car.speed;
            }
            line.translate(posX, posY);
        }
    }

    drawObjects = () => {
        var road = this.service.getSpr(this.background+"0");
        // var up = this.service.getSpr("arrow-up");
        // var down = this.service.getSpr("arrow-down");
        var posY = this.data.car.bottomY;
        var laneWidth, posX;
        if(isDesktop()){
            laneWidth = road.getDimension().width * 0.07;
            posX = 0.3723333333333333 * this.canvasWidth;
        }
        else{
            laneWidth = road.getDimension().width * 0.1293333333333333;
            posX = 0.2413333333333333 * this.canvasWidth;
        }
        var i = 0;
        var suits = ['s', 'h', 'c', 'd'];
        var count, suit, car;
        for (var x in suits) {
            suit = suits[x];
            count = this.data.result[suit].count - 1;
            car = this.service.getObj(getSuitFromString(suit));
            var carPos = { x: i * laneWidth + posX + laneWidth / 2, y: posY - (count * this.data.car.roadingSubHeight)};
            if ((this.engine.data.gameState === GAME.GAME_STATE.STARTED || this.engine.data.gameState === GAME.GAME_STATE.ENDED) && this.data.currentResult && this.data.currentResult.suit === suit) {
                var elapsed = Date.now() - this.engine.data.tick.lastTick;
                var ratio = (elapsed === 0) ? 0 : elapsed / SETTINGS.GAME.TICK_RATE;
                if (ratio > 1) ratio = 1;
                carPos.y = carPos.y + (1 - ratio) * this.data.car.roadingSubHeight;
            }

            car.translate(carPos.x, carPos.y);
            // if (this.engine.data.gameState === GAME.GAME_STATE.STARTING && getSuitLocalStorage() && getSuitLocalStorage() === getSuitFromString(suit)) {
            //     var padding = (this.data.car.arrow.up)? 10 : 8;
            //     down.active().translate(car.getTranslation().x, car.getTranslation().y + car.getDimension().height/2 + padding - 5);
            //     up.active().translate(car.getTranslation().x, car.getTranslation().y - car.getDimension().height/2 - padding);
            //     if (this.data.car.arrow.interval > 20) {
            //         this.data.car.arrow.up = !this.data.car.arrow.up;
            //         this.data.car.arrow.interval = 0;
            //     }
            //     this.data.car.arrow.interval++;
            // }
            i++;
        }
        // if (this.engine.data.gameState === GAME.GAME_STATE.STARTED) {
        //     up.active(false);
        //     down.active(false);
        // }
        if ((this.engine.data.gameState === GAME.GAME_STATE.STARTED || this.engine.data.gameState === GAME.GAME_STATE.ENDED) && this.data.currentResult) {
            suit = this.data.currentResult.suit;
            car = this.service.getObj(getSuitFromString(suit));
            count = this.data.result[suit].count - 1;
            var carPosY = posY - (count * this.data.car.roadingSubHeight);
            if (this.data.oldResult.length >= 8 && this.data.oldResult[1].suit === suit && this.data.oldResult[2].suit === suit && this.data.oldResult[3].suit === suit && this.data.oldResult[4].suit === suit && this.data.oldResult[5].suit === suit && this.data.oldResult[6].suit === suit && this.data.oldResult[7].suit === suit) {
                // boost 3x
                car.boost3x(carPosY, 8);
            } else if (this.data.oldResult.length >= 7 && this.data.oldResult[1].suit === suit && this.data.oldResult[2].suit === suit && this.data.oldResult[3].suit === suit && this.data.oldResult[4].suit === suit && this.data.oldResult[5].suit === suit && this.data.oldResult[6].suit === suit) {
                // boost 3x
                car.boost3x(carPosY, 7);
            } else if (this.data.oldResult.length >= 6 && this.data.oldResult[1].suit === suit && this.data.oldResult[2].suit === suit && this.data.oldResult[3].suit === suit && this.data.oldResult[4].suit === suit && this.data.oldResult[5].suit === suit) {
                // boost 3x
                car.boost3x(carPosY, 6);
            } else if (this.data.oldResult.length >= 5 && this.data.oldResult[1].suit === suit && this.data.oldResult[2].suit === suit && this.data.oldResult[3].suit === suit && this.data.oldResult[4].suit === suit) {
                // boost 3x
                car.boost3x(carPosY, 5);
            } else if (this.data.oldResult.length >= 4 && this.data.oldResult[1].suit === suit && this.data.oldResult[2].suit === suit && this.data.oldResult[3].suit === suit) {
                // boost 3x
                car.boost3x(carPosY, 4);
            } else if (this.data.oldResult.length >= 3 && this.data.oldResult[1].suit === suit && this.data.oldResult[2].suit === suit) {
                // boost 3x
                car.boost3x(carPosY);
            } else if (this.data.oldResult.length >= 2 && this.data.oldResult[1].suit === suit) {
                // boost 2x
                car.boost2x(carPosY);
            }else{
                car.boost(carPosY);
            }
        }
    }

    onWindowResize = () => {
        var _parentNode = this.parentNode;
        this.canvasWidth = _parentNode.clientWidth;
        this.canvasHeight = _parentNode.clientHeight;
        this.setup();
    }

    onChange = () => {
        this.setup();
    }

    setup = () => {
        this.canvas.width = this.canvasWidth;
        this.canvas.height = this.canvasHeight;
        this.plotWidth = this.canvasWidth;
        this.plotHeight = this.canvasHeight;
        this.xStart = this.canvasWidth - this.plotWidth;
        this.yStart = this.canvasHeight - this.plotHeight;
        
        if (this.theme === THEME_CLASS.CAR || this.theme === THEME_CLASS.OKBET) {
            this.engine.data.gameHistory.slice(0, 15).map((game, i) => {
                var suit = getSuitFromString(game.result.suit);
                this.service.clearSpr(suit+'-'+i+"zoom");
                this.service.clearSpr(suit+'-'+i);
                return true; 
            });

            var club = this.service.getObj("CLUB");
            this.data.car.bottomY = this.canvasHeight - this.data.car.bottomPadding - (club.getDimension().height / 2);
            this.data.car.roadingHeight = this.data.car.bottomY - this.data.car.topPadding;
            this.data.car.roadingSubHeight = this.data.car.roadingHeight / (SETTINGS.GAME.TOTAL_CARD_TO_WIN-1);
            this.data.car.totalRoad = Math.ceil(this.canvas.height / this.service.getSpr(this.background+"0").getDimension().height) * 3;
            for (var i=1; i<this.data.car.totalRoad; i++) {
                this.service.createSpr(this.canvasWidth, this.background+i, this.service.getTexture(isDesktop()?"desktop-bg" : "bg"), { zIndex:0 });
            }
        }

        this.service.setup(this.canvasWidth);
    }

    changeTheme = (theme, themeColor) => {
        this.theme = theme;
        this.themeColor = themeColor;
    }
}

export default Graphics;