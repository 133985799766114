import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { getTranslation } from '../../../common/helpers/locale';
import { ICONS } from '../../../common/constants/iconWrapper';
import ImgIcon from '../../common/ImgIcon';
import { useStore } from '../../../store/StateProvider';
import { isDesktop } from '../../../common/helpers/common';
import { enableSetting, openHelpModal, openHistoryModal, openRecentModal } from '../../../common/actions/settingsActions';
import { THEME_CLASS } from '../../../common/constants/keys';

const Header = ({ title, history, showBackIcon = true }) => {
    const [store, dispatch] = useStore();
    const [backUrl, setBackUrl] = useState('');
    const { game } = store;

    // ============================================
    // METHODS
    // ============================================
    const toggleSetting = () => {
        enableSetting(dispatch);
    }

    const toggleHistory = () => {
        openHistoryModal(dispatch);
    }

    const toggleHelp = () => {
        openHelpModal(dispatch);
    }

    const toggleRecent = () => {
        openRecentModal(dispatch);
    }
    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (store.game.assetsLoaded >= 100) {
            setBackUrl(store.game.storage.backUrl);
        }
    }, [store.game.assetsLoaded]);

    // ============================================
    // RENDER
    // ============================================
    const backIcon = (rest) => {
        return (
            <ImgIcon 
                icon={ICONS.HEADER.LEFT_ARROW}
                containerClassName="navbar__icon"
                width={20}
                height={15}
                {...rest}
            />
        );
    };

    const navMenu = (icon, onClick=()=>{}) => {
        return (
                <ImgIcon 
                    icon={icon}
                    containerClassName="navbar__menu"
                    width={30}
                    height={30}
                    onClick={onClick}
                />
            )
    }


    return (
        <div className="common-back-header">
            {showBackIcon ? (
                backUrl &&
                    <a href={backUrl}>
                        {backIcon()}
                    </a>
            ) : (
                <ImgIcon icon="" />
            )}
            
            {
                isDesktop() ? (
                    <>
                        <div className="title-container">
                            {
                                game.storage.theme ? (<>
                                    <ImgIcon icon={game.storage.theme === THEME_CLASS.OKBET ? ICONS.HEADER.OKBET_LOGO : ICONS.HEADER.ADVANTPLAY_LOGO} containerClassName="header-logo" />
                                    <h1><span style={{textTransform: 'lowercase'}}>x</span> {getTranslation(title)}</h1>
                                </>) : (<h1>{getTranslation(title)}</h1>)
                            }
                        </div>
                        {
                            store.game.isEnter && (
                                <div className="header-menu">
                                    { navMenu(ICONS.FOOTER.RESULTS, toggleRecent) }
                                    { navMenu(ICONS.FOOTER.TRANSACTIONS, toggleHistory) }
                                    { navMenu(ICONS.FOOTER.HELP, toggleHelp) }
                                    { navMenu(ICONS.HEADER.SETTING, toggleSetting) }
                                </div>
                            )
                        }
                    </>
                ) :  (
                    <h1>{getTranslation(title)}</h1>
                )
            }
            
        </div>
    )
};

export default withRouter(Header);