import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImgIcon from '../../common/ImgIcon';
import { ICONS } from '../../../common/constants/iconWrapper';
import { getTranslation } from '../../../common/helpers/locale';
import './Winning.less';
import { GAME_RESULT, THEME_CLASS } from '../../../common/constants/keys';
import classNames from 'classnames';
import { POPUP_WIDTH } from '../../../common/constants/misc';
import { isDesktop } from '../../../common/helpers/common';

const Winning = ({
    theme,
    profit,
    type,
    status
}) => {
    const [card, setCard] = useState(type);
    const [wonMessage, setWonMessage] = useState('');
    const DEVICE = isDesktop() ? 'desktop' : 'mobile';

    useEffect(() => {
        if (!profit || !type) return;
        setCard(type);
        setWonMessage(`${getTranslation("YOU_WON")} +${profit}`);
    }, [profit, type]);
    
    return (
        <>
        { theme === THEME_CLASS.CAR || theme === THEME_CLASS.OKBET ? (
            <div className='main'>
                <ImgIcon
                    icon={ICONS.THEME.CAR.GAME.MESSAGE.WINNING.RIBBON}
                    alt="ribbon"
                    width={POPUP_WIDTH.WINNING[DEVICE]}
                    containerClassName={classNames("ribbon falling", DEVICE)}
                    />
                <ImgIcon
                    icon={ICONS.THEME.CAR.GAME.MESSAGE.WINNING.RIBBON2}
                    alt="ribbon"
                    width={POPUP_WIDTH.WINNING[DEVICE]}
                    containerClassName={classNames("ribbon falling2 x2", DEVICE)}
                    />
                <div className='top'>
                    <ImgIcon
                        icon={ICONS.THEME.CAR.GAME.MESSAGE.WINNING.ARROW_LEFT}
                        alt="left"
                        size={60}
                        />
                    <div className='status'>
                        { (status === GAME_RESULT.WIN || status === GAME_RESULT.DEFAULT)? getTranslation("VICTORY") : getTranslation("LOSE") }
                    </div>
                    <ImgIcon
                        icon={ICONS.THEME.CAR.GAME.MESSAGE.WINNING.ARROW_RIGHT}
                        alt="right"
                        size={60}
                        />
                </div>
                <div className='card'>
                    <ImgIcon
                        icon={ICONS.THEME.CAR.GAME.MESSAGE.WINNING.HIGHLIGHT}
                        alt="highlight"
                        size={460}
                        containerClassName="rotating"
                        />
                    <ImgIcon
                        icon={ICONS.THEME.CAR.GAME.MESSAGE.WINNING.STAR}
                        alt="star"
                        width={220}
                        containerClassName="flickering star"
                        />
                    <ImgIcon
                        containerClassName="car"
                        icon={ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR[type]}
                        alt="car"
                        width={105}
                        />
                    <div className='text'>
                        {getTranslation(type)}
                    </div>
                </div>
                <div className={
                    classNames(
                        'bottom',
                        status === GAME_RESULT.WIN? 'active' : ''
                    )
                }>
                    <div>
                        {getTranslation("THE_MONEY_YOU_WON")}
                    </div>
                    <div className='profit'>
                        + {profit}
                    </div>
                </div>
            </div>
        ) : (
            <>
            <div className='winning'>
                <ImgIcon
                    icon={ICONS.CARDS[card]}
                    alt={card}
                    />
            </div>
            <div>
                {wonMessage}
            </div>
            </>
        )}
        </>
    )
};

Winning.prototype = {
    theme: PropTypes.string.isRequired,
    profit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    card: PropTypes.string.isRequired,
    status: PropTypes.string
}

export default Winning;