export const CDN_SOUNDS = {
    THEME: {
        CAR: {
            COUNTDOWN: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/3s.mp3'},
            BACKGROUND: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/Card_Racing_BGM_V2.mp3'},
            BACKGROUND_ALT: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/CR_BGM_V2.mp3'},
            WELCOME: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/Welcome.mp3'},
            TURBO: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/Turbo.mp3'},
            TURBO_CAR: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/Turbo_car.mp3'},
            CHOOSE_SUITS: { 
                EN: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/Choose_Suits.mp3'},
                CN: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/chinese/Choose_Suits.mp3'}
            },
            PLACE_BETS: {
                EN: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/place_bets.wav'},
                CN: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/chinese/place_bets.wav'},
                TH: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/thai/place_bets.wav'},
                ID: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/indo/place_bets.wav'},
                VN: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/vietnamese/place_bets.wav'}
            },
            CHOOSING: {
                EN:{
                    CLUB: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/Club_choose.mp3'},
                    HEART: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/Heart_choose.mp3'},
                    SPADE: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/Spade_choose.mp3'},
                    DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/Diamond_choose.mp3'},
                },
                CN:{
                    CLUB: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/chinese/Club_choose.mp3'},
                    HEART: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/chinese/Heart_choose.mp3'},
                    SPADE: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/chinese/Spade_choose.mp3'},
                    DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/chinese/Diamond_choose.mp3'},
                }
            },
            SO_CLOSE: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/so_close.mp3'},
            BETTER_LUCK: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/Better_luck.mp3'},
            CONGRATULATIONS: {
                EN: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/congratulations.wav'},
                CN: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/chinese/congratulations.wav'},
                TH: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/thai/congratulations.wav'},
                ID: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/indo/congratulations.wav'},
                VN: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/vietnamese/congratulations.wav'}
            },
            WINNING: {
                EN:{
                    CLUB: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/wins_club.wav'},
                    HEART: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/wins_heart.wav'},
                    SPADE: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/wins_spade.wav'},
                    DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/wins_diamond.wav'},
                },
                CN:{
                    CLUB: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/chinese/wins_club.wav'},
                    HEART: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/chinese/wins_heart.wav'},
                    SPADE: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/chinese/wins_spade.wav'},
                    DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/chinese/wins_diamond.wav'},
                },
                TH:{
                    CLUB: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/thai/wins_club.wav'},
                    HEART: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/thai/wins_heart.wav'},
                    SPADE: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/thai/wins_spade.wav'},
                    DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/thai/wins_diamond.wav'},
                },
                ID:{
                    CLUB: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/indo/wins_club.wav'},
                    HEART: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/indo/wins_heart.wav'},
                    SPADE: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/indo/wins_spade.wav'},
                    DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/indo/wins_diamond.wav'},
                },
                VN:{
                    CLUB: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/vietnamese/wins_club.wav'},
                    HEART: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/vietnamese/wins_heart.wav'},
                    SPADE: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/vietnamese/wins_spade.wav'},
                    DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/vietnamese/wins_diamond.wav'},
                }
            },
            CARD_FLIP: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/card_flip.mp3'},
            CARD_FLIP_SUIT: {
                EN:{
                    CLUB: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/fast_club.wav'},
                    HEART: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/fast_heart.wav'},
                    SPADE: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/fast_spade.wav'},
                    DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/fast_diamond.wav'},
                },
                CN:{
                    CLUB: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/chinese/fast_club.wav'},
                    HEART: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/chinese/fast_heart.wav'},
                    SPADE: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/chinese/fast_spade.wav'},
                    DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/chinese/fast_diamond.wav'},
                },
                TH:{
                    CLUB: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/thai/fast_club.wav'},
                    HEART: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/thai/fast_heart.wav'},
                    SPADE: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/thai/fast_spade.wav'},
                    DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/thai/fast_diamond.wav'},
                },
                ID:{
                    CLUB: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/indo/fast_club.wav'},
                    HEART: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/indo/fast_heart.wav'},
                    SPADE: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/indo/fast_spade.wav'},
                    DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/indo/fast_diamond.wav'},
                },
                VN:{
                    CLUB: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/vietnamese/fast_club.wav'},
                    HEART: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/vietnamese/fast_heart.wav'},
                    SPADE: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/vietnamese/fast_spade.wav'},
                    DIAMOND: { default: 'https://static-cardracing.pypc.net/assets/sounds/themes/car/vietnamese/fast_diamond.wav'},
                }
            }
        },
        DEFAULT: {

        }
    }
}