import React from 'react';

import { ICONS } from '../../../common/constants/iconWrapper';
import { isUserLoggedIn } from '../../../common/helpers/localStorage';
import ImgIcon from '../../common/ImgIcon';
import classNames from 'classnames';
import { FOOTER_ITEM, THEME_CLASS } from '../../../common/constants/keys';
import { setPageUrl } from '../../../common/actions/userActions';
import { useStore } from '../../../store/StateProvider';

export const Footer = () => {
    const [store, dispatch] = useStore();
    const { user, game } = store;
    const imgWidth = 47; const imgHeight = 30;

    const navigateToPage = (route, isLoginRequired = false) => {
        if (isLoginRequired) {
            if (!isUserLoggedIn()) {
                return;
            }
        }
        setPageUrl(dispatch, FOOTER_ITEM[route]);
    };

    const footerItem = (route) => {
        return (
            <div className="footer__item">
                <ImgIcon
                    icon={ICONS.FOOTER[route]}
                    onClick={() => navigateToPage(route) }
                    width={imgWidth}
                    height={imgHeight}
                    containerClassName="footer__icon-container"
                    className={classNames(
                        'footer__icon',
                        user.pageUrl === FOOTER_ITEM[route] ? 'selected' : ''
                    )}
                />
            </div>
        );
    }

    return game.isEnter ?  (
        <div className="footer__container">
            { footerItem(FOOTER_ITEM.HOME) }
            { footerItem(FOOTER_ITEM.RESULTS) }
            { footerItem(FOOTER_ITEM.TRANSACTIONS) }
            { footerItem(FOOTER_ITEM.HELP) }
        </div>
    ) : (
        <div className="footer__container">
            {
                game.storage.theme ? (<ImgIcon icon={game.storage.theme === THEME_CLASS.OKBET ? ICONS.HEADER.OKBET_LOGO : ICONS.HEADER.ADVANTPLAY_LOGO} containerClassName="footer-logo" />)
                : (<></>)
            }
        </div>
    )
};

export default Footer;