import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/Button';
import classNames from 'classnames';
import './BuyAmountCoin.less';

const BuyAmountCoin = ({ 
    icon, 
    onClick, 
    containerClassName,
    children,
    ...rest
}) => {
    return (
        <Button
            icon={icon}
            onClick={onClick}
            containerClassName={
                classNames(
                    "buyAmountCoin",
                    containerClassName
                )}
            {...rest}
            >
                {children}
            </Button>
    )
};

BuyAmountCoin.prototype = {
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    containerClassName: PropTypes.string
};

export default BuyAmountCoin;