import React, { useEffect, useState } from 'react';
import { getTranslation } from '../../common/helpers/locale';
import { getSuitFromString } from '../../common/helpers/common';
import { useStore } from '../../store/StateProvider';
import { SETTINGS, THEME_ENUM } from '../../common/constants/keys';
import moment from 'moment';
import './Results.less';
import { GAME } from '../../common/constants/game/engine';
import Button from '../common/Button';
import { getThemeCommon, getThemeGameContainer } from '../../common/helpers/theme';
import ImgIcon from '../common/ImgIcon';
import classNames from 'classnames';
import GameHash from '../common/GameHash';
import { DropdownTriggerIcon } from '../common/DropdownTriggerIcon';
import BackToTop from '../common/BackToTop';

const Results = ({
    theme,
    themeColor,
    engine,
    isModal = false,
    trigger = null
}) => {
    const themes = getThemeCommon(theme, themeColor);
    const [ store ] = useStore();
    const { game } = store;
    const [info, setInfo] = useState([]);
    const [showHash, setShowHash] = useState(false);
    const [onTop, setOnTop] = useState(true);

    const gameContainerThemes = getThemeGameContainer(theme, THEME_ENUM.DEFAULT);

    useEffect(() => {
        refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger, showHash]);

    useEffect(()=>{
        if(game.gameState === GAME.GAME_STATE.ENDED){
            refresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[game.gameState])

    const onShowClick = (key) => {
        setShowHash({...showHash, [key]: !showHash[key]})
    }

    const backToTop = () => {
        let element = document.getElementById("recent-container");
        element.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const onScroll = (e) => {
        if (e.target.scrollTop > 0 && !onTop) {
            return;
        }

        if (e.target.scrollTop > 0) setOnTop(false);
        else setOnTop(true);
    }

    const refresh = () => {
        if (!("gameHistory" in game) || !game.gameHistory) return;
        
        var rows = game.gameHistory.slice(0, SETTINGS.GAME.GAME_HISTORY_RESULT_LIMIT).map((game, i) => {
            var result = "VOID";
            if (game.status === GAME.GAME_STATUS.COMPLETED) {
                result = getSuitFromString(game.result.suit);
            }
            var gameCreated = moment.utc(game.created).format('yyyyMMDD');
            var date = moment
            .utc(game.gameCreated)
            .format('DD/MM/yyyy HH:mm');
            return (
                <div className='item' key={i}>
                    <div className="content">
                        <div className='game-id'>
                            {gameCreated}{game.sessionIndex}
                        </div>
                        <div>
                            <div className='result' style={{ color: result === "VOID"? '#7F8D9F' : SETTINGS.GAME.CAR.COLOR[result] }}>
                                {result === "VOID" ? getTranslation(result) : <ImgIcon icon={gameContainerThemes.SUITS[result.toLocaleUpperCase()]} width={20} height={20}/> }
                            </div>
                            <div className="result-color" style={{ color: result === "VOID"? '#7F8D9F' : null }}>
                                <div className={classNames("color-container", game.result.color.toLocaleLowerCase(), {voided: result === "VOID" })}>
                                    {result === "VOID" ? getTranslation(result) : null }
                                </div>
                            </div>
                        </div>
                        <div className='date'>
                            {date}
                        </div>
                        {
                            isModal ? <div><GameHash hash={game.hash} /></div> : <DropdownTriggerIcon onClick={()=>{onShowClick(game.sessionIndex)}} show={showHash[game.sessionIndex]}/> 
                        }
                    </div>
                    {
                        (!isModal && showHash[game.sessionIndex]) && (
                            <div className="mobile-game-hash">
                                <div>
                                    {getTranslation("HASH")}:
                                </div>
                                <div>
                                    <GameHash hash={game.hash} />
                                </div>
                            </div>
                        )
                    }
                </div>
            );
        });
        setInfo(rows);
    }
    
    return (
        <div id="recent-container" className='results__container results' onScroll={onScroll}>
            {
                !isModal && (
                    <div className='head'>
                        <span>
                            {getTranslation("RECENTS")}
                        </span>
                        <div>
                            <Button
                                containerClassName="refresh"
                                alt="refresh"
                                onClick={()=>{}}
                                icon={themes.PAGINATION_BACKGROUND}
                                size={30}
                                >
                                    <ImgIcon
                                        icon={themes.REFRESH}
                                        alt="refresh"
                                        size={15}
                                        containerClassName="mobile-refresh-button-container"
                                        />
                                </Button>
                        </div>
                    </div>
                )
            }
            
            <div className={classNames({main: !isModal})}>
                <div className='container'>
                    <div className={classNames('item', {head: !isModal, 'head-modal': isModal} )}>
                        <div className="content">
                            <div className='game-id'>
                                {getTranslation("GAME_ID")}
                            </div>
                            <div className='result-suit-color'>
                                {getTranslation("SUIT_&_COLOR")}
                            </div>
                            <div className='date'>
                                {getTranslation("DATE_&_TIME")}
                            </div>
                            {
                                isModal ?  (
                                    <div className='hash-title'>
                                        {getTranslation("HASH")}
                                    </div>
                                ) :         
                                <div></div>
                            }
                        </div>
                    </div>
                    {info}
                </div>
            </div>

            {!onTop && <BackToTop onClick={backToTop}/>}
        </div>
    )
};

export default Results;