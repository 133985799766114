import React from 'react'

import * as QueryString from "query-string";
import { Redirect } from 'react-router-dom';

import { useVerifyGameSetting } from '../services/userService';
import { getTheme } from '../common/helpers/common';
import ErrorHandler from './error_redirect_statuses/ErrorHandler';
import { ERROR, THEME_CLASS } from '../common/constants/keys';
import { ROUTES } from '../common/constants/routing';


const Launch = ({ location }) => {
    const params = QueryString.parse(location.search);
    let theme = THEME_CLASS.CAR;

    const { response, error } = useVerifyGameSetting(params);

    if (error) {
        theme = getTheme(error.data.result.theme ?? "car");
        return <ErrorHandler topBar={true} theme={theme} language={error.data.result.language} status={ ERROR.ERROR } url={error.data && error.data.backurl} />;
    }

    if(response){
        return <Redirect to={ROUTES.LANDING} />;
    }

    return (<div></div>)
}

export default Launch
