import React, { useState, useEffect } from 'react';

import _ from 'lodash';

import { useStore } from '../../store/StateProvider';
import './Game.less';
import { GAME } from '../../common/constants/game/engine';
import ErrorHandler from '../error_redirect_statuses/ErrorHandler';
import { useIntlProvider } from '../provider/internationalisation/IntlProvider';
import { FOOTER_ITEM, THEME_ENUM } from '../../common/constants/keys';
import Event from '../../common/helpers/events';
import { clearMessage, gameConnected, gameDisconnected, gameEnter, setAssetsLoaded, setBetDetails, setGameData, setLastBetDetails, showServerMaintenanceReminder } from '../../common/actions/gameActions';
import { setErrorRedirect } from '../../common/actions/userActions';
import Engine from '../../engine/Engine';
import { useStorageService } from '../../services/storageService';
import Home from './Home';
import Results from '../results/Results';
import Transactions from '../transactions/Transactions';
import Help from '../help/Help';
import { setBodyProps, getThemeType, getStringFromSuit, getSuitFromString } from '../../common/helpers/common';
import { enableSound, setTempThemeColor, setThemeColor } from '../../common/actions/settingsActions';
import Graphics from '../../engine/Graphics';
import Sound from '../../engine/Audio';
import Flash from './Flash';

const Game = () => {
    const engine = Engine.getInstance();
    const graphics = Graphics.getInstance();
    const sound = Sound;
    const [store, dispatch] = useStore();
    const { game, user, settings } = store;
    const [gameState, setGameState] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [errorRedirectStatuses, setErrorRedirectStatuses] = useState('');
    const [display, setDisplay] = useState('');
    const [initDone, setInitDone] = useState(false);
    const [intlDone, setIntlDone] = useState(false);
    const [initSoundDone, setInitSoundDone] = useState(false);
    const [initGraphicsDone, setInitGraphicsDone] = useState(false);
    const [initImagesDone, setInitImagesDone] = useState(false);
    const [initGameDone, setInitGameDone] = useState(false);
    const [theme, setTheme] = useState('');
    const [themeColor, setLocalThemeColor] = useState('');
    const [anonymous, setAnonymous] = useState(false);
    const [assetsProgress, setAssetsProgress] = useState(0);
    useStorageService(initDone, engine.data.storage);
    useIntlProvider(initDone, engine.data.storage, setIntlDone);

    const on = (event, payload=null) => {
        if (gameState === event) return;
        switch (event) {
            case GAME.GAME_CONNECTION.GAME_CONNECTED:
                gameConnected(dispatch, payload);
                _onConnectionChange(event);
                break;
            case GAME.GAME_CONNECTION.GAME_DISCONNECTED:
                gameDisconnected(dispatch);
                _onConnectionChange(event);
                break;
            case GAME.GAME_CONNECTION.GAME_ENTER:
                gameEnter(dispatch);
                break;
            case GAME.GAME_JOINED:
                _handleLastBet(payload);
                _onGameJoined();
                break;
            case GAME.CONFIG_DONE:
                _onConfigDone();
                break;
            case GAME.GAME_STATE.STARTING:
            case GAME.GAME_STATE.STARTED:
            case GAME.GAME_STATE.ENDED:
            case GAME.SOCKET.GAME_TICK:
            case GAME.BET_PLACED:
            case GAME.BET_PLACING:
            case GAME.BET_QUEUED:
            case GAME.PLAYER_BET:
            case GAME.CASHED_OUT:
            case GAME.CANCEL_BET:
            case GAME.UPDATE_BALANCE:
                setGameData(dispatch, payload);
                break;
            case GAME.ERROR_REDIRECT.SERVER_MAINTENANCE_NOW:
            case GAME.ERROR_REDIRECT.SHUT_DOWN:
            case GAME.ERROR_REDIRECT.DISCONNECT:
                setErrorRedirect(dispatch, payload);
                break;
            case GAME.ERROR_REDIRECT.SERVER_MAINTENANCE_REMINDER:
                showServerMaintenanceReminder(dispatch, payload);
                break;
            case GAME.UPDATE_THEME_COLOR:
                    setThemeColor(dispatch, payload);
                break;
            default:
                break;
        }
    }
    Event.register("Game", on);

    const _onConnectionChange = (event) => {
        console.log('connection changed: ', event);
        setGameState(event);
        if (event === GAME.GAME_CONNECTION.GAME_DISCONNECTED) {
            setIsConnected(game.isConnected);
        }
    }

    const _handleLastBet = (lastBet) => {
        const lastBetDetails = _.map(lastBet,(detail)=> {
            return { betValue: detail.BetType === "Suit" ? getSuitFromString(detail.BetValue) : detail.BetValue.toLocaleUpperCase(), amount: parseInt(detail.BetAmount) }
        });

        setBetDetails(dispatch, lastBetDetails);
        setLastBetDetails(dispatch, lastBetDetails);
    }

    const _onConfigDone = () => {
        setInitDone(true);
    }

    const _onGameJoined = () => {
        setInitGameDone(true);
    }

    useEffect(() => {
        if (!isConnected) {
            engine.ready();
        }
    }, []);

    useEffect(() => {
        if ("loadImage" in game) {
            // if (game.loadImage === 0) {
                setInitImagesDone(true);
            // }
        }
    })

    useEffect(() => {
        let total = 0;
        var inits = [initDone, intlDone, initGameDone, initSoundDone, initGraphicsDone, initImagesDone];
        for (var x in inits) {
            var init = inits[x];
            if (init) total++;
        }
        console.log(inits);
        var done = (total/inits.length*100).toFixed(2);
        setAssetsLoaded(dispatch, done);
        setAssetsProgress(done);
    }, [initDone, intlDone, initGameDone, initSoundDone, initGraphicsDone, initImagesDone]);

    useEffect(() => {
        if (!theme || !themeColor || _.isEmpty(game.storage)) return;

        sound.init(setInitSoundDone, getThemeType(theme), game.storage.lang);
        graphics.init(setInitGraphicsDone, theme, themeColor);
    }, [theme, themeColor, game.storage.lang]);

    useEffect(() => {
        var themeClr = settings.themeColor;
        if (!game || !settings || !game.storage || !game.storage.theme || !settings.themeColor) return;
        if (("anonymous" in game.storage)) {
            setAnonymous(game.storage.anonymous);
            if (game.storage.anonymous) {
                themeClr = THEME_ENUM.PINK;
                if (settings.themeColor !== themeClr) {
                    setTempThemeColor(dispatch, settings.themeColor);
                }
                setThemeColor(dispatch, themeClr);
            }
        }
        setTheme(game.storage.theme);
        setLocalThemeColor(themeClr);
        setBodyProps(game.storage.theme, themeClr);
    }, [game, settings.themeColor, game.storage.anonymous]);
    
    // useEffect(() => {
    //     if (game.placeBet) {
    //         console.log('placeBet', game.placeBet)
    //         engine.bet(game.buyAmount, game.buyCard);
    //     }
    //     if (game.placeBet < 0) {
    //         console.log('cancelBet');
    //         engine.cancelBet();
    //     }
    // }, [game.placeBet]);

    useEffect(() => {
        if (game.placeBet) {
            console.log('placeBet', game.placeBet)

            engine.newBet(game.buyAmount, _.map(game.betDetail, (detail) => {
                return {
                    betValue: getStringFromSuit(detail.betValue),
                    amount: detail.amount
                }
            }));
        }
    }, [game.placeBet]);
    
    useEffect(() => {
        if (!settings || !("soundEnable" in settings) || !("themeColor" in settings) || !settings.save) return;
        var sound = (settings.soundEnable === false || settings.soundEnable === "false")? false : true;
        var themeClr = (anonymous)? settings.tempThemeColor : settings.themeColor;
        engine.doSetting(sound, themeClr);
    }, [settings.save]);

    useEffect(() => {
        if (!settings || !("soundEnable" in settings)) return;
        Sound.enable(settings.soundEnable);
    }, [settings.soundEnable]);

    useEffect(() => {
        if ("isConnected" in game && game.isConnected && isConnected !== game.isConnected) {
            engine.start();
        }
    }, [game.isConnected]);

    useEffect(() => {
        if (!user || !("errorRedirect" in user)) return;
        console.log('errorRedirect', user.errorRedirect);
        clearMessage(dispatch);
        const { errorRedirect } = user;
        setErrorRedirectStatuses(<ErrorHandler theme={theme} status={errorRedirect.type} language={game.storage.lang} />);
    }, [user.errorRedirect, theme]);

    useEffect(() => {
        if (!user || !("pageUrl" in user)) return;
        const { pageUrl } = user;
        switch (pageUrl) {
            case FOOTER_ITEM.HOME:
                setDisplay(<Home 
                    theme={theme}
                    themeColor={themeColor}
                    engine={engine}
                    />);
                break;
            case FOOTER_ITEM.RESULTS:
                setDisplay(<Results
                    theme={theme}
                    themeColor={themeColor}
                    engine={engine}
                    />);
                break;
            case FOOTER_ITEM.TRANSACTIONS:
                setDisplay(<Transactions
                    theme={theme}
                    themeColor={themeColor}
                    engine={engine}
                    lang={game.storage.lang}
                    />);
                break;
            case FOOTER_ITEM.HELP:
                setDisplay(<Help
                    theme={theme}
                    themeColor={themeColor}
                    engine={engine}
                    />);
                break;
            default:
                setDisplay(<></>);
                break;
        }
    }, [user.pageUrl, theme, themeColor]);

    const start = () => {
        Event.broadcast(GAME.GAME_CONNECTION.GAME_ENTER);
        console.assert(assetsProgress >= 100);
        setIsConnected(true);
        enableSound(dispatch);
        Sound.audioContext.resume(); // Fix IOS not resume audio context
    }

    return (
        <>
        { errorRedirectStatuses && errorRedirectStatuses }
        { !isConnected && <Flash
                            theme={theme}
                            themeColor={themeColor}
                            progress={assetsProgress}
                            start={start} /> }
        { isConnected && display }
        </>
    );
};

export default Game;