import { ICONS }  from './iconWrapper';
import { THEME_CLASS, THEME_ENUM } from './keys';
import { SOUNDS } from './soundWrapper';

export const THEMES = [
    {
        className: THEME_CLASS.DEFAULT,
        overlapBackground: ICONS.THEME.DEFAULT.BACKGROUND,
        game: {
            sounds: {

            },
            textures: [
                { id:"club", src:ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.SUITS.CLUB, frames:1 },
                { id:"diamond", src:ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.SUITS.DIAMOND, frames:1 },
            ],
            gameContainer: 
            {
                SUITS: {
                    CLUB: ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.SUITS.CLUB,
                    DIAMOND: ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.SUITS.DIAMOND,
                    HEART: ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.SUITS.HEART,
                    SPADE: ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.SUITS.SPADE,
                },
                GAME_ID: {
                    LEFT: ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.GAME_ID.LEFT,
                    CENTER: ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.GAME_ID.CENTER,
                    RIGHT: ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.GAME_ID.RIGHT
                },
                BALANCE: {
                    LEFT: ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.BALANCE.LEFT,
                    CENTER: ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.BALANCE.CENTER,
                    RIGHT: ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.BALANCE.RIGHT
                },
                SOUND: {
                    LEFT: ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.SOUND.LEFT,
                    CENTER: ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.SOUND.CENTER,
                    RIGHT: ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.SOUND.RIGHT
                },
                CHAT: ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHICS_BOTTOM_CONTAINER.CHAT,
                PANEL: ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHICS_BOTTOM_CONTAINER.PANEL,
            }
        }
    },
    {
        className: THEME_CLASS.CAR,
        overlapBackground: ICONS.THEME.CAR.BACKGROUND,
        common: [
            {
                color: THEME_ENUM.BLACK,
                LEFT_ARROW: ICONS.THEME.CAR.COMMON.LEFT_ARROW,
                RIGHT_ARROW: ICONS.THEME.CAR.COMMON.RIGHT_ARROW,
                REFRESH: ICONS.THEME.CAR.COMMON.REFRESH,
                PAGINATION_BACKGROUND: ICONS.THEME.CAR.COMMON.PAGINATION_BACKGROUND.BLACK,
            },
            {
                color: THEME_ENUM.BLUE,
                LEFT_ARROW: ICONS.THEME.CAR.COMMON.LEFT_ARROW,
                RIGHT_ARROW: ICONS.THEME.CAR.COMMON.RIGHT_ARROW,
                REFRESH: ICONS.THEME.CAR.COMMON.REFRESH,
                PAGINATION_BACKGROUND: ICONS.THEME.CAR.COMMON.PAGINATION_BACKGROUND.BLUE,
            },
            {
                color: THEME_ENUM.GREEN,
                LEFT_ARROW: ICONS.THEME.CAR.COMMON.LEFT_ARROW,
                RIGHT_ARROW: ICONS.THEME.CAR.COMMON.RIGHT_ARROW,
                REFRESH: ICONS.THEME.CAR.COMMON.REFRESH,
                PAGINATION_BACKGROUND: ICONS.THEME.CAR.COMMON.PAGINATION_BACKGROUND.GREEN,
            },
            {
                color: THEME_ENUM.BROWN,
                LEFT_ARROW: ICONS.THEME.CAR.COMMON.LEFT_ARROW,
                RIGHT_ARROW: ICONS.THEME.CAR.COMMON.RIGHT_ARROW,
                REFRESH: ICONS.THEME.CAR.COMMON.REFRESH,
                PAGINATION_BACKGROUND: ICONS.THEME.CAR.COMMON.PAGINATION_BACKGROUND.BROWN,
            },
            {
                color: THEME_ENUM.PINK,
                LEFT_ARROW: ICONS.THEME.CAR.COMMON.LEFT_ARROW,
                RIGHT_ARROW: ICONS.THEME.CAR.COMMON.RIGHT_ARROW,
                REFRESH: ICONS.THEME.CAR.COMMON.REFRESH,
                PAGINATION_BACKGROUND: ICONS.THEME.CAR.COMMON.PAGINATION_BACKGROUND.PINK,
            },
        ],
        game: {
            message: {
                LOCKED_ACCESS: ICONS.THEME.CAR.GAME.MESSAGE.ACCOUNT_LOCKED.LOCKED_ACCESS,
                LOCKED_BG: ICONS.THEME.CAR.GAME.MESSAGE.ACCOUNT_LOCKED.LOCKED_BG,
            },
            flash: {
                BUTTON: ICONS.THEME.CAR.GAME.FLASH.BUTTON,
            },
            sounds: {
                COUNTDOWN: SOUNDS.THEME.CAR.COUNTDOWN,
                BACKGROUND: SOUNDS.THEME.CAR.BACKGROUND,
                BACKGROUND_ALT: SOUNDS.THEME.CAR.BACKGROUND_ALT,
                WELCOME: SOUNDS.THEME.CAR.WELCOME,
                TURBO: SOUNDS.THEME.CAR.TURBO,
                TURBO_CAR: SOUNDS.THEME.CAR.TURBO_CAR,
                // CHOOSE_SUITS: SOUNDS.THEME.CAR.CHOOSE_SUITS.EN,
                // CHOOSE_SUITS_CN: SOUNDS.THEME.CAR.CHOOSE_SUITS.CN,
                // CHOOSING__CLUB: SOUNDS.THEME.CAR.CHOOSING.EN.CLUB,
                // CHOOSING__HEART: SOUNDS.THEME.CAR.CHOOSING.EN.HEART,
                // CHOOSING__SPADE: SOUNDS.THEME.CAR.CHOOSING.EN.SPADE,
                // CHOOSING__DIAMOND: SOUNDS.THEME.CAR.CHOOSING.EN.DIAMOND,
                // CHOOSING__CLUB_CN: SOUNDS.THEME.CAR.CHOOSING.CN.CLUB,
                // CHOOSING__HEART_CN: SOUNDS.THEME.CAR.CHOOSING.CN.HEART,
                // CHOOSING__SPADE_CN: SOUNDS.THEME.CAR.CHOOSING.CN.SPADE,
                // CHOOSING__DIAMOND_CN: SOUNDS.THEME.CAR.CHOOSING.CN.DIAMOND,
                SO_CLOSE: SOUNDS.THEME.CAR.SO_CLOSE,
                BETTER_LUCK: SOUNDS.THEME.CAR.BETTER_LUCK,
                PLACE_BETS:{
                    PLACE_BETS_EN: SOUNDS.THEME.CAR.PLACE_BETS.EN,
                    PLACE_BETS_CN: SOUNDS.THEME.CAR.PLACE_BETS.CN,
                    PLACE_BETS_TH: SOUNDS.THEME.CAR.PLACE_BETS.TH,
                    PLACE_BETS_ID: SOUNDS.THEME.CAR.PLACE_BETS.ID,
                    PLACE_BETS_VN: SOUNDS.THEME.CAR.PLACE_BETS.VN,
                },
                CONGRATULATIONS:{
                    CONGRATULATIONS_EN: SOUNDS.THEME.CAR.CONGRATULATIONS.EN,
                    CONGRATULATIONS_CN: SOUNDS.THEME.CAR.CONGRATULATIONS.CN,
                    CONGRATULATIONS_TH: SOUNDS.THEME.CAR.CONGRATULATIONS.TH,
                    CONGRATULATIONS_ID: SOUNDS.THEME.CAR.CONGRATULATIONS.ID,
                    CONGRATULATIONS_VN: SOUNDS.THEME.CAR.CONGRATULATIONS.VN,
                },
                WINNING__CLUB:{
                    WINNING__CLUB_EN: SOUNDS.THEME.CAR.WINNING.EN.CLUB,
                    WINNING__CLUB_CN: SOUNDS.THEME.CAR.WINNING.CN.CLUB,
                    WINNING__CLUB_TH: SOUNDS.THEME.CAR.WINNING.TH.CLUB,
                    WINNING__CLUB_ID: SOUNDS.THEME.CAR.WINNING.ID.CLUB,
                    WINNING__CLUB_VN: SOUNDS.THEME.CAR.WINNING.VN.CLUB,
                },
                WINNING__HEART:{
                    WINNING__HEART_EN: SOUNDS.THEME.CAR.WINNING.EN.HEART,
                    WINNING__HEART_CN: SOUNDS.THEME.CAR.WINNING.CN.HEART,
                    WINNING__HEART_TH: SOUNDS.THEME.CAR.WINNING.TH.HEART,
                    WINNING__HEART_ID: SOUNDS.THEME.CAR.WINNING.ID.HEART,
                    WINNING__HEART_VN: SOUNDS.THEME.CAR.WINNING.VN.HEART,
                },
                WINNING__SPADE:{
                    WINNING__SPADE_EN: SOUNDS.THEME.CAR.WINNING.EN.SPADE,
                    WINNING__SPADE_CN: SOUNDS.THEME.CAR.WINNING.CN.SPADE,
                    WINNING__SPADE_TH: SOUNDS.THEME.CAR.WINNING.TH.SPADE,
                    WINNING__SPADE_ID: SOUNDS.THEME.CAR.WINNING.ID.SPADE,
                    WINNING__SPADE_VN: SOUNDS.THEME.CAR.WINNING.VN.SPADE,
                },
                WINNING__DIAMOND:{
                    WINNING__DIAMOND_EN: SOUNDS.THEME.CAR.WINNING.EN.DIAMOND,
                    WINNING__DIAMOND_CN: SOUNDS.THEME.CAR.WINNING.CN.DIAMOND,
                    WINNING__DIAMOND_TH: SOUNDS.THEME.CAR.WINNING.TH.DIAMOND,
                    WINNING__DIAMOND_ID: SOUNDS.THEME.CAR.WINNING.ID.DIAMOND,
                    WINNING__DIAMOND_VN: SOUNDS.THEME.CAR.WINNING.VN.DIAMOND,
                },
                CARD_FLIP__CLUB:{
                    CARD_FLIP__CLUB_EN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.EN.CLUB,
                    CARD_FLIP__CLUB_CN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.CN.CLUB,
                    CARD_FLIP__CLUB_TH: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.TH.CLUB,
                    CARD_FLIP__CLUB_ID: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.ID.CLUB,
                    CARD_FLIP__CLUB_VN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.VN.CLUB,
                },
                CARD_FLIP__HEART:{
                    CARD_FLIP__HEART_EN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.EN.HEART,
                    CARD_FLIP__HEART_CN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.CN.HEART,
                    CARD_FLIP__HEART_TH: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.TH.HEART,
                    CARD_FLIP__HEART_ID: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.ID.HEART,
                    CARD_FLIP__HEART_VN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.VN.HEART,
                },
                CARD_FLIP__SPADE:{
                    CARD_FLIP__SPADE_EN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.EN.SPADE,
                    CARD_FLIP__SPADE_CN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.CN.SPADE,
                    CARD_FLIP__SPADE_TH: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.TH.SPADE,
                    CARD_FLIP__SPADE_ID: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.ID.SPADE,
                    CARD_FLIP__SPADE_VN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.VN.SPADE,
                },
                CARD_FLIP__DIAMOND:{
                    CARD_FLIP__DIAMOND_EN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.EN.DIAMOND,
                    CARD_FLIP__DIAMOND_CN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.CN.DIAMOND,
                    CARD_FLIP__DIAMOND_TH: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.TH.DIAMOND,
                    CARD_FLIP__DIAMOND_ID: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.ID.DIAMOND,
                    CARD_FLIP__DIAMOND_VN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.VN.DIAMOND,
                },
                CARD_FLIP: SOUNDS.THEME.CAR.CARD_FLIP,
            },
            textures: [
                { id:"club", src: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.CLUB, frames:1 },
                { id:"heart", src: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.HEART, frames:1 },
                { id:"spade", src: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.SPADE, frames:1 },
                { id:"diamond", src: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.DIAMOND, frames:1 },

                { id:"arrow-up", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.ARROW.UP, frames:1 },
                { id:"arrow-down", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.ARROW.DOWN, frames:1 },

                { id:"highlight", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HIGHLIGHT, frames:1 },
                { id:"bg", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BACKGROUND, frames:1 },
                { id:"desktop-bg", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.DESKTOP_BACKGROUND, frames:1 },
                { id:"start-line", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.START_LINE, frames:1 },
                { id:"desktop-start-line", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.DESKTOP_START_LINE, frames:1 },

                { id:"history-bg-blue", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HISTORY.BACKGROUND.BLUE, frames:1 },
                { id:"history-bg-brown", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HISTORY.BACKGROUND.BROWN, frames:1 },
                { id:"history-bg-green", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HISTORY.BACKGROUND.GREEN, frames:1 },
                { id:"history-bg-black", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HISTORY.BACKGROUND.BLACK, frames:1 },
                { id:"history-bg-pink", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HISTORY.BACKGROUND.PINK, frames:1 },

                { id:"history-flag", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HISTORY.FLAG, frames:1 },
                { id:"history-cover", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HISTORY.COVER, frames:1 },

                { id:"car-club", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.DEFAULT_SIZE.CLUB, frames:1 },
                { id:"car-heart", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.DEFAULT_SIZE.HEART, frames:1 },
                { id:"car-spade", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.DEFAULT_SIZE.SPADE, frames:1 },
                { id:"car-diamond", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.DEFAULT_SIZE.DIAMOND, frames:1 },

                { id:"blue-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.BLUE, frames:1 },
                { id:"yellow-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.YELLOW, frames:1 },
                { id:"red-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.RED, frames:1 },
                { id:"green-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.GREEN, frames:1 },

                { id:"blue-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.BLUE, frames:1 },
                { id:"yellow-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.YELLOW, frames:1 },
                { id:"red-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.RED, frames:1 },
                { id:"green-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.GREEN, frames:1 },

                { id:"blue-ind-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.BLUE, frames:1 },
                { id:"yellow-ind-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.YELLOW, frames:1 },
                { id:"red-ind-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.RED, frames:1 },
                { id:"green-ind-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.GREEN, frames:1 },

                { id:"blue-ind-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.BLUE, frames:1 },
                { id:"yellow-ind-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.YELLOW, frames:1 },
                { id:"red-ind-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.RED, frames:1 },
                { id:"green-ind-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.GREEN, frames:1 },

                { id:"blue-ind-booster-4x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.BLUE, frames:1 },
                { id:"yellow-ind-booster-4x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.YELLOW, frames:1 },
                { id:"red-ind-booster-4x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.RED, frames:1 },
                { id:"green-ind-booster-4x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.GREEN, frames:1 },

                { id:"blue-ind-booster-5x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.BLUE, frames:1 },
                { id:"yellow-ind-booster-5x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.YELLOW, frames:1 },
                { id:"red-ind-booster-5x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.RED, frames:1 },
                { id:"green-ind-booster-5x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.GREEN, frames:1 },

                { id:"blue-ind-booster-6x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.BLUE, frames:1 },
                { id:"yellow-ind-booster-6x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.YELLOW, frames:1 },
                { id:"red-ind-booster-6x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.RED, frames:1 },
                { id:"green-ind-booster-6x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.GREEN, frames:1 },

                { id:"blue-ind-booster-7x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.BLUE, frames:1 },
                { id:"yellow-ind-booster-7x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.YELLOW, frames:1 },
                { id:"red-ind-booster-7x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.RED, frames:1 },
                { id:"green-ind-booster-7x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.GREEN, frames:1 },

                { id:"blue-ind-booster-8x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.BLUE, frames:1 },
                { id:"yellow-ind-booster-8x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.YELLOW, frames:1 },
                { id:"red-ind-booster-8x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.RED, frames:1 },
                { id:"green-ind-booster-8x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.GREEN, frames:1 },


                { id:"car-club-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.LARGE_SIZE.CLUB, frames:1 },
                { id:"car-heart-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.LARGE_SIZE.HEART, frames:1 },
                { id:"car-spade-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.LARGE_SIZE.SPADE, frames:1 },
                { id:"car-diamond-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.LARGE_SIZE.DIAMOND, frames:1 },
                
                { id:"blue-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.LARGE_SIZE.RED, frames:1 },
                { id:"green-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.LARGE_SIZE.RED, frames:1 },
                { id:"green-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.LARGE_SIZE.RED, frames:1 },
                { id:"green-ind-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.LARGE_SIZE.RED, frames:1 },
                { id:"green-ind-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-4x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-4x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-4x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.LARGE_SIZE.RED, frames:1 },
                { id:"green-ind-booster-4x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-5x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-5x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-5x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.LARGE_SIZE.RED, frames:1 },
                { id:"green-ind-booster-5x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-6x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-6x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-6x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.LARGE_SIZE.RED, frames:1 },
                { id:"green-ind-booster-6x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-7x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-7x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-7x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.LARGE_SIZE.RED, frames:1 },
                { id:"green-ind-booster-7x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-8x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-8x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-8x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.LARGE_SIZE.RED, frames:1 },
                { id:"green-ind-booster-8x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.LARGE_SIZE.GREEN, frames:1 },



                { id:"car-club-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.SMALL_SIZE.CLUB, frames:1 },
                { id:"car-heart-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.SMALL_SIZE.HEART, frames:1 },
                { id:"car-spade-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.SMALL_SIZE.SPADE, frames:1 },
                { id:"car-diamond-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.SMALL_SIZE.DIAMOND, frames:1 },

                { id:"blue-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.SMALL_SIZE.RED, frames:1 },
                { id:"green-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.SMALL_SIZE.RED, frames:1 },
                { id:"green-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.SMALL_SIZE.RED, frames:1 },
                { id:"green-ind-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.SMALL_SIZE.RED, frames:1 },
                { id:"green-ind-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-4x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-4x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-4x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.SMALL_SIZE.RED, frames:1 },
                { id:"green-ind-booster-4x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-5x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-5x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-5x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.SMALL_SIZE.RED, frames:1 },
                { id:"green-ind-booster-5x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-6x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-6x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-6x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.SMALL_SIZE.RED, frames:1 },
                { id:"green-ind-booster-6x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-7x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-7x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-7x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.SMALL_SIZE.RED, frames:1 },
                { id:"green-ind-booster-7x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-8x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-8x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-8x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.SMALL_SIZE.RED, frames:1 },
                { id:"green-ind-booster-8x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.SMALL_SIZE.GREEN, frames:1 },
            ],
            gameContainer: [
                {
                    color: THEME_ENUM.DEFAULT,
                    SUITS: {
                        BACK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.BACK,
                        SPADE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.SPADE,
                        HEART: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.HEART,
                        CLUB: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.CLUB,
                        DIAMOND: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.DIAMOND,
                    },
                    CHIPS:{
                        BLUE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CHIPS.BLUE,
                        ORANGE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CHIPS.ORANGE,
                        PURPLE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CHIPS.PURPLE,
                        GREEN: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CHIPS.GREEN,
                        RED: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CHIPS.RED,
                    },
                    REBET_ICON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.REBET_ICON,
                    REBET_CANCEL_ICON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.REBET_CANCEL_ICON,
                },
                {
                    color: THEME_ENUM.BLACK,
                    GAME_ID: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.GAME_ID.BLACK,
                    BALANCE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.BALANCE.BLACK,
                    SETTING: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.SETTING.BLACK,
                },
                {
                    color: THEME_ENUM.BLUE,
                    GAME_ID: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.GAME_ID.BLUE,
                    BALANCE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.BALANCE.BLUE,
                    SETTING: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.SETTING.BLUE,
                },
                {
                    color: THEME_ENUM.BROWN,
                    GAME_ID: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.GAME_ID.BROWN,
                    BALANCE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.BALANCE.BROWN,
                    SETTING: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.SETTING.BROWN,
                },
                {
                    color: THEME_ENUM.GREEN,
                    GAME_ID: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.GAME_ID.GREEN,
                    BALANCE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.BALANCE.GREEN,
                    SETTING: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.SETTING.GREEN,
                },
                {
                    color: THEME_ENUM.PINK,
                    GAME_ID: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.GAME_ID.PINK,
                    BALANCE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.BALANCE.PINK,
                    SETTING: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.SETTING.PINK,
                },
            ],
            controls: [
                {
                    color: THEME_ENUM.BLACK,
                    BUY_AMOUNT: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BUY_AMOUNT.BLACK,
                    SUIT_CHOICE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.SUIT_CHOICE.BLACK,
                    INPUT_PROGRESS_BAR: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.FILL.BLACK,
                    INPUT_PROGRESS_BAR_EMPTY: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.EMPTY.BLACK,
                    BET_INCREMENT_BUTTON_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.CLICK.BLACK,
                    BET_INCREMENT_BUTTON_WO_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.WITHOUT_CLICK.BLACK,
                    BUY_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUY_BTN.BLACK,
                    BUYING_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUYING_BTN,
                },
                {
                    color: THEME_ENUM.BLUE,
                    BUY_AMOUNT: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BUY_AMOUNT.BLUE,
                    SUIT_CHOICE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.SUIT_CHOICE.BLUE,
                    INPUT_PROGRESS_BAR: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.FILL.BLUE,
                    INPUT_PROGRESS_BAR_EMPTY: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.EMPTY.BLUE,
                    BET_INCREMENT_BUTTON_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.CLICK.BLUE,
                    BET_INCREMENT_BUTTON_WO_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.WITHOUT_CLICK.BLUE,
                    BUY_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUY_BTN.BLUE,
                    BUYING_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUYING_BTN,
                },
                {
                    color: THEME_ENUM.BROWN,
                    BUY_AMOUNT: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BUY_AMOUNT.BROWN,
                    SUIT_CHOICE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.SUIT_CHOICE.BROWN,
                    INPUT_PROGRESS_BAR: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.FILL.BROWN,
                    INPUT_PROGRESS_BAR_EMPTY: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.EMPTY.BROWN,
                    BET_INCREMENT_BUTTON_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.CLICK.BROWN,
                    BET_INCREMENT_BUTTON_WO_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.WITHOUT_CLICK.BROWN,
                    BUY_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUY_BTN.BROWN,
                    BUYING_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUYING_BTN,
                },
                {
                    color: THEME_ENUM.GREEN,
                    BUY_AMOUNT: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BUY_AMOUNT.GREEN,
                    SUIT_CHOICE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.SUIT_CHOICE.GREEN,
                    INPUT_PROGRESS_BAR: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.FILL.GREEN,
                    INPUT_PROGRESS_BAR_EMPTY: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.EMPTY.GREEN,
                    BET_INCREMENT_BUTTON_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.CLICK.GREEN,
                    BET_INCREMENT_BUTTON_WO_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.WITHOUT_CLICK.GREEN,
                    BUY_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUY_BTN.GREEN,
                    BUYING_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUYING_BTN,
                },
                {
                    color: THEME_ENUM.PINK,
                    BUY_AMOUNT: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BUY_AMOUNT.PINK,
                    SUIT_CHOICE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.SUIT_CHOICE.PINK,
                    INPUT_PROGRESS_BAR: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.FILL.PINK,
                    INPUT_PROGRESS_BAR_EMPTY: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.EMPTY.PINK,
                    BET_INCREMENT_BUTTON_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.CLICK.PINK,
                    BET_INCREMENT_BUTTON_WO_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.WITHOUT_CLICK.PINK,
                    BUY_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUY_BTN.PINK,
                    BUYING_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUYING_BTN,
                },
            ]
        }
    },
    {
        className: THEME_CLASS.OKBET,
        overlapBackground: ICONS.THEME.CAR.BACKGROUND,
        common: [
            {
                color: THEME_ENUM.BLACK,
                LEFT_ARROW: ICONS.THEME.CAR.COMMON.LEFT_ARROW,
                RIGHT_ARROW: ICONS.THEME.CAR.COMMON.RIGHT_ARROW,
                REFRESH: ICONS.THEME.CAR.COMMON.REFRESH,
                PAGINATION_BACKGROUND: ICONS.THEME.CAR.COMMON.PAGINATION_BACKGROUND.BLACK,
            },
            {
                color: THEME_ENUM.BLUE,
                LEFT_ARROW: ICONS.THEME.CAR.COMMON.LEFT_ARROW,
                RIGHT_ARROW: ICONS.THEME.CAR.COMMON.RIGHT_ARROW,
                REFRESH: ICONS.THEME.CAR.COMMON.REFRESH,
                PAGINATION_BACKGROUND: ICONS.THEME.CAR.COMMON.PAGINATION_BACKGROUND.BLUE,
            },
            {
                color: THEME_ENUM.GREEN,
                LEFT_ARROW: ICONS.THEME.CAR.COMMON.LEFT_ARROW,
                RIGHT_ARROW: ICONS.THEME.CAR.COMMON.RIGHT_ARROW,
                REFRESH: ICONS.THEME.CAR.COMMON.REFRESH,
                PAGINATION_BACKGROUND: ICONS.THEME.CAR.COMMON.PAGINATION_BACKGROUND.GREEN,
            },
            {
                color: THEME_ENUM.BROWN,
                LEFT_ARROW: ICONS.THEME.CAR.COMMON.LEFT_ARROW,
                RIGHT_ARROW: ICONS.THEME.CAR.COMMON.RIGHT_ARROW,
                REFRESH: ICONS.THEME.CAR.COMMON.REFRESH,
                PAGINATION_BACKGROUND: ICONS.THEME.CAR.COMMON.PAGINATION_BACKGROUND.BROWN,
            },
            {
                color: THEME_ENUM.PINK,
                LEFT_ARROW: ICONS.THEME.CAR.COMMON.LEFT_ARROW,
                RIGHT_ARROW: ICONS.THEME.CAR.COMMON.RIGHT_ARROW,
                REFRESH: ICONS.THEME.CAR.COMMON.REFRESH,
                PAGINATION_BACKGROUND: ICONS.THEME.CAR.COMMON.PAGINATION_BACKGROUND.PINK,
            },
        ],
        game: {
            message: {
                LOCKED_ACCESS: ICONS.THEME.CAR.GAME.MESSAGE.ACCOUNT_LOCKED.LOCKED_ACCESS,
                LOCKED_BG: ICONS.THEME.CAR.GAME.MESSAGE.ACCOUNT_LOCKED.LOCKED_BG,
            },
            flash: {
                BUTTON: ICONS.THEME.CAR.GAME.FLASH.BUTTON,
            },
            sounds: {
                COUNTDOWN: SOUNDS.THEME.CAR.COUNTDOWN,
                BACKGROUND: SOUNDS.THEME.CAR.BACKGROUND,
                BACKGROUND_ALT: SOUNDS.THEME.CAR.BACKGROUND_ALT,
                WELCOME: SOUNDS.THEME.CAR.WELCOME,
                TURBO: SOUNDS.THEME.CAR.TURBO,
                TURBO_CAR: SOUNDS.THEME.CAR.TURBO_CAR,
                // CHOOSE_SUITS: SOUNDS.THEME.CAR.CHOOSE_SUITS.EN,
                // CHOOSE_SUITS_CN: SOUNDS.THEME.CAR.CHOOSE_SUITS.CN,
                // CHOOSING__CLUB: SOUNDS.THEME.CAR.CHOOSING.EN.CLUB,
                // CHOOSING__HEART: SOUNDS.THEME.CAR.CHOOSING.EN.HEART,
                // CHOOSING__SPADE: SOUNDS.THEME.CAR.CHOOSING.EN.SPADE,
                // CHOOSING__DIAMOND: SOUNDS.THEME.CAR.CHOOSING.EN.DIAMOND,
                // CHOOSING__CLUB_CN: SOUNDS.THEME.CAR.CHOOSING.CN.CLUB,
                // CHOOSING__HEART_CN: SOUNDS.THEME.CAR.CHOOSING.CN.HEART,
                // CHOOSING__SPADE_CN: SOUNDS.THEME.CAR.CHOOSING.CN.SPADE,
                // CHOOSING__DIAMOND_CN: SOUNDS.THEME.CAR.CHOOSING.CN.DIAMOND,
                SO_CLOSE: SOUNDS.THEME.CAR.SO_CLOSE,
                BETTER_LUCK: SOUNDS.THEME.CAR.BETTER_LUCK,
                PLACE_BETS:{
                    PLACE_BETS_EN: SOUNDS.THEME.CAR.PLACE_BETS.EN,
                    PLACE_BETS_CN: SOUNDS.THEME.CAR.PLACE_BETS.CN,
                    PLACE_BETS_TH: SOUNDS.THEME.CAR.PLACE_BETS.TH,
                    PLACE_BETS_ID: SOUNDS.THEME.CAR.PLACE_BETS.ID,
                    PLACE_BETS_VN: SOUNDS.THEME.CAR.PLACE_BETS.VN,
                },
                CONGRATULATIONS:{
                    CONGRATULATIONS_EN: SOUNDS.THEME.CAR.CONGRATULATIONS.EN,
                    CONGRATULATIONS_CN: SOUNDS.THEME.CAR.CONGRATULATIONS.CN,
                    CONGRATULATIONS_TH: SOUNDS.THEME.CAR.CONGRATULATIONS.TH,
                    CONGRATULATIONS_ID: SOUNDS.THEME.CAR.CONGRATULATIONS.ID,
                    CONGRATULATIONS_VN: SOUNDS.THEME.CAR.CONGRATULATIONS.VN,
                },
                WINNING__CLUB:{
                    WINNING__CLUB_EN: SOUNDS.THEME.CAR.WINNING.EN.CLUB,
                    WINNING__CLUB_CN: SOUNDS.THEME.CAR.WINNING.CN.CLUB,
                    WINNING__CLUB_TH: SOUNDS.THEME.CAR.WINNING.TH.CLUB,
                    WINNING__CLUB_ID: SOUNDS.THEME.CAR.WINNING.ID.CLUB,
                    WINNING__CLUB_VN: SOUNDS.THEME.CAR.WINNING.VN.CLUB,
                },
                WINNING__HEART:{
                    WINNING__HEART_EN: SOUNDS.THEME.CAR.WINNING.EN.HEART,
                    WINNING__HEART_CN: SOUNDS.THEME.CAR.WINNING.CN.HEART,
                    WINNING__HEART_TH: SOUNDS.THEME.CAR.WINNING.TH.HEART,
                    WINNING__HEART_ID: SOUNDS.THEME.CAR.WINNING.ID.HEART,
                    WINNING__HEART_VN: SOUNDS.THEME.CAR.WINNING.VN.HEART,
                },
                WINNING__SPADE:{
                    WINNING__SPADE_EN: SOUNDS.THEME.CAR.WINNING.EN.SPADE,
                    WINNING__SPADE_CN: SOUNDS.THEME.CAR.WINNING.CN.SPADE,
                    WINNING__SPADE_TH: SOUNDS.THEME.CAR.WINNING.TH.SPADE,
                    WINNING__SPADE_ID: SOUNDS.THEME.CAR.WINNING.ID.SPADE,
                    WINNING__SPADE_VN: SOUNDS.THEME.CAR.WINNING.VN.SPADE,
                },
                WINNING__DIAMOND:{
                    WINNING__DIAMOND_EN: SOUNDS.THEME.CAR.WINNING.EN.DIAMOND,
                    WINNING__DIAMOND_CN: SOUNDS.THEME.CAR.WINNING.CN.DIAMOND,
                    WINNING__DIAMOND_TH: SOUNDS.THEME.CAR.WINNING.TH.DIAMOND,
                    WINNING__DIAMOND_ID: SOUNDS.THEME.CAR.WINNING.ID.DIAMOND,
                    WINNING__DIAMOND_VN: SOUNDS.THEME.CAR.WINNING.VN.DIAMOND,
                },
                CARD_FLIP__CLUB:{
                    CARD_FLIP__CLUB_EN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.EN.CLUB,
                    CARD_FLIP__CLUB_CN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.CN.CLUB,
                    CARD_FLIP__CLUB_TH: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.TH.CLUB,
                    CARD_FLIP__CLUB_ID: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.ID.CLUB,
                    CARD_FLIP__CLUB_VN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.VN.CLUB,
                },
                CARD_FLIP__HEART:{
                    CARD_FLIP__HEART_EN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.EN.HEART,
                    CARD_FLIP__HEART_CN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.CN.HEART,
                    CARD_FLIP__HEART_TH: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.TH.HEART,
                    CARD_FLIP__HEART_ID: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.ID.HEART,
                    CARD_FLIP__HEART_VN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.VN.HEART,
                },
                CARD_FLIP__SPADE:{
                    CARD_FLIP__SPADE_EN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.EN.SPADE,
                    CARD_FLIP__SPADE_CN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.CN.SPADE,
                    CARD_FLIP__SPADE_TH: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.TH.SPADE,
                    CARD_FLIP__SPADE_ID: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.ID.SPADE,
                    CARD_FLIP__SPADE_VN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.VN.SPADE,
                },
                CARD_FLIP__DIAMOND:{
                    CARD_FLIP__DIAMOND_EN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.EN.DIAMOND,
                    CARD_FLIP__DIAMOND_CN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.CN.DIAMOND,
                    CARD_FLIP__DIAMOND_TH: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.TH.DIAMOND,
                    CARD_FLIP__DIAMOND_ID: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.ID.DIAMOND,
                    CARD_FLIP__DIAMOND_VN: SOUNDS.THEME.CAR.CARD_FLIP_SUIT.VN.DIAMOND,
                },
                CARD_FLIP: SOUNDS.THEME.CAR.CARD_FLIP,
            },
            textures: [
                { id:"club", src: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.CLUB, frames:1 },
                { id:"heart", src: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.HEART, frames:1 },
                { id:"spade", src: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.SPADE, frames:1 },
                { id:"diamond", src: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.DIAMOND, frames:1 },

                { id:"arrow-up", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.ARROW.UP, frames:1 },
                { id:"arrow-down", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.ARROW.DOWN, frames:1 },

                { id:"highlight", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HIGHLIGHT, frames:1 },
                { id:"bg", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BACKGROUND, frames:1 },
                { id:"desktop-bg", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.DESKTOP_BACKGROUND, frames:1 },
                { id:"start-line", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.START_LINE, frames:1 },
                { id:"desktop-start-line", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.DESKTOP_START_LINE, frames:1 },

                { id:"history-bg-blue", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HISTORY.BACKGROUND.BLUE, frames:1 },
                { id:"history-bg-brown", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HISTORY.BACKGROUND.BROWN, frames:1 },
                { id:"history-bg-green", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HISTORY.BACKGROUND.GREEN, frames:1 },
                { id:"history-bg-black", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HISTORY.BACKGROUND.BLACK, frames:1 },
                { id:"history-bg-pink", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HISTORY.BACKGROUND.PINK, frames:1 },

                { id:"history-flag", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HISTORY.FLAG, frames:1 },
                { id:"history-cover", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.HISTORY.COVER, frames:1 },

                { id:"car-club", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.DEFAULT_SIZE.CLUB, frames:1 },
                { id:"car-heart", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.DEFAULT_SIZE.HEART, frames:1 },
                { id:"car-spade", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.DEFAULT_SIZE.SPADE, frames:1 },
                { id:"car-diamond", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.DEFAULT_SIZE.DIAMOND, frames:1 },

                { id:"blue-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.BLUE, frames:1 },
                { id:"yellow-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.YELLOW, frames:1 },
                { id:"red-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.RED, frames:1 },
                { id:"green-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.GREEN, frames:1 },

                { id:"blue-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.BLUE, frames:1 },
                { id:"yellow-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.YELLOW, frames:1 },
                { id:"red-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.RED, frames:1 },
                { id:"green-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.GREEN, frames:1 },

                { id:"blue-ind-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.BLUE, frames:1 },
                { id:"yellow-ind-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.YELLOW, frames:1 },
                { id:"red-ind-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.RED, frames:1 },
                { id:"green-ind-booster-big", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.GREEN, frames:1 },

                { id:"blue-ind-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.BLUE, frames:1 },
                { id:"yellow-ind-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.YELLOW, frames:1 },
                { id:"red-ind-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.RED, frames:1 },
                { id:"green-ind-booster-small", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.GREEN, frames:1 },

                { id:"blue-ind-booster-4x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.BLUE, frames:1 },
                { id:"yellow-ind-booster-4x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.YELLOW, frames:1 },
                { id:"red-ind-booster-4x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.RED, frames:1 },
                { id:"green-ind-booster-4x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.GREEN, frames:1 },

                { id:"blue-ind-booster-5x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.BLUE, frames:1 },
                { id:"yellow-ind-booster-5x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.YELLOW, frames:1 },
                { id:"red-ind-booster-5x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.RED, frames:1 },
                { id:"green-ind-booster-5x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.GREEN, frames:1 },

                { id:"blue-ind-booster-6x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.BLUE, frames:1 },
                { id:"yellow-ind-booster-6x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.YELLOW, frames:1 },
                { id:"red-ind-booster-6x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.RED, frames:1 },
                { id:"green-ind-booster-6x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.GREEN, frames:1 },

                { id:"blue-ind-booster-7x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.BLUE, frames:1 },
                { id:"yellow-ind-booster-7x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.YELLOW, frames:1 },
                { id:"red-ind-booster-7x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.RED, frames:1 },
                { id:"green-ind-booster-7x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.GREEN, frames:1 },

                { id:"blue-ind-booster-8x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.BLUE, frames:1 },
                { id:"yellow-ind-booster-8x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.YELLOW, frames:1 },
                { id:"red-ind-booster-8x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.RED, frames:1 },
                { id:"green-ind-booster-8x", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.GREEN, frames:1 },


                { id:"car-club-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.LARGE_SIZE.CLUB, frames:1 },
                { id:"car-heart-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.LARGE_SIZE.HEART, frames:1 },
                { id:"car-spade-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.LARGE_SIZE.SPADE, frames:1 },
                { id:"car-diamond-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.LARGE_SIZE.DIAMOND, frames:1 },
                
                { id:"blue-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.LARGE_SIZE.RED, frames:1 },
                { id:"green-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.LARGE_SIZE.RED, frames:1 },
                { id:"green-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.LARGE_SIZE.RED, frames:1 },
                { id:"green-ind-booster-big-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.LARGE_SIZE.RED, frames:1 },
                { id:"green-ind-booster-small-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-4x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-4x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-4x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.LARGE_SIZE.RED, frames:1 },
                { id:"green-ind-booster-4x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-5x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-5x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-5x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.LARGE_SIZE.RED, frames:1 },
                { id:"green-ind-booster-5x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-6x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-6x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-6x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.LARGE_SIZE.RED, frames:1 },
                { id:"green-ind-booster-6x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-7x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-7x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-7x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.LARGE_SIZE.RED, frames:1 },
                { id:"green-ind-booster-7x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.LARGE_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-8x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.LARGE_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-8x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.LARGE_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-8x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.LARGE_SIZE.RED, frames:1 },
                { id:"green-ind-booster-8x-largeSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.LARGE_SIZE.GREEN, frames:1 },



                { id:"car-club-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.SMALL_SIZE.CLUB, frames:1 },
                { id:"car-heart-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.SMALL_SIZE.HEART, frames:1 },
                { id:"car-spade-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.SMALL_SIZE.SPADE, frames:1 },
                { id:"car-diamond-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR.SMALL_SIZE.DIAMOND, frames:1 },

                { id:"blue-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.SMALL_SIZE.RED, frames:1 },
                { id:"green-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.SMALL_SIZE.RED, frames:1 },
                { id:"green-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.BOOSTER_SMALL.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.SMALL_SIZE.RED, frames:1 },
                { id:"green-ind-booster-big-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.SMALL_SIZE.RED, frames:1 },
                { id:"green-ind-booster-small-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_SMALL.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-4x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-4x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-4x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.SMALL_SIZE.RED, frames:1 },
                { id:"green-ind-booster-4x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_4X.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-5x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-5x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-5x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.SMALL_SIZE.RED, frames:1 },
                { id:"green-ind-booster-5x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_5X.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-6x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-6x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-6x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.SMALL_SIZE.RED, frames:1 },
                { id:"green-ind-booster-6x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_6X.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-7x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-7x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-7x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.SMALL_SIZE.RED, frames:1 },
                { id:"green-ind-booster-7x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_7X.SMALL_SIZE.GREEN, frames:1 },

                { id:"blue-ind-booster-8x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.SMALL_SIZE.BLUE, frames:1 },
                { id:"yellow-ind-booster-8x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.SMALL_SIZE.YELLOW, frames:1 },
                { id:"red-ind-booster-8x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.SMALL_SIZE.RED, frames:1 },
                { id:"green-ind-booster-8x-smallSize", src:ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.IND_BOOSTER_8X.SMALL_SIZE.GREEN, frames:1 },
            ],
            gameContainer: [
                {
                    color: THEME_ENUM.DEFAULT,
                    SUITS: {
                        BACK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.BACK,
                        SPADE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.SPADE,
                        HEART: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.HEART,
                        CLUB: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.CLUB,
                        DIAMOND: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.SUITS.DIAMOND,
                    },
                    CHIPS:{
                        BLUE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CHIPS.BLUE,
                        ORANGE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CHIPS.ORANGE,
                        PURPLE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CHIPS.PURPLE,
                        GREEN: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CHIPS.GREEN,
                        RED: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CHIPS.RED,
                    },
                    REBET_ICON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.REBET_ICON,
                    REBET_CANCEL_ICON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.REBET_CANCEL_ICON,
                },
                {
                    color: THEME_ENUM.BLACK,
                    GAME_ID: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.GAME_ID.BLACK,
                    BALANCE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.BALANCE.BLACK,
                    SETTING: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.SETTING.BLACK,
                },
                {
                    color: THEME_ENUM.BLUE,
                    GAME_ID: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.GAME_ID.BLUE,
                    BALANCE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.BALANCE.BLUE,
                    SETTING: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.SETTING.BLUE,
                },
                {
                    color: THEME_ENUM.BROWN,
                    GAME_ID: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.GAME_ID.BROWN,
                    BALANCE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.BALANCE.BROWN,
                    SETTING: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.SETTING.BROWN,
                },
                {
                    color: THEME_ENUM.GREEN,
                    GAME_ID: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.GAME_ID.GREEN,
                    BALANCE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.BALANCE.GREEN,
                    SETTING: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.SETTING.GREEN,
                },
                {
                    color: THEME_ENUM.PINK,
                    GAME_ID: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.GAME_ID.PINK,
                    BALANCE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.BALANCE.PINK,
                    SETTING: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHTICS_TOP_CONTAINER.SETTING.PINK,
                },
            ],
            controls: [
                {
                    color: THEME_ENUM.BLACK,
                    BUY_AMOUNT: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BUY_AMOUNT.BLACK,
                    SUIT_CHOICE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.SUIT_CHOICE.BLACK,
                    INPUT_PROGRESS_BAR: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.FILL.BLACK,
                    INPUT_PROGRESS_BAR_EMPTY: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.EMPTY.BLACK,
                    BET_INCREMENT_BUTTON_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.CLICK.BLACK,
                    BET_INCREMENT_BUTTON_WO_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.WITHOUT_CLICK.BLACK,
                    BUY_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUY_BTN.BLACK,
                    BUYING_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUYING_BTN,
                },
                {
                    color: THEME_ENUM.BLUE,
                    BUY_AMOUNT: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BUY_AMOUNT.BLUE,
                    SUIT_CHOICE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.SUIT_CHOICE.BLUE,
                    INPUT_PROGRESS_BAR: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.FILL.BLUE,
                    INPUT_PROGRESS_BAR_EMPTY: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.EMPTY.BLUE,
                    BET_INCREMENT_BUTTON_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.CLICK.BLUE,
                    BET_INCREMENT_BUTTON_WO_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.WITHOUT_CLICK.BLUE,
                    BUY_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUY_BTN.BLUE,
                    BUYING_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUYING_BTN,
                },
                {
                    color: THEME_ENUM.BROWN,
                    BUY_AMOUNT: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BUY_AMOUNT.BROWN,
                    SUIT_CHOICE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.SUIT_CHOICE.BROWN,
                    INPUT_PROGRESS_BAR: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.FILL.BROWN,
                    INPUT_PROGRESS_BAR_EMPTY: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.EMPTY.BROWN,
                    BET_INCREMENT_BUTTON_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.CLICK.BROWN,
                    BET_INCREMENT_BUTTON_WO_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.WITHOUT_CLICK.BROWN,
                    BUY_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUY_BTN.BROWN,
                    BUYING_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUYING_BTN,
                },
                {
                    color: THEME_ENUM.GREEN,
                    BUY_AMOUNT: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BUY_AMOUNT.GREEN,
                    SUIT_CHOICE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.SUIT_CHOICE.GREEN,
                    INPUT_PROGRESS_BAR: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.FILL.GREEN,
                    INPUT_PROGRESS_BAR_EMPTY: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.EMPTY.GREEN,
                    BET_INCREMENT_BUTTON_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.CLICK.GREEN,
                    BET_INCREMENT_BUTTON_WO_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.WITHOUT_CLICK.GREEN,
                    BUY_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUY_BTN.GREEN,
                    BUYING_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUYING_BTN,
                },
                {
                    color: THEME_ENUM.PINK,
                    BUY_AMOUNT: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BUY_AMOUNT.PINK,
                    SUIT_CHOICE: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.SUIT_CHOICE.PINK,
                    INPUT_PROGRESS_BAR: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.FILL.PINK,
                    INPUT_PROGRESS_BAR_EMPTY: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.INPUT_PROGRESS_BAR.EMPTY.PINK,
                    BET_INCREMENT_BUTTON_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.CLICK.PINK,
                    BET_INCREMENT_BUTTON_WO_CLICK: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.BET_INCREMENT_BUTTON.WITHOUT_CLICK.PINK,
                    BUY_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUY_BTN.PINK,
                    BUYING_BUTTON: ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUYING_BTN,
                },
            ]
        }
    }
];