import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import ImgIcon from './ImgIcon';
import { ICONS } from '../../common/constants/iconWrapper';
import classNames from 'classnames';
import './GameModal.less';
import { THEME_CLASS } from '../../common/constants/keys';

const GameModal = ({
    theme,
    themeColor,
    containerClassName,
    width=330,
    closable=true,
    onClose,
    children
}) => {
    let close = (theme === THEME_CLASS.CAR || theme === THEME_CLASS.OKBET)? (
        <ImgIcon
        icon={ICONS.THEME.CAR.COMMON.CLOSE}
        size={50}
        containerClassName="close"
        onClick={() => onClose()}
    />
    ) : (
        <ImgIcon
        icon={ICONS.COMMON.CLOSE}
        size={64}
        containerClassName="close"
        onClick={() => onClose()}
    />
    );

    return (
        <Modal
            visible
            centered
            closable={false}
            className={classNames(
                containerClassName,
                'gameModal__container'
            )}
            title={null}
            width={width}
            footer={
                closable && close
            }
        >
            {children}
        </Modal>
    );
};

GameModal.prototype = {
    theme: PropTypes.string.isRequired,
    themeColor: PropTypes.string,
    containerClassName: PropTypes.string,
    width: PropTypes.number,
    closable: PropTypes.bool,
    onClose: PropTypes.func
};

export default GameModal;