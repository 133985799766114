import errorReducer from '../common/reducers/errorReducer';
import gameReducer from '../common/reducers/gameReducer';
import settingsReducer from '../common/reducers/settingsReducer';
import userReducer from '../common/reducers/userReducer';

/**
 * Function is super basic analog to Redux's combineReducers().
 * @param {Object} state Current application state.
 * @param {Action} action Dispatched action.
 * @returns New application state.
 */

export default function appReducer(state, action) {
    const { error, game, settings, user } = state;
    const updatedState = {
        error: errorReducer(error, action),
        game: gameReducer(game, action),
        settings: settingsReducer(settings, action),
        user: userReducer(user, action),
    };
    return updatedState;
}