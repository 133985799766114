import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ROUTES } from '../../../common/constants/routing';

import GameRedirection from '../../third-party-redirect/GameRedirection';
import Game from '../../game/Game';
import ErrorHandler from '../../error_redirect_statuses/ErrorHandler';
import LayoutRoute from './LayoutRoute';
import Launch from '../../Launch';
import SabaResult from '../../results/SabaResult';
import { isDesktop } from '../../../common/helpers/common';

const AppRoutes = () => {
    const mainRoute = window.__POWERED_BY_QIANKUN__
      ? ROUTES.CRASH.ROOT
      : ROUTES.LANDING;
    return (
        <>
            <Switch>
                <Route exact path={ROUTES.CLIENT.AUTH} component={GameRedirection} />
                <Route exact path={ROUTES.CLIENT.LAUNCH} component={Launch} />
                <LayoutRoute exact 
                    path={mainRoute} 
                    component={Game}
                    defaultFooter={!isDesktop()}
                />
                <LayoutRoute
                    path={ROUTES.MAINTENANCE}
                    component={ErrorHandler}
                />
                <LayoutRoute
                    path={ROUTES.ERROR}
                    component={ErrorHandler}
                />
                <LayoutRoute
                    path={ROUTES.RESULT}
                    component={SabaResult}
                    backToHeader={{ title: "RESULTS" }}
                    defaultFooter={false}
                />
                <Redirect to={ROUTES.ERROR} />
            </Switch>
        </>
    )
};

export default AppRoutes;