import { ACTIONS } from '../constants/actions';

export const setSettingsStorage = (dispatch, payload) => 
    dispatch({
        payload,
        type: ACTIONS.SET_SETTINGS_STORAGE
    });

export const enableSetting = (dispatch) => 
    dispatch({
        type: ACTIONS.ENABLE_SETTING
    });

export const disableSetting = (dispatch) => 
    dispatch({
        type: ACTIONS.DISABLE_SETTING
    });

export const enableSound = (dispatch) => 
    dispatch({
        type: ACTIONS.ENABLE_SOUND
    });

export const disableSound = (dispatch) => 
    dispatch({
        type: ACTIONS.DISABLE_SOUND
    });

export const setThemeColor = (dispatch, payload) => 
    dispatch({
        payload,
        type: ACTIONS.SET_THEME_COLOR
    });

export const setTempThemeColor = (dispatch, payload) => 
    dispatch({
        payload,
        type: ACTIONS.SET_TEMP_THEME_COLOR
    });

export const saveSetting = (dispatch) => 
    dispatch({
        type: ACTIONS.SAVE_SETTING
    });

export const enablePanel = (dispatch) => 
    dispatch({
        type: ACTIONS.ENABLE_PANEL
    });

export const disablePanel = (dispatch) => 
    dispatch({
        type: ACTIONS.DISABLE_PANEL
    });

export const openHistoryModal = (dispatch) => 
    dispatch({
        type: ACTIONS.OPEN_HISTORY_MODAL
    });

export const closeHistoryModal = (dispatch) => 
    dispatch({
        type: ACTIONS.CLOSE_HISTORY_MODAL
    });

export const openHelpModal = (dispatch) => 
    dispatch({
        type: ACTIONS.OPEN_HELP_MODAL
    });

export const closeHelpModal = (dispatch) => 
    dispatch({
        type: ACTIONS.CLOSE_HELP_MODAL
    });

export const openRecentModal = (dispatch) => 
    dispatch({
        type: ACTIONS.OPEN_RECENT_MODAL
    });

export const closeRecentModal = (dispatch) => 
    dispatch({
        type: ACTIONS.CLOSE_RECENT_MODAL
    });