import _ from 'lodash';
import { THEMES } from '../constants/theme';
import { THEME_CLASS } from '../constants/keys';
import { getLocalizedSoundName } from './common';

export const getThemeClassName = (themeName) => {
    var theme = ensureThemeExist(themeName);

    return theme.className;
}

export const getThemeTextures = (themeName) => {
    var theme = ensureThemeExist(themeName);
    
    return theme.game.textures;
}

export const getThemeSounds = (themeName, language) => {
    var theme = ensureThemeExist(themeName);
    const multiLanguageSounds = ['PLACE_BETS', 'CONGRATULATIONS', 'WINNING__CLUB', 'WINNING__HEART', 
                                'WINNING__SPADE', 'WINNING__DIAMOND', 'CARD_FLIP__CLUB', 'CARD_FLIP__HEART', 'CARD_FLIP__SPADE',
                                'CARD_FLIP__DIAMOND'];
    const sounds = theme.game.sounds;
    return _.reduce(sounds, function(result, value, key) {
        if(_.includes(multiLanguageSounds, key)){
            result[key] = value[getLocalizedSoundName(key, language)];
        }
        else{
            result[key] = value;
        }
        
        return result;
      }, {});
}

export const getThemeFlash = (themeName) => {
    var theme = ensureThemeExist(themeName);

    return theme.game.flash;
}

export const getThemeMessage = (themeName) => {
    var theme = ensureThemeExist(themeName);

    return theme.game.message;
}

export const getThemeOverlapBackground = (themeName) => {
    var theme = ensureThemeExist(themeName);

    return theme.overlapBackground;
}

export const getThemeGameContainer = (themeName, color="") => {
    var theme = ensureThemeExist(themeName);
    var game = (!color)? theme.game.gameContainer : _.find(theme.game.gameContainer, (g) => g.color === color);

    return game;
}

export const getThemeGameControls = (themeName, color="") => {
    var theme = ensureThemeExist(themeName);
    var game = (!color)? theme.game.controls : _.find(theme.game.controls, (g) => g.color === color);

    return game;
}

export const getThemeCommon = (themeName, color="") => {
    var theme = ensureThemeExist(themeName);
    var game = (!color)? theme.common : _.find(theme.common, (g) => g.color === color);

    return game;
}

export const getThemeTransactions = (themeName, color="") => {
    var theme = ensureThemeExist(themeName);
    var game = (!color)? theme.transactions : _.find(theme.transactions, (g) => g.color === color);

    return game;
}

export const themeExist = (themeName) => {
    var theme = _.find(THEMES, (t) => t.className === themeName);
    return theme;
}

const ensureThemeExist = (themeName) => {
    var theme = _.find(THEMES, (t) => t.className === themeName);
    if (!theme) theme = _.find(THEMES, (t) => t.className === THEME_CLASS.CAR);
    return theme;
}