class Texture {
    constructor({id, src, numOfFrames, imageId=0, width=0, height=0, speed=1}, callback) {
        this.img = null;
        
        this.id = id;
        this.src = src;
        this.numOfFrames = numOfFrames;
        this.imageId = imageId;
        this.speed = speed;
        this.width = width;
        this.height = height;

        this.init(callback);
    }

    init = (callback) => {
        var self = this;
        self.img = new Image();
        self.img.src = self.src.default;
        self.img.onload = function() {
            self.imgWidth = parseFloat(self.img.width);
            self.imgHeight = parseFloat(self.img.height);
            if (self.width === 0 || self.height === 0) {
                self.width = self.imgWidth;
                self.height = self.imgHeight;
            }
            self.subImgWidth = self.imgWidth / self.numOfFrames;
            self.subWidth = self.width / self.numOfFrames;
            callback(self);
        }
    }
}

export default Texture;