export const API = {
    HOST: process.env.NODE_ENV === 'development' ? 'http://localhost:13000' : window.location.origin + process.env.PUBLIC_URL,
    HOST_GAME_LAUNCHER: process.env.NODE_ENV === 'development' ? 'http://localhost:14000' : window.location.origin,
    API_BASE: '/api',
    CLIENT: {
        VERIFY_THIRD_PARTY: '/client/verifyThirdParty',
        OTT: '/client/verifyOtt',
        TRANSACTIONS: '/client/transactions',
        VERIFY_GAME_SETTING: '/client/verifyGameSetting',
    },
    HUB: {
        CONNECTION: '/game',
    },
    GAME_RESULT: {
        SABA: '/saba/getGameResult',
    },
};