import React from 'react';
import BorderContainer from '../../common/BorderContainer';
import { getTranslation } from '../../../common/helpers/locale';
import { ICONS } from '../../../common/constants/iconWrapper';
import './BuyAmount.less';

const BuyAmount = ({ 
    width=189,
    minWidth=189,
    height=50,
    children
 }) => {
    return (
        <div className='buyAmount__container' style={{ minWidth: minWidth, width: width, height: height }}>
            <BorderContainer 
                    containerClassName="buy-amount"
                    minWidth={161}
                    leftRightWidth={14}
                    height={height-5}
                    leftImg={ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHICS_BOTTOM_CONTAINER.BUY_AMOUNT.LEFT}
                    centerImg={ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHICS_BOTTOM_CONTAINER.BUY_AMOUNT.CENTER}
                    rightImg={ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHICS_BOTTOM_CONTAINER.BUY_AMOUNT.RIGHT}>
                        
                        <BorderContainer 
                            containerClassName="label"
                            minWidth={52}
                            leftRightWidth={25}
                            height={height/4}
                            leftImg={ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHICS_BOTTOM_CONTAINER.BUY_AMOUNT.HEADER.LEFT}
                            centerImg={ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHICS_BOTTOM_CONTAINER.BUY_AMOUNT.HEADER.CENTER}
                            rightImg={ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.GRAPHICS_BOTTOM_CONTAINER.BUY_AMOUNT.HEADER.RIGHT}>
                                {getTranslation("BUY_AMOUNT")}
                            </BorderContainer>
                        { children }

                    </BorderContainer>
        </div>
    );
};

export default BuyAmount;