import SocketService from '../services/socketService';
import { API } from '../common/constants/api';
import { getGameIdLocalStorage, getCurrentUser } from '../common/helpers/localStorage';
import { GAME } from '../common/constants/game/engine';
import Event from '../common/helpers/events';
import { getRefererUrl, getStringFromSuit } from '../common/helpers/common';
import axios from 'axios';
import { SETTINGS, ERROR, STORAGE_SETTINGS, THEME_ENUM, STORAGE } from '../common/constants/keys';
import { ROUTES } from '../common/constants/routing';
import { isInteger } from 'lodash';
import GameLib from '../common/helpers/game';
import { configsService } from '../services/configsService';

const { HOST_GAME_LAUNCHER, API_BASE, CLIENT } = API;

class Engine {
    static instance = null;
    static getInstance() {
        if (!Engine.instance) {
            Engine.instance = new Engine();
        }

        return Engine.instance;
    }
    data = {
        gameDisplayId: getGameIdLocalStorage(),
        isConnected: false,
        gameState: null,
        username: null,
        balance: null,
        bets: {},
        spectacles: {},
        nextBet: {
            amount: null,
            card: null,
            betDetails: [],
        },
        placingBet: false,
        placeBet: false,
        tick: {
            lag: false,
            lastTick: null,
            timer: null,
        },
        oldResult: [],
        maintenance: false
    };

    constructor() {
        var self = this;
        self.service = SocketService.getInstance();
    }

    init = () => {
        var self = this;
        self.service.init({
            joinedGame: this.joinedGame,
            serverMaintenanceReminder: this.serverMaintenanceReminder,
        }, (res) => {
            console.log("CONNECTED!", res);
            self.setGameData(GAME.GAME_CONNECTION.GAME_CONNECTED);
        });
    }

    ready = () => {
        var self = this;
        if (self.data.isConnected) {
            self.setGameData(GAME.GAME_CONNECTION.GAME_CONNECTED);
            return true;
        }
        return false;
    }

    start = () => {
        var self = this;
        self.data.gameDisplayId = getGameIdLocalStorage();

        const requestOtt = (ott, gameId, callback) => {
            axios.get( `${HOST_GAME_LAUNCHER}${API_BASE}${CLIENT.OTT}`, {
                params: {
                    token: encodeURIComponent(ott),
                    game: encodeURIComponent(gameId),
                    refererUrl: encodeURIComponent(getRefererUrl())
                }
            })
            .then(function (response) {
                callback(null, response);
            })
            .catch(function (error) {
                console.error("OTT ERROR", error);
                callback(error);
            });
        }
        requestOtt(getCurrentUser(), getGameIdLocalStorage(), (err, res) => {
            self.data.storage = configsService(res, err);
            if(self.data.storage){
                self.data.storage[STORAGE_SETTINGS.THEME_COLOR] = THEME_ENUM.BLUE;
                self.data.storage[STORAGE_SETTINGS.SOUND_ENABLE] = false;
            }

            if(res && res.data.isMaintenance){
                var msg = {
                    ...res,
                    type: ERROR.MAINTENANCE
                };
                self.trigger(GAME.ERROR_REDIRECT.SERVER_MAINTENANCE_NOW, msg);
            }

            self.trigger(GAME.CONFIG_DONE);
            if (err && err !== 401) {
                console.error(`Request ott error: ${err}`);
                window.location = ROUTES.NOT_FOUND;
                return;
            }
            self.service.joinGame({
                ott: res.data.ott,
                gameId: self.data.gameDisplayId,
                joinedEventHandler: this.joinedEventHandler,
                disconnectedEventHandler: this.disconnectedEventHandler,
                gameStarted: this.gameStarted,
                gameTick: this.gameTick,
                gameEnded: this.gameEnded,
                gameStarting: this.gameStarting,
                placeBet: this.placeBet,
                cashedOut: this.cashedOut,
                playerBet: this.playerBet,
                cashedOutApiReturn: this.cashedOutApiReturn,
                winPopup: this.winPopup,
                serverMaintenanceReminder: this.serverMaintenanceReminder,
                serverMaintenanceNow: this.serverMaintenanceNow,
                shutDown: this.shutDown,
                disconnect: this.disconnect,
                updateBalance: this.updateBalance,
            });
        });
    }

    joinedEventHandler = (res) => {
        console.log('joinedEventHandler', res);
    }

    joinedGame = (res) => {
        console.log('joinedGame', res);
        var self = this;
        var obj = res;
        self.trigger(GAME.UPDATE_THEME_COLOR, obj.themeColor);
        self.data.storage[STORAGE.COLOR_ODDS] = obj.oddsTypes.Color;
        self.data.storage[STORAGE.SUIT_ODDS] = obj.oddsTypes.Suit;

        obj.startTime = new Date(Date.now() - parseInt(res.elapsed));
        if (self.data.gameState === GAME.GAME_STATE.STARTED) {
            self.data.tick.lastTick = Date.now();
        }
        for (var user in obj.bets) {
            obj.bets[user].username = user;
        }
        self.setGameData(res.state, obj);
        self.trigger(GAME.GAME_JOINED, res.userBets);
    }

    disconnectedEventHandler = () => {
        console.error('disconnected');
    }

    gameTick = (res) => {
        var self = this;
        self.data.tick.lastTick = Date.now();
        if (self.data.tick.lag === true) {
            self.data.tick.lag = false;
        }
        self.setResult(res);
        if (self.data.tick.timer) {
            clearTimeout(self.data.tick.timer);
        }
        self.data.tick.timer = setTimeout(() => {
            console.log("LAG");
            self.data.tick.lag = true;
        }, SETTINGS.GAME.TOP_PREDICTING_LAPSE);
        self.setGameData(GAME.SOCKET.GAME_TICK, {
            currentResult: res
        });
    }

    setResult = (card) => {
        this.data.result[card.suit].count++;
        this.data.result[card.suit].card = card;
        this.data.oldResult.unshift(card);
    }

    gameEnded = (res) => {
        console.log('gameEnded', res);
        var self = this;
        self.data.tick.lastTick = Date.now();
        if (self.data.tick.timer) {
            clearTimeout(self.data.tick.timer);
        }
        var gameInfo = {
            sessionIndex: self.data.sessionIndex,
            result: res.currentResult,
            forced: res.forced,
            gameCreated: self.data.gameCreated,
            bets: self.data.bets,
            status: GAME.GAME_STATUS.COMPLETED,
            hash: res.hash
        }
        if (self.data.gameHistory.length >= SETTINGS.GAME.GAME_HISTORY_RESULT_LIMIT) {
            self.data.gameHistory.pop();
        }
        self.data.gameHistory.unshift(gameInfo);
        self.setResult(res.currentResult);
        self.data.tick.lag = false;
        self.setGameData(GAME.GAME_STATE.ENDED, res);
    }

    gameStarting = (res) => {
        console.log('gameStarting', res);
        var self = this;
        self.data.bets = {};
        self.data.joiners = {};
        self.data.spectacles = {};
        self.data.oldResult = [];
        self.data.currentResult = undefined;
        self.data.startTime =  new Date(Date.now() + self.data.timeTillStart);
        if (self.data.nextBet.amount) {
            // self.doBet(self.data.nextBet.amount, self.data.nextBet.card, (err) => {
            //     if (err) {
            //         console.error('Response from placing a bet: ', err);
            //     }
            // });

            self.newDoBet(self.data.nextBet.betDetails, (err) => {
                if (err) {
                    console.error('Response from placing a bet: ', err);
                }
            });
        }
        delete self.data.currentResult;
        self.setGameData(GAME.GAME_STATE.STARTING, res);
    }

    gameStarted = (bets) => {
        console.log('gameStarted', bets);
        var self = this;
        self.data.startTime = Date.now();
        self.data.joiners = {};
        self.data.spectacles = {};
        self.data.startTime = Date.now();
        self.data.tick.lastTick = self.data.startTime;
        self.data.placingBet = false;
        self.data.nextBet.amount = null;
        Object.keys(bets).forEach((username) => {
            self.data.bets[username] = bets[username];
            self.data.bets[username].username = username;
        });
        self.setGameData(GAME.GAME_STATE.STARTED);
    }

    placeBet = (res) => {
        console.log('placeBet', res);
        var self = this;
        if (res.errorMessage) {
            console.error('placeBet error: ', res.errorMessage);

            if (res.errorMessage === "INVALID_BET" || res.errorMessage === 'FAILED_IN_BUY_ACTION' || res.errorMessage === 'INVALID_BUY_AMOUNT' || res.errorMessage  === 'INVALID_SUIT' || res.errorMessage === 'INVALID_GAME_CURRENCY') {
                self.data.nextBet.amount = null;
                self.data.placingBet = false;
                self.trigger(GAME.CANCEL_BET);
                self.trigger(GAME.MESSAGE.BUY_ACTION_ERROR);
            } else if (res.errorMessage === 'INSUFFICIENT_FUND') {
                self.data.nextBet.amount = null;
                self.data.placingBet = false;
                self.data.balance = res.balance || res.Balance;
                self.data.insufficientBalance = true;
                self.trigger(GAME.MESSAGE.INSUFFICIENT_BALANCE);
            }
            return;
        }
        if ("result" in res) {
            self.data.balance = res.result;
            self.trigger(GAME.BET_CONFIRMED_MSG);
        }
        self.trigger(GAME.BET_PLACED);
    }

    cashedOut = (res) => {
        console.log('cashedOut', res);
        var self = this;
        if (!self.data.bets[res.username]) {
            return console.error('Username not found in bets at cashedOut', res.username);
        }
        self.data.bets[res.username].stoppedCard = res.stoppedCard;
        if (self.data.username === res.username) {
            self.data.balance += GameLib.calculateWonAmount(self.data.bets[res.username].bet, res.stoppedCard);
        }
        self.trigger(GAME.CASHED_OUT);
    }

    playerBet = (res) => {
        console.log('playerBet', res);
        var self = this;
        if (self.data.username === res.username) {
            self.data.placingBet = false;
            self.data.nextBet.amount = null;
        }
        self.data.joiners[res.username] = res.username;
        self.trigger(GAME.PLAYER_BET);
    }

    cashedOutApiReturn = (res) => {
        console.log('cashedOutApiReturn', res);
        var self = this;
        if (!self.data.bets[res.username]) {
            return console.error('Username not found in bets at cashedOut', res.username);
        }
        if (res.balance || res.Balance) {
            self.data.balance = res.balance || res.Balance;
        }
        self.data.bets[res.username].stoppedCard = res.stoppedCard;
        self.data.bets[res.username].profit = res.cashOut;
        self.trigger(GAME.CASHED_OUT);
    }

    winPopup = (res) => {
        console.log('winPopup', res);
        var self = this;
        self.trigger(GAME.WIN_POPUP);
    }

    serverMaintenanceReminder = (res) => {
        console.log('serverMaintenanceReminder', res);
        var self = this;
        var msg = {
            ...res,
            type: ERROR.MAINTENANCE
        };
        self.trigger(GAME.ERROR_REDIRECT.SERVER_MAINTENANCE_REMINDER, msg);
    }

    serverMaintenanceNow = (res) => {
        console.log('serverMaintenanceNow', res);
        var self = this;
        self.data.maintenance = true;
        var msg = {
            ...res,
            type: ERROR.MAINTENANCE
        };
        self.trigger(GAME.ERROR_REDIRECT.SERVER_MAINTENANCE_NOW, msg);
    }

    shutDown = (res) => {
        console.log('shutDown', res);
        var self = this;
        var msg = {
            ...res,
            type: ERROR.ERROR
        };
        self.trigger(GAME.ERROR_REDIRECT.SHUT_DOWN, msg);
    }

    disconnect = (res) => {
        console.log('disconnect', res);
        var self = this;
        if (!self.data.maintenance) {
            var msg = {
                ...res,
                type: ERROR.ERROR
            };
            self.trigger(GAME.ERROR_REDIRECT.DISCONNECT, msg);
            self.service.disconnect();
        }
    }

    bet = (amount, card) => {
        console.assert(typeof amount === 'number');
        console.assert(isInteger(amount));
        console.assert(!(GameLib.parseCard(card) instanceof Error));

        this.data.nextBet.amount = amount;
        this.data.nextBet.card = card;
        this.data.placingBet = true;

        if (this.data.gameState === GAME.GAME_STATE.STARTING) {
            return this.doBet(amount, card);
        }
        this.trigger(GAME.BET_QUEUED);
    }

    newBet = (amount, betDetails) => {
        console.assert(typeof amount === 'number');
        console.assert(isInteger(amount));

        this.data.nextBet.amount = amount;
        this.data.nextBet.betDetails = betDetails;
        this.data.placingBet = true;

        if (this.data.gameState === GAME.GAME_STATE.STARTING) {
            return this.newDoBet(betDetails);
        }
        this.trigger(GAME.BET_QUEUED);
    }

    
    newDoBet = (betDetails) => {
        // console.log("doBet", amount, getStringFromSuit(card))
        console.log("do Bet", betDetails);
        var self = this;
        self.service.newPlaceBet(self.data.gameDisplayId, betDetails);
        self.trigger(GAME.BET_PLACING);
    }

    doBet = (amount, card) => {
        console.log("doBet", amount, getStringFromSuit(card))
        var self = this;
        self.service.placeBet(self.data.gameDisplayId, amount, getStringFromSuit(card));
        self.trigger(GAME.BET_PLACING);
    }

    cancelBet = () => {
        console.log('cancelbet');
        if (!this.data.nextBet.amount) {
            return console.error('Can not cancel next bet');
        }
        this.data.nextBet.amount = null;
        this.data.placingBet = false;
        this.trigger(GAME.CANCEL_BET);
    }

    doSetting = (sound, themeColor) => {
        console.log("doSetting", sound, themeColor)
        var self = this;
        self.service.changeSetting(self.data.gameDisplayId, themeColor);
    }

    updateBalance = (res) => {
        console.log('updateBalance', res);
        var self = this;
        if ("balance" in res) {
            self.data.balance = res.balance;
        }
        self.trigger(GAME.UPDATE_BALANCE);
    }

    trigger = (type, data=null) => {
        Event.broadcast(type, data === null? this.data : data);
    }

    getGameData = () => {
        return this.data;
    }

    setGameData = (type, payload=null) => {
        switch (type) {
            case GAME.GAME_CONNECTION.GAME_CONNECTED:
            case GAME.GAME_CONNECTION.GAME_DISCONNECTED:
                this.data = {
                    ...this.data,
                    ...payload,
                    isConnected: true
                };
                break;
            case GAME.GAME_STATE.STARTING:
            case GAME.GAME_STATE.STARTED:
            case GAME.GAME_STATE.ENDED:
                this.data = {
                    ...this.data,
                    ...payload,
                    gameState: type,
                };
                break;
            case GAME.SOCKET.GAME_TICK:
                this.data = {
                    ...this.data,
                    ...payload
                };
                break;
            default:
                break;
        }
        Event.broadcast(type, this.data);
        return this.data;
    }
}

export default Engine;