import React, { useState, useEffect, useRef } from 'react';

import './Controls.less';
import { getTranslation } from '../../../../common/helpers/locale';
import { getRank, getResultTotalSize, isNumber } from '../../../../common/helpers/common';
import { setSuitLocalStorage, setBetSizeLocalStorage, getSuitLocalStorage } from '../../../../common/helpers/localStorage';
import { message} from 'antd';
import InputAddMinus from '../../../common/input-add-minus';
import { isMobileOrSmall, getSuitFromString, isDesktop } from '../../../../common/helpers/common';
import { BUY_AMOUNT_COIN_COLOR, THEME_ENUM, THEME_CLASS, SETTINGS, CARD, STORAGE, CAR_COLOR } from '../../../../common/constants/keys';
import { showInsufficientBalance, betSize, betCard, placeBuy, cancelBuy, setBetDetails, setLastBetDetails } from '../../../../common/actions/gameActions';
import { useStore } from '../../../../store/StateProvider';
import classNames from 'classnames';
import GameLib from '../../../../common/helpers/game';
import { getThemeGameControls, getThemeGameContainer } from '../../../../common/helpers/theme';
import InputAddMinusCarProgressBar from '../../../common/InputAddMinusCarProgressBar';
import { GAME } from '../../../../common/constants/game/engine';
import Event from '../../../../common/helpers/events';
import _ from 'lodash';
import DefaultBottomContainer from './DefaultBottomContainer';
import CarBottomContainer from './CarBottomContainer';
import CarDesktopBottomContainer from './CarDesktopBottomContainer';

const Controls = ({
    theme,
    themeColor
}) => {
    const [store, dispatch] = useStore();
    const { game } = store;
    const [show, setShow] = useState(false);
    const [selectCard, setSelectCard] = useState(getSuitLocalStorage() === undefined? '' : getSuitLocalStorage());
    const [buyAmount, setBuyAmount] = useState(0);
    const [selectOption, setSelectOption] = useState();
    const [selectChip, setSelectChip] = useState(BUY_AMOUNT_COIN_COLOR.BLUE);
    const [chips, setChips] = useState({});
    const [currencyDesc, setCurrencyDesc] = useState('');
    const [betInvalid, setBetInvalid] = useState(false);
    const [cardInvalid, setCardInvalid] = useState(false);
    const [panel, setPanel] = useState('');
    const [oldCardNumber, setOldCardNumber] = useState(SETTINGS.GAME.CARD_TOTAL)
    const [cardNumber, setCardNumber] = useState(SETTINGS.GAME.CARD_TOTAL);
    const [oldCard, setOldCard] = useState(false);
    const [card, setCard] = useState({ suit: "", rank: "" });
    const [cardAnimate, setCardAnimate] = useState(false);
    const [increment, setIncrement] = useState(1);
    const [minBuy, setMinBuy] = useState(1);
    const [maxBuy, setMaxBuy] = useState(10);
    const [quickBuyButton1, setQuickBuyButton1] = useState();
    const [quickBuyButton2, setQuickBuyButton2] = useState();
    const [quickBuyButton3, setQuickBuyButton3] = useState();
    const [quickBuyButton4, setQuickBuyButton4] = useState();
    const [quickBuyButton5, setQuickBuyButton5] = useState();
    const [suitOdds, setSuitOdds] = useState(3.8);
    const [colorOdds, setColorOdds] = useState(1.96);
    const [progress, setProgress] = useState(100);
    const [triggerState, setTriggerState] = useState('');
    const [disabled, setDisabled] = useState(false);
    const ref = useRef(null);
    const flipCardRef = useRef(null);
    const isInitialMount = useRef(true);    
    const [suitDisable, setSuitDisable] = useState(false);
    const [colorDisable, setColorDisable] = useState(false);
    const [cacheSelectedOption, setCacheSelectedOption] = useState([]);

    const on = (event, payload=null) => {
        switch (event) {
            case GAME.GAME_STATE.STARTING:
            case GAME.GAME_STATE.STARTED:
            case GAME.GAME_STATE.ENDED:
            case GAME.BET_PLACING:
            case GAME.BET_QUEUED:
            case GAME.PLAYER_BET:
            case GAME.CASHED_OUT:
            case GAME.CANCEL_BET:
            case GAME.BET_PLACED:
                setTriggerState(event);
                break;

            case GAME.BET_CONFIRMED_MSG:
                warningMsg("BET_CONFIRMED");
                break;
            default:
                break;
        }
    }
    Event.register("Controls", on);

    useEffect(() => {
        const themes = getThemeGameControls(theme, themeColor);
        const disable = GameLib.isBetting(game) || (game.gameState === GAME.GAME_STATE.STARTED);
        
        setDisabled(disable);
        // const playing = game.gameState === GAME.GAME_STATE.STARTING || ((game.gameState === GAME.GAME_STATE.STARTED || game.gameState === GAME.GAME_STATE.ENDED) && GameLib.currentPlay(game));

        if (theme === THEME_CLASS.DEFAULT) {
            setPanel(
                <DefaultBottomContainer 
                    theme={theme}
                    themeColor={themeColor}
                    changeCard={changeCard}
                    selectCard={selectCard}
                    progress={progress}
                    closeControls={closeControls}
                    _changeBuyAmount={_changeBuyAmount}
                    _setBuyAmount={_setBuyAmount}
                    buyAmount={buyAmount}
                    disabled={disabled}
                    increment={increment}
                    minBuy={minBuy}
                    maxBuy={maxBuy}
                    currencyDesc={currencyDesc}
                    quickBuyButton1={quickBuyButton1}
                    quickBuyButton2={quickBuyButton2}
                    quickBuyButton3={quickBuyButton3}
                    quickBuyButton4={quickBuyButton4}
                    setBuyAmount={setBuyAmount}
                    _placeBet={_placeBet}
                    _cancelBet={_cancelBet}
                    betInvalid={betInvalid}
                    cardInvalid={cardInvalid}
                    game={game}
                />   
            );
        } else if (theme === THEME_CLASS.CAR || theme === THEME_CLASS.OKBET) {
            if (!themeColor) return;
            const gameContainerThemes = getThemeGameContainer(theme, THEME_ENUM.DEFAULT);

                if(isDesktop())
                    setPanel(
                        <CarDesktopBottomContainer 
                            disabled={disabled}
                            gameContainerThemes={gameContainerThemes}
                            chips={chips}
                            handleBetOptionClick={handleBetOptionClick}
                            getChipValueByColor={getChipValueByColor}
                            selectOption={selectOption}
                            suitOdds={suitOdds}
                            colorOdds={colorOdds}
                            clearBets={clearBets}
                            selectChip={selectChip}
                            handleChipClick={handleChipClick}
                            game={game}
                            theme={theme}
                            themes={themes}
                            minBuy={minBuy}
                            maxBuy={maxBuy}
                            _newPlaceBet={_newPlaceBet}
                            _cancelBet={_cancelBet}
                            betInvalid={betInvalid}
                            handleRebetClick={handleRebetClick}
                            progress={progress}
                            currencyDesc={currencyDesc}
                            show={show}
                            flipCardRef={flipCardRef}
                            cardAnimate={cardAnimate}
                            oldCardNumber={oldCardNumber}
                            card={card}
                            themeColor={themeColor}
                            cardNumber={cardNumber}
                            getRank={getRank}
                            oldCard={oldCard}
                            suitDisable={suitDisable}
                            colorDisable={colorDisable}
                        />
                    )
                else
                    setPanel(
                        <CarBottomContainer 
                            progress={progress}
                            game={game}
                            handleRebetClick={handleRebetClick}
                            gameContainerThemes={gameContainerThemes}
                            flipCardRef={flipCardRef}
                            cardAnimate={cardAnimate}
                            oldCardNumber={oldCardNumber}
                            card={card}
                            theme={theme}
                            themeColor={themeColor}
                            getRank={getRank}
                            cardNumber={cardNumber}
                            oldCard={oldCard}
                            disabled={disabled}
                            chips={chips}
                            handleBetOptionClick={handleBetOptionClick}
                            getChipValueByColor={getChipValueByColor}
                            selectOption={selectOption}
                            colorOdds={colorOdds}
                            suitOdds={suitOdds}
                            handleChipClick={handleChipClick}
                            selectChip={selectChip}
                            minBuy={minBuy}
                            maxBuy={maxBuy}
                            themes={themes}
                            _newPlaceBet={_newPlaceBet}
                            _cancelBet={_cancelBet}
                            betInvalid={betInvalid}
                            currencyDesc={currencyDesc}
                            clearBets={clearBets}
                            suitDisable={suitDisable}
                            colorDisable={colorDisable}
                        />
                )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardAnimate, triggerState, disabled, game, progress, card, cardNumber, currencyDesc, increment, minBuy, maxBuy, selectCard, buyAmount, theme, themeColor]);

    useEffect(() => {
        ref.current = (
            <InputAddMinus
                isMobileOrSmall={isMobileOrSmall()}
                onButtonChange={_changeBuyAmount}
                onChange={_setBuyAmount}
                value={buyAmount}
                disabled={disabled}
                increment={increment}
                min={minBuy}
                max={maxBuy}
                minusBtnFirst={true}
                customInput={() => <InputAddMinusCarProgressBar
                    value={buyAmount}
                    themeColor={themeColor}
                    min={minBuy}
                    max={maxBuy}
                />}
                >
            </InputAddMinus>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyAmount, disabled]);

    useEffect(() => {
        if (!("storage" in game)) return;
        let { storage } = game;
        setIncrement(parseInt(storage.increment));
        setMinBuy(parseInt(storage.minBuy));
        setMaxBuy(parseInt(storage.maxBuy));
        setQuickBuyButton1(parseInt(storage.quickBuyButton1));
        setQuickBuyButton2(parseInt(storage.quickBuyButton2));
        setQuickBuyButton3(parseInt(storage.quickBuyButton3));
        setQuickBuyButton4(parseInt(storage.quickBuyButton4));
        setQuickBuyButton5(parseInt(storage.quickBuyButton5));
        setSuitOdds(storage.suitOdds);
        setColorOdds(storage.colorOdds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [game.storage]);

    useEffect(() => {
        if (isInitialMount.current) {
           isInitialMount.current = false;
        } else {
            // _setBuyAmount((betInvalid instanceof Error)? 0 : buyAmount, true);
            changeCard((cardInvalid instanceof Error)? '' : selectCard, true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minBuy, maxBuy, cardInvalid, betInvalid]);

    useEffect(() => {
        const handler = (e) => {
            setOldCardNumber(cardNumber);
            setOldCard(card);
            setCardAnimate(false);
            Event.broadcast(GAME.PLAY_SOUND, {
                name: "CARD_FLIP__" + getSuitFromString(card.suit),
                loop: false,
                cancel: "",
                simultaneous: 1
            });
        }
        if (flipCardRef && flipCardRef.current) {
            flipCardRef.current.addEventListener('transitionend', handler);
        }

        return () => {
            if (flipCardRef && flipCardRef.current) {
                flipCardRef.current.removeEventListener('transitionend', handler);
            }
        }
    })

    useEffect(() => {
        if (!("currentResult" in game) || !game.currentResult) return;
        const { currentResult } = game;
        if (card.rank === currentResult.rank && card.suit === currentResult.suit) {
            return;
        }
        let tCard = { suit: currentResult.suit, rank: currentResult.rank };
        setCard(tCard);
        setCardNumber(count => count - 1);
        if (flipCardRef && flipCardRef.current) {
            Event.broadcast(GAME.PLAY_SOUND, {
                name: "CARD_FLIP",
                loop: false,
                cancel: "",
                simultaneous: 1
            });
            setCardAnimate(true);
        }
        if (oldCard === false && getResultTotalSize(game.result) > Object.keys(CARD).length+1) {
            setOldCard(tCard);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [game.currentResult]);

    useEffect(() => {
        if (!("result" in game)) return;
        var count = getResultTotalSize(game.result);
        let cardNo = SETTINGS.GAME.CARD_TOTAL - count;
        setOldCardNumber(cardNo);
        setCardNumber(cardNo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [game.result]);

    useEffect(() => {
        if (!("gameState" in game)) return;
        if (game.gameState === GAME.GAME_STATE.STARTING) {
            let cardNo = SETTINGS.GAME.CARD_TOTAL - Object.keys(CARD).length;
            setCard({ suit: "", rank: "" });
            setOldCard(false);
            setOldCardNumber(cardNo);
            setCardNumber(cardNo);
            setCardAnimate(false);
            if ("startTime" in game && "timeTillStart" in game) {
                const progression = () => {
                    setTimeout(() => {
                        var prog = 100 - ((game.startTime - Date.now()) / game.timeTillStart * 100);
                        setProgress(prog);
                        if (prog <= 100) {
                            progression();
                        }
                    }, 16);
                }
                progression();
            }
        }

        if((game.gameState === GAME.GAME_STATE.ENDED) || (game.gameState === GAME.GAME_STATE.STARTED && !GameLib.currentPlay(game))){
            clearBets();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [game.gameState]);


    useEffect(()=>{
        if(quickBuyButton1 && quickBuyButton2 && quickBuyButton3 && quickBuyButton4 && quickBuyButton5)
            chipsGrouping();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[game.betDetail, quickBuyButton1, quickBuyButton2, quickBuyButton3, quickBuyButton4, quickBuyButton5]);

    const warningMsg = (msg) => {
        message.open({
            content: getTranslation(msg),
            className: "msg-container",
            icon: <></>,
            key: msg,
            style: {
                marginTop: '40vh',
            },
        });
    };

    const chipsGrouping = () => {
        var totalBet = 0;

        const chipCalculate = (amount, color, chipValue) => {
            var chipMap = chipColorMap.get(amount);
            if(chipMap){
                return {color: chipMap, chipCount: 1 ,leftOver: 0};
            }

            const chips = amount / chipValue;
            var leftOver = chips % 1;
            if(chips - leftOver > 0){
                return {color, chipCount: chips - leftOver , leftOver: Math.round(leftOver * chipValue)};
            }

            return {color, chipCount: 0, leftOver: Math.round(leftOver * chipValue)};
        }
        var summary = chips;
        _.map(game.betDetail, (detail) => {
            var chipsDetails = {};
            totalBet += detail.amount;
            var result = chipCalculate(detail.amount, 'red', quickBuyButton5);
            if(result.chipCount > 0){
                chipsDetails = { ...chipsDetails, [detail.betValue] : {...chipsDetails[detail.betValue], amount: detail.amount, [result.color]: result.chipCount} };
            }
            
            result = chipCalculate(result.leftOver, 'green', quickBuyButton4);
            if(result.chipCount > 0){
                chipsDetails = { ...chipsDetails, [detail.betValue] : {...chipsDetails[detail.betValue], amount: detail.amount, [result.color]: result.chipCount} };
            }
            
            result = chipCalculate(result.leftOver, 'purple', quickBuyButton3);
            if(result.chipCount > 0){
                chipsDetails = { ...chipsDetails, [detail.betValue] : {...chipsDetails[detail.betValue], amount: detail.amount, [result.color]: result.chipCount} };
            }
            
            result = chipCalculate(result.leftOver, 'orange', quickBuyButton2);
            if(result.chipCount > 0){
                chipsDetails = { ...chipsDetails, [detail.betValue] : {...chipsDetails[detail.betValue], amount: detail.amount, [result.color]: result.chipCount} };
            }

            result = chipCalculate(result.leftOver, 'blue', quickBuyButton1);
            if(result.chipCount > 0){
                chipsDetails = { ...chipsDetails, [detail.betValue] : {...chipsDetails[detail.betValue], amount: detail.amount, [result.color]: result.chipCount} };
            }
            
            summary = {...summary, ...chipsDetails};
        });

        setChips(summary);

        setBuyAmount(totalBet)
        betSize(dispatch, totalBet);
        
    }

    const clearBets = () => {
        setChips({});
        setSelectOption(null);
        setBetDetails(dispatch, []);
        betSize(dispatch, 0);

        if(!game.storage[STORAGE.MULTI_BET]) {
            setSuitDisable(false);
            setColorDisable(false);
        }
    }

    const checkSelectCard = () => {
        if (cardInvalid instanceof Error) return false;
        return true;
    }

    const _setBuyAmount = (amount, setInitialValue = false) => {
        if (disabled && !setInitialValue) return;
        amount = checkBuyAmount(amount);
        setBetSizeLocalStorage(amount);
        betSize(dispatch, amount);
        setBuyAmount(amount);
    }

    const _changeBuyAmount = (amount) => {
        if (disabled) return;
        amount = buyAmount + amount;
        _setBuyAmount(amount)
    };

    const checkBuyAmount = (amount, check=false) => {
        amount = parseInt(amount);
        console.assert(isNumber(amount));
        console.assert(!(betInvalid instanceof Error));
        let balance = parseFloat(game.balance);

        let max_buy = Math.min(balance, maxBuy);
        if (amount > max_buy) {
            if (game.username && amount > balance) {
                showInsufficientBalance(dispatch);
                if (check) return false;
            }
            amount = Math.floor(max_buy);
        }
        amount = (amount < minBuy) ? minBuy : amount;
        return parseInt(amount);
    };

    const changeCard = (card, start=false) => {
        setSuitLocalStorage(card);
        betCard(dispatch, card);
        setSelectCard(card);
        if (!start) {
            const track = "CHOOSING__"+card;
            Event.broadcast(GAME.PLAY_SOUND, {
                name: game.storage.lang === 'zh-CN' ? track + "_CN" : track,
                loop: false,
                cancel: "ALL",
                simultaneous: 1
            });
        }
    }

    const handleBetOptionClick = (option) => {
        if(disabled) return;

        const chipValue = getChipValueByColor(selectChip);
        const [validate, chipExist] = checkChipAmount(chipValue, option);

        if(validate){
            var detail;
            setSelectOption(option);

            if(chipExist){
                // use deep clone to prevent update on lastBetDetails
                const clone = _.cloneDeep(game.betDetail);
                _.find(clone, (x)=> { return x.betValue === option }).amount += chipValue;
                detail = clone;
            }
            else{
                detail = game.betDetail ? game.betDetail.concat({betValue: option, amount: chipValue}) : [{betValue: option, amount: chipValue}];
            }

            setBetDetails(dispatch, detail);
            setLastBetDetails(dispatch, []);
            setCacheSelectedOption(detail);
            chipsGrouping();
        }
    }

    const checkChipAmount = (chipValue, option) => {
        const chipExist = _.find(game.betDetail, (x)=> { return x.betValue === option });

        if(!game.storage[STORAGE.MULTI_BET]) {
            const checker = _.includes(CARD, option) ? CARD : CAR_COLOR;
            const invalid = _.some(game.betDetail, (x) => {
                return option !== x.betValue && _.includes(checker, x.betValue);
            });
            if(checker === CARD)
                setSuitDisable(true);
            else if(checker === CAR_COLOR)
                setColorDisable(true);

            if(invalid)
                return [false];
        }

        if(!option || game.gameState !== GAME.GAME_STATE.STARTING){
            return [false];
        }

        if(!checkIsInsufficientBalance(buyAmount + chipValue)){
            return [false];
        }

        if(game.buyAmount + chipValue > maxBuy){
            warningMsg("BET_LIMIT_EXCEEDED");
            return [false];
        }
        else if(chipValue > maxBuy){
            warningMsg("BET_LIMIT_EXCEEDED");
            return [false];
        }

        return [true, chipExist];
    }

    const checkIsInsufficientBalance = (buyAmount) => {
        let balance = parseFloat(game.balance);
        if(buyAmount > balance){
            showInsufficientBalance(dispatch);
            return false;
        }
        
        return true;
    }

    const handleChipClick = (chipColor) => {
        setSelectChip(chipColor.toLocaleUpperCase());
    }

    const _placeBet = () => {
        if (!(checkSelectCard() && checkBuyAmount(buyAmount, true))) return;
        if (betInvalid instanceof Error || cardInvalid instanceof Error) {
            return;
        }
        placeBuy(dispatch);
    };

    const _newPlaceBet = () => {
        const validate = _.map(game.betDetail,(detail) => {
            if(GameLib.parseCard(detail.betValue) instanceof Error){
                return false;
            }
            return true;
        });

        if (game.buyAmount < minBuy || game.buyAmount > maxBuy) {
            if(game.buyAmount < minBuy){
                warningMsg("MIN_BET_REQUIRED");
            }
            return;
        }
        
        if(!checkIsInsufficientBalance(buyAmount) || _.isEmpty(validate) || !_.every(validate, (data) => {
            return data === true;
            })){
            return; 
        }

        setLastBetDetails(dispatch, []);
        placeBuy(dispatch);
    }

    const _cancelBet = () => {
        cancelBuy(dispatch);
    }

    const closeControls = () => {
        setShow(false);
    }

    const handleRebetClick = () => {
        if(game.lastBetDetails?.length === undefined || game.lastBetDetails?.length === 0) {
            return;
        }

        if(!game.storage[STORAGE.MULTI_BET]){
            if(_.some(game.lastBetDetails, (x) => _.includes(CAR_COLOR, x.betValue))){
                setColorDisable(true);
            }
            
            if(_.some(game.lastBetDetails, (x) => _.includes(CARD, x.betValue))){
                setSuitDisable(true);
            }
        }

        setBetDetails(dispatch, game.lastBetDetails);
    }

    useEffect(() => {
        if (!store.settings) return;
        if ("panelEnable" in store.settings) {
            setShow(store.settings.panelEnable);
        }
    }, [store.settings]);

    useEffect(() => {
        if (!game || !("currency" in game.storage)) return;
        if (game.storage.currencyMultiplier > 1) {
            setCurrencyDesc(`100 = ${game.storage.currency} ${(100 * game.storage.currencyMultiplier).toLocaleString()}`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [game.storage.currency]);

    useEffect(()=>{
        if(game.gameState === GAME.GAME_STATE.STARTED)
            setLastBetDetails(dispatch, cacheSelectedOption);

    },[cacheSelectedOption, dispatch, game.gameState]);

    useEffect(() => {
        var betInvalid = GameLib.parseBet(buyAmount);
        var cardInvalid = GameLib.parseCard(selectCard);
        setBetInvalid(betInvalid);
        setCardInvalid(cardInvalid);
        changeCard((cardInvalid instanceof Error)? '' : selectCard, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyAmount, selectCard]);

    const getChipValueByColor = (color) => {
        switch(color) {
            case BUY_AMOUNT_COIN_COLOR.ORANGE:
                return quickBuyButton2;
            case BUY_AMOUNT_COIN_COLOR.PURPLE:
                return quickBuyButton3;
            case BUY_AMOUNT_COIN_COLOR.GREEN: 
                return quickBuyButton4;
            case BUY_AMOUNT_COIN_COLOR.RED:
                return quickBuyButton5;
            case BUY_AMOUNT_COIN_COLOR.BLUE:
            default:
                return quickBuyButton1;
        }
    }

    const chipColorMap = new Map([
        [quickBuyButton1, BUY_AMOUNT_COIN_COLOR.BLUE.toLocaleLowerCase()],
        [quickBuyButton2, BUY_AMOUNT_COIN_COLOR.ORANGE.toLocaleLowerCase()],
        [quickBuyButton3, BUY_AMOUNT_COIN_COLOR.PURPLE.toLocaleLowerCase()],
        [quickBuyButton4, BUY_AMOUNT_COIN_COLOR.GREEN.toLocaleLowerCase()],
        [quickBuyButton5, BUY_AMOUNT_COIN_COLOR.RED.toLocaleLowerCase()],
    ]);

    return (
        <div className={classNames(
            'controlsMain__container',
            show ? 'active' : ''
        )}>
        <div className='controls__container'>
            {panel}
        </div>
        </div>
    )
}

export default Controls;