import React from 'react'

import classNames from 'classnames'
import { Col, Row } from 'antd'

import BetOptions from './BetOptions'
import Chips from './Chips'
import { getTranslation } from '../../../../common/helpers/locale'
import BetButtonWithTotal from '../BetButtonWithTotal'
import ImgIcon from '../../../common/ImgIcon'
import Countdown from './Countdown'
import CardFlip from './CardFlip'

const CarDesktopBottomContainer = ({ 
    disabled,
    chips,
    gameContainerThemes,
    handleBetOptionClick,
    getChipValueByColor,
    selectOption,
    suitOdds,
    colorOdds,
    clearBets,
    selectChip,
    handleChipClick,
    game,
    theme,
    themes,
    minBuy,
    maxBuy,
    _newPlaceBet,
    _cancelBet,
    betInvalid,
    handleRebetClick,
    progress,
    currencyDesc,
    show,
    flipCardRef,
    cardAnimate,
    oldCardNumber,
    card,
    themeColor,
    cardNumber,
    getRank,
    oldCard,
    suitDisable,
    colorDisable
}) => {
    return (
        <>
            <div className='controlsTop__container'>
                {
                    progress >= 100 && (
                        <div className='aboveTop__container'>
                            <CardFlip 
                                flipCardRef={flipCardRef}
                                cardAnimate={cardAnimate}
                                gameContainerThemes={gameContainerThemes}
                                oldCardNumber={oldCardNumber}
                                card={card}
                                theme={theme}
                                themeColor={themeColor}
                                cardNumber={cardNumber}
                                getRank={getRank}
                                oldCard={oldCard}
                            />
                        </div>
                    )
                }
                <div className={
                    classNames(
                        'main',
                        disabled? 'disabled' : ''
                    )
                }>
                    <div className="bet-panel">
                        <BetOptions
                            gameContainerThemes={gameContainerThemes}
                            chips={chips}
                            handleBetOptionClick={handleBetOptionClick}
                            getChipValueByColor={getChipValueByColor}
                            selectOption={selectOption}
                            suitOdds={suitOdds}
                            colorOdds={colorOdds}
                            suitDisable={suitDisable}
                            colorDisable={colorDisable}
                        />
                        {
                            show && (
                                <>
                                    <div className={classNames("bet-chips__container")}>
                                        <Row justify="space-around" align="middle">
                                            <Col span={3}>
                                                <div className={classNames("bet-setting-btn", game.storage.lang, {
                                                        cancel: game.lastBetDetails?.length === undefined || game.lastBetDetails?.length === 0
                                                    })} onClick={handleRebetClick} >    
                                                        <ImgIcon
                                                            icon={game.lastBetDetails?.length === undefined || game.lastBetDetails?.length === 0 ? gameContainerThemes.REBET_CANCEL_ICON : gameContainerThemes.REBET_ICON}
                                                            height={35}    
                                                            width={35}
                                                        />
                                                        {getTranslation("REBET").toLocaleUpperCase()}
                                                </div>
                                            </Col>
                                            <Col className="bet-chips__chip-container" span={10}>
                                                <Chips
                                                    chips={gameContainerThemes.CHIPS} 
                                                    getChipValueByColor={getChipValueByColor} 
                                                    selectChip={selectChip}
                                                    handleChipClick={handleChipClick}
                                                />
                                            </Col>
                                            <Col className="bet-chips__clear-chip-container" onClick={disabled ? ()=>{} : clearBets} span={3}>
                                                {getTranslation("CLEAR_BETS").toLocaleUpperCase()}
                                            </Col>
                                            <Col span={7}>
                                                <BetButtonWithTotal
                                                    engine={game}
                                                    theme={theme}
                                                    min={minBuy}
                                                    max={maxBuy}
                                                    btnWidth={"90%"}
                                                    btnHeight={60}
                                                    btnBuy={themes.BUY_BUTTON}
                                                    btnBuying={themes.BUYING_BUTTON}
                                                    placeBet={_newPlaceBet}
                                                    cancelBet={_cancelBet}
                                                    buyAmount={game.buyAmount}
                                                    betInvalid={betInvalid}
                                                    cardInvalid={false}
                                                    betAmountInvalid={game.buyAmount < minBuy || game.buyAmount > maxBuy}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <span className="bet__currency-info">{currencyDesc}</span>
                                    <div className='countdown-container'>
                                        <Countdown progress={progress} game={game}/>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default CarDesktopBottomContainer
