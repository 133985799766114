import React from 'react';
import { THEME_CLASS } from '../../../common/constants/keys';
import { getTranslation } from '../../../common/helpers/locale';

const ServerMaintenanceReminder = ({
    theme,
    themeColor,
    start,
    end
}) => {
    return (
        <>
        { theme === THEME_CLASS.CAR? (
            <>
            <div>
                <h1>
                    {getTranslation("MAINTENANCE")}
                </h1>
                <div className='content'>
                    {start} - 
                    <br/>
                    {end} (UTC)
                </div>
            </div>
            </>
        ) : (
            <></>
        )}
        </>
    )
}

export default ServerMaintenanceReminder;