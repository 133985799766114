export const ROUTES = {
    NOT_FOUND: '/404',
    MAINTENANCE: '/maintenance',
    ERROR: '/error',
    LANDING: '/',
    LOGIN: '/login',
    GAME: {
        ROOT: '/card',
        HOME: '/',
        RESULTS: '/results',
        TRANSACTIONS: '/transactions',
        HELP: '/help',
    },
    CLIENT: {
        AUTH: '/client/auth',
        LAUNCH:'/launch'
    },
    RESULT: '/GetGameResult',
};