import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { GAME } from '../../../common/constants/game/engine';
import { SETTINGS } from '../../../common/constants/keys';
import { getTranslation } from '../../../common/helpers/locale';
import GameLib from '../../../common/helpers/game';
import Button from '../../common/Button';
import classNames from 'classnames';
import './BetButtonWithTotal.less';
import ImgIcon from '../../common/ImgIcon';
import { ICONS } from '../../../common/constants/iconWrapper';

const BetButtonWithTotal = ({
    engine,
    theme,
    min,
    max,
    btnWidth,
    btnHeight,
    btnBuy,
    btnBuying,
    placeBet,
    cancelBet,
    buyAmount,
    betInvalid,
    cardInvalid,
    betAmountInvalid,
    ...rest
}) => {
    const [initialDisable, setInitialDisable] = useState(false);
    const [timeOut, setTimeOut] = useState(null);
    const [placeBetButton, setPlaceBetButton] = useState(null);

    const _onEngineChange = () => {
        if (engine.gameState === GAME.GAME_STATE.ENDED) {
            const onGameCrash = () => {
                setInitialDisable(true);
                _initialDisableTimeout();
            }
            onGameCrash();
        } else if (engine.gameState === GAME.GAME_STATE.STARTING) {
            setInitialDisable(false);
        }
    }

    const _initialDisableTimeout = () => {
        setTimeOut(setTimeout(() => {
            setInitialDisable(false);
        }, SETTINGS.GAME.BET_BUTTON.INITIAL_DISABLE_TIME));
    }

    const totalBet = () => {
        return (
            <>
                <ImgIcon 
                    icon={ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.COIN_ICON} 
                    alt="" 
                    width="30px" 
                    height="30px"
                    containerStyle={{margin: "0px 5px 0px"}}
                    />
                {buyAmount}
            </>
        )
    }
    
    const renderBetButton = () => {
        var _notPlaying = GameLib.notPlaying(engine);
        var _isBetting = GameLib.isBetting(engine);
        var notPlayingOrBetting = _notPlaying || _isBetting;
        var invalidBet = GameLib.canUserBet(betInvalid, cardInvalid) instanceof Error;
        // var invalidBetAmount = _.isEmpty(betAmountInvalid) || !_.every(betAmountInvalid, (data) => {
        //     return data === true;
        //     })

        var btnContent = [], btnClasses = [], onClickFunc = null;
        if (notPlayingOrBetting) {
            if(buyAmount > 0)
                btnClasses.push("total-buy");

            if (_isBetting) {
                btnContent.push(
                    <div key={`betting`} className={classNames("betting-btn", engine.storage.lang , {'buy-with-total' :  buyAmount  > 0 })}>
                        {getTranslation("BETTING")}
                        {
                            buyAmount > 0 ? totalBet() : null
                        } 
                    </div>
                );
                btnClasses.push('disable','buying');
            } else if (initialDisable) {
                btnContent.push(
                    <div key={`initial_disable`} className={classNames(`bc-2`, "disabled" ,{
                        "buy-with-total": buyAmount  > 0
                    })}>
                        {getTranslation("BET NOW")} 
                        {
                            buyAmount > 0 ? totalBet() : null
                        }  
                    </div>
                );
                btnClasses.push('disable');
            } else if (_notPlaying) {
                if (invalidBet) {
                    btnContent.push(
                        <div key={`not_playing`} className={classNames(`bc-3`, {
                            "buy-with-total": buyAmount  > 0
                        })}>
                            {getTranslation("BET NOW")}
                            {
                                buyAmount > 0 ? totalBet() : null
                            } 
                        </div>
                    );
                    btnClasses.push('disable');
                } else if (engine.placingBet) {
                    btnContent.push(
                        <div key={`placing_bet`} className={classNames(`bc-4`, {
                            "buy-with-total": buyAmount  > 0
                        })}>
                            {getTranslation("BET NOW")}
                            {
                                buyAmount > 0 ? totalBet() : null
                            } 
                        </div>
                    );
                    btnClasses.push('disable');
                } else {
                    btnContent.push(
                        <div key={`buy_with_total`}
                        className={classNames(`bc-5`, {
                            "buy-with-total": buyAmount  > 0
                        })}>
                            {getTranslation("BET NOW")}
                            {
                                buyAmount > 0 ? totalBet() : null
                            }
                        </div>
                    );
                    onClickFunc = placeBet;
                }
            }
        }
        setBetButton(btnClasses, onClickFunc, btnContent);
    }

    const setBetButton = (btnClasses, onClickFunc, btnContent) => {
        let btn = (btnClasses.includes("buying"))? btnBuying : btnBuy;
        setPlaceBetButton(
            <Button
                icon={btn}
                containerClassName={classNames(
                    'placeBet__container-with-total',
                    btnClasses,
                    engine.storage.lang
                )}
                onClick={onClickFunc}
                width={btnWidth}
                height={btnHeight}
                {...rest}
                >
                    {btnContent}
                    <span className="min-max__bet-info">{`${getTranslation("MIN_BET")}: ${min}`} &nbsp;&nbsp; {`${getTranslation("MAX_BET")}: ${max}`}</span>
                </Button>);
    }

    useEffect(() => {
        _onEngineChange();
        renderBetButton();

        return () => {
            clearTimeout(timeOut);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [betInvalid, cardInvalid, engine, theme, btnBuy]);

    return (placeBetButton && placeBetButton)
}

BetButtonWithTotal.prototype = {
    engine: PropTypes.object.isRequired,
    theme: PropTypes.string.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    btnWidth: PropTypes.number.isRequired,
    btnHeight: PropTypes.number.isRequired,
    btnBuy: PropTypes.string.isRequired,
    btnBuying: PropTypes.string.isRequired,
    placeBet: PropTypes.func.isRequired,
    cancelBet: PropTypes.func.isRequired,
    buyAmount: PropTypes.number,
    betInvalid: PropTypes.any.isRequired,
    cardInvalid: PropTypes.any.isRequired,
    betAmountInvalid: PropTypes.any.isRequired,
};


export default BetButtonWithTotal
