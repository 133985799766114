import { HubConnectionBuilder } from '@aspnet/signalr';
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import { API } from '../common/constants/api';
import { MAINTENANCE_STATUS } from '../common/constants/misc';
import { ROUTES } from '../common/constants/routing';

const { HUB, HOST } = API;

class SocketService {
    static instance = null;
    data = {
        connection: null,
        connected: false,
        connecting: false,
        disconnectedCallbacks: [],
    };

    static getInstance() {
        if (!SocketService.instance) {
            SocketService.instance = new SocketService();
        }

        return SocketService.instance;
    }

    formatGuid = (gameId) => gameId.replace(/\s+/g, '-').toLowerCase();

    init = ({ 
        reconnect,
        joinedGame,
        placedBet,
        serverMaintenanceReminder
    }, callback) => {
        if (this.data.connecting) {
            // setTimeout(() => this.init({ reconnect }), 500);
        } else if (!this.data.connected || reconnect) {
            const connection = new HubConnectionBuilder()
                .withUrl(HOST + HUB.CONNECTION)
                .withHubProtocol(new MessagePackHubProtocol())
                .build();

            this.data.connection = connection;
            this.data.connecting = true;

            this.data.connection
                .start()
                .then(() => {
                    this.data.connected = true;
                    this.data.connecting = false;
                    if (callback) {
                        callback(true);
                    }
                    this.data.connection.on('joinedGame', (res) => {
                        if (joinedGame) {
                            joinedGame(res);
                        }
                    });
                    this.data.connection.on('placedBet', (res) => {
                        if (placedBet) {
                            placedBet(res);
                        }
                    });
                    this.data.connection.on('serverMaintenanceReminder', (res) => {
                        if (serverMaintenanceReminder) {
                            serverMaintenanceReminder(res);
                        }
                    });
                })
                .catch((err) => {
                    console.error('Error while establishing connection :(', err);
                    this.data.connecting = false;
                    if (err.statusCode === MAINTENANCE_STATUS)
                        window.location = ROUTES.MAINTENANCE;
                });

            this.data.connection.onclose(() => {
                this.data.connected = false;

                this.data.disconnectedCallbacks.forEach(function (callBack) {
                    callBack();
                });
            });
        }
    };

    joinGame = ({
        ott,
        gameId,
        joinedEventHandler,
        disconnectedEventHandler,
        gameStarted,
        gameTick,
        gameEnded,
        gameStarting,
        placeBet,
        cashedOut,
        playerBet,
        cashedOutApiReturn,
        winPopup,
        serverMaintenanceReminder,
        serverMaintenanceNow,
        shutDown,
        disconnect,
        updateBalance,
    }) => {
        this.initializeEvent(() => {
            this.data.connection
                .invoke('joinGame', ott, gameId)
                .then((res) => {
                    if (joinedEventHandler) {
                        joinedEventHandler(true);
                    }

                    if (disconnectedEventHandler) {
                        this.data.disconnectedCallbacks.push(disconnectedEventHandler);
                    }

                    // const lowerCasegameId = this.formatGuid(gameId);

                    this.data.connection.on('gameStarted', (res) => {
                        if (gameStarted) {
                            gameStarted(res);
                        }
                    });

                    this.data.connection.on('gameTick', (res) => {
                        if (gameTick) {
                            gameTick(res);
                        }
                    });

                    this.data.connection.on('gameEnded', (res) => {
                        if (gameEnded) {
                            gameEnded(res);
                        }
                    });

                    this.data.connection.on('gameStarting', (res) => {
                        if (gameStarting) {
                            gameStarting(res);
                        }
                    });

                    this.data.connection.on('placeBet', (res) => {
                        if (placeBet) {
                            placeBet(res);
                        }
                    });

                    this.data.connection.on('cashedOut', (res) => {
                        if (cashedOut) {
                            cashedOut(res);
                        }
                    });

                    this.data.connection.on('playerBet', (res) => {
                        if (playerBet) {
                            playerBet(res);
                        }
                    });

                    this.data.connection.on('cashedOutApiReturn', (res) => {
                        if (cashedOutApiReturn) {
                            cashedOutApiReturn(res);
                        }
                    });

                    this.data.connection.on('winPopup', (res) => {
                        if (winPopup) {
                            winPopup(res);
                        }
                    });

                    this.data.connection.on('serverMaintenanceReminder', (res) => {
                        if (serverMaintenanceReminder) {
                            serverMaintenanceReminder(res);
                        }
                    });

                    this.data.connection.on('serverMaintenanceNow', (res) => {
                        if (serverMaintenanceNow) {
                            serverMaintenanceNow(res);
                        }
                    });

                    this.data.connection.on('shutDown', (res) => {
                        if (shutDown) {
                            shutDown(res);
                        }
                    });

                    this.data.connection.on('disconnect', (res) => {
                        if (disconnect) {
                            disconnect(res);
                        }
                    });

                    this.data.connection.on('balance', (res) => {
                        if (updateBalance) {
                            updateBalance(res);
                        }
                    })
                })
                .catch((err) => console.error('Error while joining game :(', err));
        }, () => this.joinGame({
            ott,
            gameId,
            joinedEventHandler,
            disconnectedEventHandler,
            gameStarted,
            gameTick,
            gameEnded,
            gameStarting,
            placeBet,
            cashedOut,
            playerBet,
            cashedOutApiReturn,
            winPopup,
            serverMaintenanceReminder,
            serverMaintenanceNow,
            shutDown,
            disconnect
        }));
    };

    disconnect = () => {
        this.initializeEvent(() => {
            this.data.connection
                .stop()
                .then(() => {
                    this.data.connection.off('joinedGame');
                    this.data.connection.off('gameStarted');
                    this.data.connection.off('gameTick');
                    this.data.connection.off('gameEnded');
                    this.data.connection.off('gameStarting');
                    this.data.connection.off('placeBet');
                    this.data.connection.off('cashedOut');
                    this.data.connection.off('playerBet');
                })
                .catch((err) => console.error('Error while sending message', err))
        }, () => this.disconnect());
    };

    placeBet = (
        gameId,
        amount, 
        card,
    ) => {
        this.initializeEvent(() => {
            this.data.connection
                .invoke('buy', gameId, amount, card)
                .catch((err) => {
                    console.error('Error while placing bet', err);
                })
        }, () => this.placeBet(gameId, amount, card));
    };

    newPlaceBet = (
        gameId,
        betDetails
    ) => {
        this.initializeEvent(() => {
            this.data.connection
                .invoke('buy', gameId, betDetails)
                .catch((err) => {
                    console.error('Error while placing bet', err);
                })
        }, () => { this.newPlaceBet(gameId, betDetails)} );
    };

    changeSetting = (
        gameId,
        themeColor
    ) => {
        this.initializeEvent(() => {
            this.data.connection
                .invoke('changeSetting', gameId, themeColor)
                .catch((err) => {
                    console.error('Error while changing setting', err);
                })
        }, () => this.changeSetting(gameId, themeColor));
    };

    maintenanceNotification = (receiveMaintenanceEventHandler = null) => {
        this.initializeEvent(() => {
            this.data.connection.off('maintenanceNotification');
            this.data.connection.on('maintenanceNotification', (res) => {
                if (receiveMaintenanceEventHandler) {
                    receiveMaintenanceEventHandler(res);
                } else {
                    window.location = ROUTES.MAINTENANCE;
                }
            })
        }, () => {
            this.maintenanceNotification(receiveMaintenanceEventHandler)
        });
    };

    shutDownNotification = () => {
        this.initializeEvent(() => {
            this.data.connection.off('shutDown');
            this.data.connection.on('shutDown', () => {
                this.data.connection.stop().then(() => {
                    window.location = ROUTES.MAINTENANCE;
                });
            });
        }, () => this.shutDownNotification());
    }

    initializeEvent = (initializeHandle, retryHandle) => {
        if (this.data.connected) {
            initializeHandle();
        } else if (this.data.connecting) {
            if (retryHandle) {
                // setTimeout(() => retryHandle(), 500);
            }
        } else {
            console.error('Connection not initialized');
        }
    }
}

export default SocketService;