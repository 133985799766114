import { setUserInfo, deleteFromLocalStorage, setGameIdLocalStorage } from '../common/helpers/localStorage';
import { STORAGE, STORAGE_SETTINGS } from '../common/constants/keys';
import { getTheme } from '../common/helpers/common';
import { DEFAULT } from '../common/constants/misc';

export function configsService(response, error) {
    var storage = {};
    deleteFromLocalStorage(STORAGE.CURRENCY);
    deleteFromLocalStorage(STORAGE.MINBUY);
    deleteFromLocalStorage(STORAGE.MAXBUY);
    deleteFromLocalStorage(STORAGE.INCREMENT);
    deleteFromLocalStorage(STORAGE.QUICKBUYBUTTON1);
    deleteFromLocalStorage(STORAGE.QUICKBUYBUTTON2);
    deleteFromLocalStorage(STORAGE.QUICKBUYBUTTON3);
    deleteFromLocalStorage(STORAGE.QUICKBUYBUTTON4);
    deleteFromLocalStorage(STORAGE_SETTINGS.THEME_COLOR);
    deleteFromLocalStorage(STORAGE_SETTINGS.SOUND_ENABLE);
    deleteFromLocalStorage(STORAGE.USER);
    deleteFromLocalStorage(STORAGE.BACKURL);
    deleteFromLocalStorage(STORAGE.THEME);
    deleteFromLocalStorage(STORAGE.ANONYMOUS);
    deleteFromLocalStorage(STORAGE.GAMEID);
    deleteFromLocalStorage(STORAGE.CURRENCY_MULTIPLIER);

    if (error) {
        if (error.data) {
            letStorage(storage, STORAGE.BACKURL, error.data.backUrl);
            if (error.data.result) {
                letStorage(storage, STORAGE.THEME, error.data.result.theme ?? DEFAULT.THEME);
                letStorage(storage, STORAGE.LANGUAGE, error.data.result.language);
            }
        }
        return;
    }

    if (response) {
        if (response.data.isBan || response.data.isDisconnect) {
            letStorage(storage, STORAGE.ACCOUNT_LOCKED, "true");
        }
        if (!response.data.isAnonymous && !response.data.isMaintenance && !response.data.isBan && !response.data.isDisconnect) {
            letStorage(storage, STORAGE.CURRENCY, response.data.result.currency);
            letStorage(storage, STORAGE.MINBUY, response.data.result.minBuy);
            letStorage(storage, STORAGE.MAXBUY, response.data.result.maxBuy);
            letStorage(storage, STORAGE.INCREMENT, response.data.result.increment);
            letStorage(storage, STORAGE.QUICKBUYBUTTON1, response.data.result.quickBuyButton1);
            letStorage(storage, STORAGE.QUICKBUYBUTTON2, response.data.result.quickBuyButton2);
            letStorage(storage, STORAGE.QUICKBUYBUTTON3, response.data.result.quickBuyButton3);
            letStorage(storage, STORAGE.QUICKBUYBUTTON4, response.data.result.quickBuyButton4);
            letStorage(storage, STORAGE.QUICKBUYBUTTON5, response.data.result.quickBuyButton5);
            letStorage(storage, STORAGE_SETTINGS.SOUND_ENABLE, response.data.result.soundEnable);
            letStorage(storage, STORAGE_SETTINGS.THEME_COLOR, response.data.result.themeColor);
            letStorage(storage, STORAGE.USER, response.data.result.sessionId);
            letStorage(storage, STORAGE.GAMEID, response.data.result.gameId);
            letStorage(storage, STORAGE.CURRENCY_MULTIPLIER, response.data.result.currencyMultiplier);
            letStorage(storage, STORAGE.MULTI_BET, response.data.result.multiBet);
        }
        if (response.data.isAnonymous) {
            letStorage(storage, STORAGE.USER, response.data.result.sessionId);
            letStorage(storage, STORAGE.ANONYMOUS, true);
            letStorage(storage, STORAGE.CURRENCY, response.data.result.currency);
            letStorage(storage, STORAGE.MINBUY, response.data.result.minBuy);
            letStorage(storage, STORAGE.MAXBUY, response.data.result.maxBuy);
            letStorage(storage, STORAGE.INCREMENT, response.data.result.increment);
            letStorage(storage, STORAGE.QUICKBUYBUTTON1, response.data.result.quickBuyButton1);
            letStorage(storage, STORAGE.QUICKBUYBUTTON2, response.data.result.quickBuyButton2);
            letStorage(storage, STORAGE.QUICKBUYBUTTON3, response.data.result.quickBuyButton3);
            letStorage(storage, STORAGE.QUICKBUYBUTTON4, response.data.result.quickBuyButton4);
            letStorage(storage, STORAGE.QUICKBUYBUTTON5, response.data.result.quickBuyButton5);
            letStorage(storage, STORAGE_SETTINGS.SOUND_ENABLE, response.data.result.soundEnable);
            letStorage(storage, STORAGE_SETTINGS.THEME_COLOR, response.data.result.themeColor);
            letStorage(storage, STORAGE.GAMEID, response.data.result.gameId);
            letStorage(storage, STORAGE.CURRENCY_MULTIPLIER, response.data.result.currencyMultiplier);
            letStorage(storage, STORAGE.MULTI_BET, response.data.result.multiBet);
        }
        letStorage(storage, STORAGE.THEME, response.data.result.theme);
        letStorage(storage, STORAGE.LANGUAGE, response.data.result.language);
        letStorage(storage, STORAGE.BACKURL, response.data.backUrl);
    }

    return storage;
}

const letStorage = (storage, key, value) => {
    storage[key] = value;
    switch (key) {
        case STORAGE.USER:
            setUserInfo(value);
            break;
        case STORAGE.GAMEID:
            setGameIdLocalStorage(value);
            break;
        case STORAGE.THEME:
            storage[key] = getTheme(value);
            break;
        case STORAGE.CURRENCY_MULTIPLIER:
        case STORAGE.BACKURL:
        case STORAGE.CURRENCY:
        case STORAGE.LANGUAGE:
        case STORAGE.ANONYMOUS:
        case STORAGE.MINBUY:
        case STORAGE.MAXBUY:
        case STORAGE.INCREMENT:
        case STORAGE.QUICKBUYBUTTON1:
        case STORAGE.QUICKBUYBUTTON2:
        case STORAGE.QUICKBUYBUTTON3:
        case STORAGE.QUICKBUYBUTTON4:
        case STORAGE_SETTINGS.THEME_COLOR:
        case STORAGE_SETTINGS.SOUND_ENABLE:
            break;
        default:
            break;
    }
}