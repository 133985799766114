import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import classNames from 'classnames';
import './ImgIcon.less';
import { useDispatch } from '../../store/StateProvider';
import { setImageLoad } from '../../common/actions/gameActions';

const ImgIcon = ({
    size,
    height,
    width,
    icon,
    alt,
    onClick,
    children,
    containerClassName,
    textCenter=false,
    containerStyle,
    ...rest
}) => {
    const dispatch = useDispatch();
    const styles = {
        cursor: onClick ? 'pointer' : 'cursor',
        height: size ? size : height,
        width: size ? size : width,
    };

    useEffect(() => {
            loadingImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadingImage = () => {
        setImageLoad(dispatch, 1);
    }

    const loadedImage = () => {
        setImageLoad(dispatch, -1);
    }

    return (
        <div className={classNames(
            'img-icon',
            containerClassName,
            textCenter ? 'content-center' : '')}
            style={containerStyle}>
              <img onLoad={loadedImage} style={styles} src={icon.hasOwnProperty('default') ? icon.default : icon} alt={alt} {...rest} onClick={onClick} />
            {children}
        </div>
    );
};

ImgIcon.protoTypes = {
    alt: PropTypes.string,
    containerClassName: PropTypes.string,
    textCenter: PropTypes.bool,
    height: PropTypes.number,
    icon: PropTypes.string.isRequired,
    lazy: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.number,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ImgIcon;