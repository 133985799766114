import classNames from 'classnames'
import React from 'react'
import { ICONS } from '../../../common/constants/iconWrapper'
import ImgIcon from '../../common/ImgIcon'
import SimpleModal from '../../common/SimpleModal'
import Transactions from '../../transactions/Transactions'

const HistoryModal = ({ visible, theme, themeColor, engine, lang, onClose }) => {
    return (
        <SimpleModal
            containerClassName={
                classNames(
                    "gameSetting__container",
                    "history-modal",
                    visible ? 'active' : ''
                )}
            title="HISTORY"
        >
            <Transactions 
                theme={theme} 
                themeColor={themeColor}
                engine={engine}
                lang={lang}
                isModal={true}
            />
        <div className='buttons__container'>
            <ImgIcon
                onClick={onClose}
                icon={ICONS.THEME.CAR.GAME.SETTING.CLOSE}
                alt="close"
                />
        </div>
        </SimpleModal>
    )
}

export default HistoryModal
