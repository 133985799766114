import React from 'react'

import classNames from 'classnames'

import SimpleModal from '../../common/SimpleModal'
import ImgIcon from '../../common/ImgIcon'
import { ICONS } from '../../../common/constants/iconWrapper'
import Help from '../../help/Help'

const HelpModal = ({ visible, theme, themeColor, engine, onClose }) => {
    return (
        <SimpleModal
            containerClassName={
                classNames(
                    "gameSetting__container",
                    "help-modal",
                    visible ? 'active' : ''
                )}
            title="HELP"
        >
            <Help 
                theme={theme}
                themeColor={themeColor}
                engine={engine}
                isModal={true}
            />
            <div className='buttons__container'>
                <ImgIcon
                    onClick={onClose}
                    icon={ICONS.THEME.CAR.GAME.SETTING.CLOSE}
                    alt="close"
                    />
            </div>
        </SimpleModal>
    )
}

export default HelpModal
