import React from 'react'

import { Row } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';

import ImgIcon from '../../../common/ImgIcon';
import { isMobileOrSmall } from '../../../../common/helpers/common';



const Chips = ({ chips, getChipValueByColor, selectChip, handleChipClick }) => {
    return (
        <Row justify="space-between" align="middle">
            {   
                _.map(chips, (chip, color)=>{
                    var chipValue = getChipValueByColor(color);
                    
                    return <ImgIcon
                                key={color}
                                icon={chip} 
                                containerClassName={classNames(`bet-chips__chip-img-container ${color.toLocaleLowerCase()}`, {
                                    "active" : selectChip === color
                                })} 
                                width={isMobileOrSmall() ? 40 : 50} 
                                height={isMobileOrSmall() ? 40 : 50} 
                                children={
                                    // Onclick function prevent player click on the amount, and cause click not working
                                    <span className="bet-chips__amount" onClick={()=>{handleChipClick(color)}}>
                                        {chipValue}
                                    </span>
                                }
                                onClick={()=>{handleChipClick(color)}} />
                })
            }
        </Row>
    )
}

export default Chips
