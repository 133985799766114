export const ERROR_REDIRECT_STATUSES = [401, 403, 404, 451, 502, 503, 504];
export const MAINTENANCE_STATUS = 900;

export const errorStatusesMap = new Map([
    [
        '400',
        {
            // image: ICONS.ERROR.ERROR_4XX,
            text: '400 error text',
            title: 'Bad request',
        },
    ],
    [
        '401',
        {
            // image: ICONS.ERROR.ERROR_4XX,
            text: '401 error text',
            title: 'Unauthorized',
        },
    ],
    [
        '403',
        {
            // image: ICONS.ERROR.ERROR_4XX,
            text: '403 error text',
            title: 'Forbidden',
        },
    ],
    [
        '404',
        {
            // image: ICONS.ERROR.ERROR_4XX,
            text: '404 error text',
            title: 'Page not found',
        },
    ],
    [
        '451',
        {
            // image: ICONS.ERROR.ERROR_4XX,
            text: 'Blocked country text',
            title: 'Sorry, your IP is prohibited',
        },
    ],
    [
        '500',
        {
            // image: ICONS.ERROR.ERROR_5XX,
            text: '500 error text',
            title: 'Internal server error',
        },
    ],
    [
        '502',
        {
            // image: ICONS.ERROR.ERROR_5XX,
            text: '502 error text',
            title: 'Bad gateway',
        },
    ],
    [
        '503',
        {
            // image: ICONS.ERROR.ERROR_5XX,
            text: '503 error text',
            title: 'Service unavailable',
        },
    ],
    [
        '504',
        {
            // image: ICONS.ERROR.ERROR_5XX,
            text: '504 error text',
            title: 'Gateway timeout',
        },
    ],
]);

export const HTTP_HEADER = {
    CHINA_IDENTIFIER: 'WANGSU-REQ-ID'
}

export const DEFAULT = {
    THEME: 'car'
}

export const POPUP_WIDTH = {
    WINNING: {
        desktop: 485,
        mobile: 295
    },
    INSUFFICIENT_BALANCE: {
        desktop: 591,
        mobile: 291
    },
    ACCOUNT_LOCKED: {
        desktop: 591,
        mobile: 291
    },
    BUY_ERROR: {
        desktop: 591,
        mobile: 291
    },
    MAINTENANCE: {
        desktop: 591,
        mobile: 291
    }
}

export const TRAFFIC_LIGHT_SIZE = {
    WIDTH: {
        desktop: 380,
        mobile: 253
    },
    HEIGHT: {
        desktop: 164,
        mobile: 109
    }
}
