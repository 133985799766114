import { ACTIONS } from '../constants/actions';

/**
 * Verify third party success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const verifyThirdPartySuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.VERIFY_THIRD_PARTY_USER_SUCCESS,
    });

export const verifyThirdPartyFailure = (dispatch, payload) => dispatch({
    payload,
    type: ACTIONS.VERIFY_THIRD_PARTY_USER_FAILURE
})

export const setPageUrl = (dispatch, payload) => 
    dispatch({
        payload,
        type: ACTIONS.PAGE_URL
    });

export const setErrorRedirect = (dispatch, payload) => 
    dispatch({
        payload,
        type: ACTIONS.ERROR_REDIRECT_STATUSES
    });