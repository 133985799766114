import React from 'react'

import { Progress } from 'antd'
import classNames from 'classnames'

import { getTranslation } from '../../../../common/helpers/locale'
import CardSelect from '../CardSelect'
import ImgIcon from '../../../common/ImgIcon'
import BuyAmount from '../BuyAmount'
import InputAddMinus from '../../../common/input-add-minus'
import { isMobileOrSmall } from '../../../../common/helpers/common'
import BuyAmountCoin from '../BuyAmountCoin'
import { BUY_AMOUNT_COIN_COLOR } from '../../../../common/constants/keys'
import { ICONS } from '../../../../common/constants/iconWrapper'
import BetButton from '../BetButton'

const DefaultBottomContainer = ({ 
    theme, 
    themeColor, 
    changeCard, 
    selectCard, 
    progress, 
    closeControls, 
    _changeBuyAmount,
    _setBuyAmount,
    buyAmount,
    disabled,
    increment,
    minBuy,
    maxBuy,
    currencyDesc,
    quickBuyButton1,
    quickBuyButton2,
    quickBuyButton3,
    quickBuyButton4,
    setBuyAmount,
    _placeBet,
    _cancelBet,
    betInvalid,
    cardInvalid,
    game
    }) => {
    return (
        <>
        <div className='controlsTop__container'>
            <div className='main'>
                <CardSelect
                    theme={theme}
                    themeColor={themeColor}
                    onClick={changeCard}
                    selected={selectCard}
                    ></CardSelect>
                <div className='card__title'>
                    {getTranslation("CHOOSE_YOUR_CARD")}
                </div>
                <Progress 
                    percent={progress}
                    size="small"
                    showInfo={false} />
            </div>
            <ImgIcon 
                icon={ICONS.COMMON.CLOSE}
                alt="Close"
                containerClassName="close"
                onClick={closeControls} />
        </div>
        <div className='controlsBottom__controller'>
            <div>
                <BuyAmount
                    minWidth={150}
                    width="100%"
                    height={37}
                    >
                    <InputAddMinus
                        isMobileOrSmall={isMobileOrSmall()}
                        onButtonChange={_changeBuyAmount}
                        onChange={_setBuyAmount}
                        value={buyAmount}
                        disabled={disabled}
                        increment={increment}
                        min={minBuy}
                        max={maxBuy}
                        >
                    </InputAddMinus>
                </BuyAmount>
                <div className={
                    classNames(
                        'currency__label',
                        currencyDesc? 'active' : ''
                    )
                }>
                    {currencyDesc}
                </div>
                <div className='buyAmountCoin__container'>
                    <BuyAmountCoin
                        icon={ICONS.BUY_AMOUNT_COIN[BUY_AMOUNT_COIN_COLOR.BLUE]}
                        onClick={() => _changeBuyAmount(quickBuyButton1)}>{quickBuyButton1}</BuyAmountCoin>
                    <BuyAmountCoin
                        icon={ICONS.BUY_AMOUNT_COIN[BUY_AMOUNT_COIN_COLOR.ORANGE]}
                        onClick={() => _changeBuyAmount(quickBuyButton2)}>{quickBuyButton2}</BuyAmountCoin>
                    <BuyAmountCoin
                        icon={ICONS.BUY_AMOUNT_COIN[BUY_AMOUNT_COIN_COLOR.PURPLE]}
                        onClick={() => _changeBuyAmount(quickBuyButton3)}>{quickBuyButton3}</BuyAmountCoin>
                    <BuyAmountCoin
                        icon={ICONS.BUY_AMOUNT_COIN[BUY_AMOUNT_COIN_COLOR.GREEN]}
                        onClick={() => _changeBuyAmount(quickBuyButton4)}>{quickBuyButton4}</BuyAmountCoin>
                    <BuyAmountCoin
                        icon={ICONS.BUY_AMOUNT_COIN[BUY_AMOUNT_COIN_COLOR.RED]}
                        onClick={() => setBuyAmount(minBuy)} />
                </div>
            </div>
            <div>
                <BetButton 
                    engine={game}
                    theme={theme}
                    min={minBuy}
                    max={maxBuy}
                    btnWidth={153}
                    btnHeight={120}
                    btnBuy={ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUY}
                    btnBuying={ICONS.THEME.DEFAULT.GAME.GRAPHICS_CONTAINER.CONTROLS.PLACE_BET_BUTTON.BUYING}
                    placeBet={_placeBet}
                    cancelBet={_cancelBet}
                    buyAmount={buyAmount}
                    betInvalid={betInvalid}
                    cardInvalid={cardInvalid}
                />
            </div>
        </div>
        </>
    )
}

export default DefaultBottomContainer
