import { ACTIONS } from '../constants/actions';

export default function settingsReducer(settings, { type, payload = null }) {
    switch (type) {
        case ACTIONS.SET_SETTINGS_STORAGE:
            return {
                ...settings,
                ...payload
            };
        case ACTIONS.ENABLE_SETTING:
            return {
                ...settings,
                settingEnable: true,
                historyModalVisible: false,
                helpModalVisible: false,
                recentModalVisible: false,
            };
        case ACTIONS.DISABLE_SETTING:
            return {
                ...settings,
                settingEnable: false
            };
        case ACTIONS.ENABLE_SOUND:
            return {
                ...settings,
                soundEnable: true
            };
        case ACTIONS.DISABLE_SOUND:
            return {
                ...settings,
                soundEnable: false
            };
        case ACTIONS.SET_THEME_COLOR:
            return {
                ...settings,
                themeColor: payload
            };
        case ACTIONS.SET_TEMP_THEME_COLOR:
            return {
                ...settings,
                tempThemeColor: payload
            };
        case ACTIONS.SAVE_SETTING:
            return {
                ...settings,
                save: Date.now()
            };
        case ACTIONS.ENABLE_PANEL:
            return {
                ...settings,
                panelEnable: true
            };
        case ACTIONS.DISABLE_PANEL:
            return {
                ...settings,
                panelEnable: false
            };
        case ACTIONS.OPEN_HISTORY_MODAL:
            return {
                ...settings,
                historyModalVisible: true,
                settingEnable: false,
                helpModalVisible: false,
                recentModalVisible: false,
            };
        case ACTIONS.CLOSE_HISTORY_MODAL:
            return {
                ...settings,
                historyModalVisible: false
            };
        case ACTIONS.OPEN_HELP_MODAL:
            return {
                ...settings,
                helpModalVisible: true,
                historyModalVisible: false,
                settingEnable: false,
                recentModalVisible: false,
            };
        case ACTIONS.CLOSE_HELP_MODAL:
            return {
                ...settings,
                helpModalVisible: false
            };
        case ACTIONS.OPEN_RECENT_MODAL:
            return {
                ...settings,
                recentModalVisible: true,
                helpModalVisible: false,
                historyModalVisible: false,
                settingEnable: false,
            };
        case ACTIONS.CLOSE_RECENT_MODAL:
            return {
                ...settings,
                recentModalVisible: false
            };
        default:
            return settings;
    }
}