import React from 'react'

import { getHashVerifyLink } from '../../common/helpers/common';
import CopyButton from './CopyButton'

const GameHash = ({hash}) => {
const link = () => `${getHashVerifyLink()}/2/${hash}`;

  return hash ? (
      <React.Fragment>
          <div className="hash" style={{ overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'none'}}>
              <a href={hash ? link() : '#'} target="_blank" rel="noreferrer noopener">{hash}</a> 
          </div>
          <CopyButton valueCopy={hash}/>
      </React.Fragment>
    ) : <div>-</div>
}

export default GameHash