import { GAME } from '../constants/game/engine';

export const gameConnected = (dispatch, payload) => 
    dispatch({
        payload,
        type: GAME.GAME_CONNECTION.GAME_CONNECTED
    });

export const gameDisconnected = (dispatch) => 
    dispatch({
        type: GAME.GAME_CONNECTION.GAME_DISCONNECTED
    });

export const gameEnter = (dispatch) => 
    dispatch({
        type: GAME.GAME_CONNECTION.GAME_ENTER
    });

export const betSize = (dispatch, payload) => 
    dispatch({
        payload,
        type: GAME.BUY_AMOUNT
    });

export const betCard = (dispatch, payload) => 
    dispatch({
        payload,
        type: GAME.BUY_CARD
    });


export const setBetDetails = (dispatch, payload) => 
    dispatch({
        payload,
        type: GAME.BET_DETAIL
    });

export const setLastBetDetails = (dispatch, payload) => 
    dispatch({
        payload,
        type: GAME.LAST_BET_DETAIL
    });

export const placeBuy = (dispatch) => {
    dispatch({
        type: GAME.PLACE_BUY
    });
}

export const cancelBuy = (dispatch) => 
    dispatch({
        type: GAME.CANCEL_BUY
    });

export const showWinning = (dispatch, payload) => 
    dispatch({
        payload,
        type: GAME.MESSAGE.WINNING
    })

export const showInsufficientBalance = (dispatch) => 
    dispatch({
        type: GAME.MESSAGE.INSUFFICIENT_BALANCE
    });

export const showAccountLocked = (dispatch) => 
    dispatch({
        type: GAME.MESSAGE.ACCOUNT_LOCKED
    });

export const showServerMaintenanceReminder = (dispatch, payload) => 
    dispatch({
        payload,
        type: GAME.ERROR_REDIRECT.SERVER_MAINTENANCE_REMINDER
    });

export const showCountDown = (dispatch, payload) => 
    dispatch({
        payload,
        type: GAME.MESSAGE.COUNTDOWN
    });

export const clearMessage = (dispatch) => 
    dispatch({
        type: GAME.MESSAGE_CLEAR
    });

export const setGameData = (dispatch, payload) => 
    dispatch({
        payload,
        type: GAME.SET_GAME_DATA
    });

export const setGameStorage = (dispatch, payload) =>
    dispatch({
        payload,
        type: GAME.SET_GAME_STORAGE
    });

export const setImageLoad = (dispatch, payload) => 
    dispatch({
        payload,
        type: GAME.LOAD_IMAGE
    });

export const setAssetsLoaded = (dispatch, payload) => 
    dispatch({
        payload,
        type: GAME.ASSETS_LOADED
    });

export const showBuyActionError = (dispatch) => 
    dispatch({
        type: GAME.MESSAGE.BUY_ACTION_ERROR
    });