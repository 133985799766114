import React from 'react';

import { ICONS } from '../../../common/constants/iconWrapper';
import ImgIcon from '../../common/ImgIcon';
import { getTranslation } from '../../../common/helpers/locale';
import { THEME_CLASS } from '../../../common/constants/keys';
import './Loading.less';

export const Loading = ({
    theme
}) => {
    return (
        <>
        {theme === THEME_CLASS.CAR? (
            <>
            <video autoPlay muted>
                <source src={ICONS.THEME.CAR.GAME.MISC.LOADING.default} type="video/mp4" />
                <p>Your browser doesn't support HTML5 video. Here is a <a href={ICONS.THEME.CAR.GAME.MISC.LOADING.default}>link to the video</a> instead.</p>
            </video>
            </>
        ) : (
            <>
            <ImgIcon 
                icon={ICONS.THEME.DEFAULT.GAME.MISC.LOADING}
                alt={getTranslation("LOADING")}
                size={55}
            />
            <div className='normal__text'>
                {getTranslation("PLEASE_WAIT_WHILE_REDIRECTING")}
            </div>
            </>
        )}
        </>
    )
};