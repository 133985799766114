export const initialResponse = {
    error: null,
    loading: false,
    response: null,
};

export const ACTIONS = {
    FAILURE: 'FAILURE',
    INIT: 'INIT',
    SUCCESS: 'SUCCESS',
};

export function responseReducer(state, { type, payload }) {
    switch (type) {
        case ACTIONS.INIT:
            return {
                error: null,
                loading: true,
                response: null,
            };
        case ACTIONS.SUCCESS:
            return {
                error: null,
                loading: false,
                response: payload,
            };
        case ACTIONS.FAILURE:
            return {
                error: payload,
                loading: false,
                response: null,
            };
        default:
            return initialResponse;
    }
}