import React from 'react'

import classNames from 'classnames'

import { getTranslation } from '../../../../common/helpers/locale'

const Countdown = ({ progress, game }) => {
    return (
        <div className={classNames(
            'choose-suit',
            progress >= 100 ? '' : 'active'
        )}>
            <div>
                {getTranslation("PLACE_YOUR_BETS")}
            </div>
            <div className='progress'>
                <div className="ant-progress ant-progress-line ant-progress-status-success ant-progress-small">
                    <div className="ant-progress-outer">
                        <div className="ant-progress-inner">
                            <div className={classNames(
                                'ant-progress-bg',
                                progress >= 100? '' : 'active'
                            )} style={{ transition:"width "+((game.timeTillStart/1000)-progress/100*(game.timeTillStart/1000))+"s linear" }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Countdown
