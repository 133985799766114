import React, { useEffect } from 'react';

import { THEME_CLASS, THEME_ENUM, FOOTER_ITEM } from '../../../common/constants/keys';
import { useDispatch } from '../../../store/StateProvider';
import { useStorageService } from '../../../services/storageService';
import { setPageUrl } from '../../../common/actions/userActions';
import { getTheme, isDesktop, setIsDesktop } from '../../../common/helpers/common';
import classNames from 'classnames';

export const SiteProvider = (props) => {
    const dispatch = useDispatch();
    const stor = {
        theme: getTheme(THEME_CLASS.CAR),
        themeColor: THEME_ENUM.BLUE,
    };
    useStorageService(false, stor);

    useEffect(() => {
        document.body.classList.add(stor.theme);
        document.body.classList.add(stor.themeColor);
        setIsDesktop();

        setPageUrl(dispatch, FOOTER_ITEM.HOME);
    }, []);

    return <div className={classNames({desktop: isDesktop(), mobile: !isDesktop()})}>{props.children}</div>;
};