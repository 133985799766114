import React from 'react';

import { UpOutlined } from '@ant-design/icons';

import './BackToTop.less';

const BackToTop = ({onClick}) => {
  return (
    <div className="back-to-top">
            <div onClick={onClick}>
                <UpOutlined />
            </div>
    </div>
  )
}

export default BackToTop