import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SETTINGS } from '../../../common/constants/keys';
import Button from '../../common/Button';
import GameLib from '../../../common/helpers/game';
import classNames from 'classnames';
import { GAME } from '../../../common/constants/game/engine';
import { getTranslation } from '../../../common/helpers/locale';
import './BetButton.less';

const BetButton = ({
    engine,
    theme,
    min,
    max,
    btnWidth,
    btnHeight,
    btnBuy,
    btnBuying,
    placeBet,
    cancelBet,
    buyAmount,
    betInvalid,
    cardInvalid,
    ...rest
}) => {
    const [initialDisable, setInitialDisable] = useState(false);
    const [timeOut, setTimeOut] = useState(null);
    const [placeBetButton, setPlaceBetButton] = useState(null);

    const _onEngineChange = () => {
        if (engine.gameState === GAME.GAME_STATE.ENDED) {
            const onGameCrash = () => {
                setInitialDisable(true);
                _initialDisableTimeout();
            }
            onGameCrash();
        } else if (engine.gameState === GAME.GAME_STATE.STARTING) {
            setInitialDisable(false);
        }
    }

    const _initialDisableTimeout = () => {
        setTimeOut(setTimeout(() => {
            setInitialDisable(false);
        }, SETTINGS.GAME.BET_BUTTON.INITIAL_DISABLE_TIME));
    }

    const renderBetButton = () => {
        var _notPlaying = GameLib.notPlaying(engine);
        var _isBetting = GameLib.isBetting(engine);
        var notPlayingOrBetting = _notPlaying || _isBetting;
        var invalidBet = GameLib.canUserBet(betInvalid, cardInvalid) instanceof Error;

        var btnContent = [], btnClasses = [], onClickFunc = null;
        if (notPlayingOrBetting) {
            if (_isBetting) {
                if (engine.gameState !== GAME.GAME_STATE.STARTING) {
                    btnContent.push(
                        <>
                        <div key={ `bc-0-${btnContent.length}` }>
                            {getTranslation("BUYING")}
                        </div>
                        <div className='cancel' key={ `bc-1-${btnContent.length}` }>
                            {getTranslation("CANCEL")}
                        </div>
                        </>
                    );
                    onClickFunc = cancelBet;
                    btnClasses.push('cancel','buying','red');
                }else{
                    btnContent.push(
                        <>
                        <div key={`bc-0-${btnContent.length}`}>
                            {getTranslation("BUYING")}
                        </div>
                        </>
                    );
                    btnClasses.push('disable','buying');
                }
            } else if (initialDisable) {
                btnContent.push(
                    <>
                    <div className={`bc-2`}>
                        {getTranslation("BUY")}
                    </div>
                    <div className={`bc-2 bottom`}>
                        <span>
                            {getTranslation("MIN")}:&nbsp;{min}
                        </span>
                        <span>
                            {getTranslation("MAX")}:&nbsp;{max}
                        </span>
                    </div>
                    </>
                );
                btnClasses.push('disable');
            } else if (_notPlaying) {
                if (invalidBet) {
                    btnContent.push(
                        <>
                        <div className={`bc-3`}>
                            {getTranslation("BUY")}
                        </div>
                        <div className={`bc-3 bottom`}>
                            <span>
                                {getTranslation("MIN")}:&nbsp;{min}
                            </span>
                            <span>
                                {getTranslation("MAX")}:&nbsp;{max}
                            </span>
                        </div>
                        </>
                    );
                    btnClasses.push('disable');
                } else if (engine.placingBet) {
                    btnContent.push(
                        <>
                        <div className={`bc-4`}>
                            {getTranslation("BUY")}
                        </div>
                        <div className={`bc-4 bottom`}>
                            <span>
                                {getTranslation("MIN")}:&nbsp;{min}
                            </span>
                            <span>
                                {getTranslation("MAX")}:&nbsp;{max}
                            </span>
                        </div>
                        </>
                    );
                    btnClasses.push('disable');
                } else {
                    btnContent.push(
                        <>
                        <div className={`bc-5`}>
                            {getTranslation("BUY")}
                        </div>
                        <div className={`bc-2 bottom`}>
                            <span>
                                {getTranslation("MIN")}:&nbsp;{min}
                            </span>
                            <span>
                                {getTranslation("MAX")}:&nbsp;{max}
                            </span>
                        </div>
                        </>
                    );
                    onClickFunc = placeBet;
                }
            }
        }
        setBetButton(btnClasses, onClickFunc, btnContent);
    }

    const setBetButton = (btnClasses, onClickFunc, btnContent) => {
        let btn = (btnClasses.includes("buying"))? btnBuying : btnBuy;
        setPlaceBetButton(
            <Button
                icon={btn}
                containerClassName={classNames(
                    'placeBet__container',
                    btnClasses
                )}
                onClick={onClickFunc}
                width={btnWidth}
                height={btnHeight}
                {...rest}
                >
                    {btnContent}
                </Button>);
    }

    useEffect(() => {
        _onEngineChange();
        renderBetButton();

        return () => {
            clearTimeout(timeOut);
        }
    }, [betInvalid, cardInvalid, engine]);

    return (placeBetButton && placeBetButton)
};

BetButton.prototype = {
    engine: PropTypes.object.isRequired,
    theme: PropTypes.string.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    btnWidth: PropTypes.number.isRequired,
    btnHeight: PropTypes.number.isRequired,
    btnBuy: PropTypes.string.isRequired,
    btnBuying: PropTypes.string.isRequired,
    placeBet: PropTypes.func.isRequired,
    cancelBet: PropTypes.func.isRequired,
    buyAmount: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    betInvalid: PropTypes.any.isRequired,
    cardInvalid: PropTypes.any.isRequired,
};

export default BetButton;