import React, { useState, useEffect } from 'react'

import * as QueryString from 'query-string';
import { Col, Row, Divider  } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/vi';
import 'moment/locale/th';
import 'moment/locale/id';
import 'moment/locale/hi';
import 'moment/locale/ko';

import { useIntlProvider } from '../provider/internationalisation/IntlProvider'
import { useGetGameResult } from '../../services/sabaService'
import { getTranslation } from '../../common/helpers/locale';
import './SabaResult.less';
import ImgIcon from '../common/ImgIcon';
import { ICONS } from '../../common/constants/iconWrapper';
import { getSuitFromString } from '../../common/helpers/common';
import ErrorHandler from '../error_redirect_statuses/ErrorHandler';
import { ERROR, THEME_CLASS } from '../../common/constants/keys';


const SabaResult = ({ location }) => {
    const [result, setResult] = useState({});
    const params = QueryString.parse(location.search);
    const voidedText = `-${getTranslation("VOID").toLocaleUpperCase()}-`;

    const momentLocaleUpdate = (currentLanguage) => {
        switch (currentLanguage) {
            case 'vi-VN':
                moment.locale('vi');
                break;
            case 'th-TH':
                moment.locale('th');
                break;
            case 'zh-CN':
                moment.locale('zh-cn');
                break;
            case 'id-ID':
                moment.locale('id');
                break;
            case 'hi-IN':
                moment.locale('hi');
                break;
            case 'ko-KR':
                moment.locale('ko');
                break;
            default:
                moment.locale('en-us');
                break
        }
    }

    const getWinSuit = () => {
        const suit = result.status === "Voided" ? voidedText : getTranslation(getSuitFromString(result.result?.suit))
        if(suit) return suit.toLocaleUpperCase();
        

        return suit;
    }

    const getWinColor = () => {
        const color = result.status === "Voided" ? voidedText : getTranslation(result.result?.color.toLocaleUpperCase())
        if(color) return color.toLocaleUpperCase();

        return color
    }

    const getWinCarImage = () => {
        const suit_texture_name = result.status === "Voided" ? "VOID" : getSuitFromString(result.result?.suit);

        return <ImgIcon
                    containerClassName="car"
                    icon={result.status === "Voided" ? 
                        ICONS.COMMON.VOIDED_CAR_IMAGE : 
                        ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.CAR[suit_texture_name]}
                    alt="car"
                    width="25%"
                />
    }
    
    useIntlProvider(true, { lang: params.LangCode });
    const { loading, response, error } = useGetGameResult(params);

    useEffect(()=>{
        if(response){
            setResult({
                result: response.data.result, 
                startDate: response.data.startDate, 
                status: response.data.status, 
                gameId: moment.utc(response.data.startDate).format('yyyyMMDD')+response.data.gameSessionIndex })
        }
    },[response])

    useEffect(()=>{
        momentLocaleUpdate(params.LangCode);
    },[params.LangCode])

    return error ? 
    (
        <ErrorHandler 
            topBar={false} 
            theme={THEME_CLASS.CAR} 
            language={params.LangCode} 
            status={ ERROR.ERROR } />
    )
    : (
        <div className="results__container saba-result">
           <Col className="game-result">
               <div className="game-result__content">
                   {
                       !loading && !_.isEmpty(result) && (
                            <>
                                <h2 className="game-result__id">{ getTranslation("GAME_ID") } &nbsp;{result.gameId}</h2>
                                <Row justify="space-around" align="middle" className="game-result__result">
                                    <Col span="12">
                                        { getWinCarImage() }
                                    </Col>
                                    <Col span="12">
                                        <label className="game-result__win-text"> { getTranslation('THE_WINNER_IS') }</label> <br />
                                        <label className="game-result__win-suit"> { getWinSuit() } </label> <br />
                                        <label className="game-result__win-color"> { getWinColor() } </label>
                                    </Col>
                                </Row>
                                <Divider className="divider"/>
                                <div className="game-result__datetime">
                                    <span>{`${getTranslation("DATE")}: ${moment.utc(result.startDate).format('DD/MM/yyyy')}`}</span> <br />
                                    <span>{`${getTranslation("TIME")}: ${moment.utc(result.startDate).format('HH:mm:ss')} GMT`}</span>
                                    &nbsp;&nbsp;
                                    <span className="game-result__date-ago">{moment.utc(result.startDate).fromNow()}</span>
                                </div>
                            </>
                       )
                   }
                    
               </div>
           </Col>
        </div>
    )
}

export default SabaResult
