import React from 'react'
import { getTranslation } from '../../../common/helpers/locale'

const BuyError = () => {
    return (
        <>
        <div className='img'>
                <div className='top-up flickering'>
                    {getTranslation("ERROR")}
                </div>
            </div>
            <div>
                <div className="content">
                    {getTranslation("TRANSACTION_ERROR")}
                </div>
            </div>
        </>
    )
}

export default BuyError
