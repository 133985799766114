import React from 'react';

import { ICONS } from '../../../common/constants/iconWrapper';
import ImgIcon from '../../common/ImgIcon';
import { getTranslation } from '../../../common/helpers/locale';
import { THEME_CLASS } from '../../../common/constants/keys';
import BorderContainer from '../../common/BorderContainer';

export const Error = ({
    theme=THEME_CLASS.CAR,
    themeColor
}) => {
    return (
        <>
        { theme === THEME_CLASS.CAR? (
            <>
            <BorderContainer 
                centerAlign={true}
                containerClassName="icon-error"
                centerImg={ICONS.THEME.CAR.GAME.MISC.ERROR}
                alt={getTranslation("ERROR")}
                leftRightWidth={0}
                minWidth={179}
                height={122}
            >
                {getTranslation("ERROR")}
            </BorderContainer>
            <div className='normal__text'>
                {getTranslation("SORRY")}
            </div>
            <div className='big__text'>
                {getTranslation("PAGE_NOT_FOUND")}
            </div>
            </>
        ) : (
            <>
            <ImgIcon 
                icon={ICONS.THEME.DEFAULT.GAME.MISC.ERROR}
                alt={getTranslation("ERROR")}
                width={245}
                height={60}
            />
            <div className='normal__text'>
                {getTranslation("SORRY")}
            </div>
            <div className='big__text'>
                {getTranslation("PAGE_NOT_FOUND")}
            </div>
            </>
        )}
        </>
    );
};