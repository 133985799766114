import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImgIcon from './ImgIcon';
import { getThemeGameControls } from '../../common/helpers/theme';
import './InputAddMinusCarProgressBar.less';
import { THEME_CLASS } from '../../common/constants/keys';

const InputAddMinusCarProgressBar = ({
    bars=10,
    themeColor,
    value,
    min,
    max,
}) => {
    const [progressBar, setProgressBar] = useState('');

    useEffect(() => {
        if (!themeColor) return;
        const themes = getThemeGameControls(THEME_CLASS.CAR, themeColor);
        let amt = Math.floor(value / (max-min) * bars);
        let display = [];
        for (var i = 0; i < bars; i++)
        {
            let bar = (i < amt)? (
                <ImgIcon
                    icon={themes.INPUT_PROGRESS_BAR}
                    alt={amt+"/"+bars}
                    width={14.64}
                    height={17.84}
                    containerClassName="input__progress-bar-fill"
                    />
            ) : (
                <ImgIcon
                    icon={themes.INPUT_PROGRESS_BAR_EMPTY}
                    alt={amt+"/"+bars}
                    width={15}
                    height={18}
                    containerClassName="input__progress-bar-empty"
                    />
            );
            display.push(bar);
        }
        setProgressBar(display);
    }, [value, themeColor]);
    
    return (
        <div className='car-input-progress'>
            {progressBar}
        </div>
    )
}

InputAddMinusCarProgressBar.prototype = {
    bars: PropTypes.number.isRequired,
    themeColor: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
    min: PropTypes.number,
    max: PropTypes.number
};

export default InputAddMinusCarProgressBar;