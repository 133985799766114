import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './input-add-minus.less';

const InputAddMinus = ({
    isMobileOrSmall,
    onButtonChange,
    onChange,
    value,
    disabled=false,
    increment,
    min,
    max,
    readOnly=true,
    minusBtnFirst=false,
    customInput: CustomInput = null
}) => {
    const timerInterval = 500;
    const [time, setTime] = useState(timerInterval+1);
    const [enableTimer, setEnableTimer] = useState(false);
    const [minusActive, setMinusActive] = useState(false);
    const [addActive, setAddActive] = useState(true);
    const [add, setAdd] = useState(false);

    const update = (check=undefined) => {
        min = (min)? min : 1;
        if (value <= min) {
            setMinusActive(false);
            if (check !== undefined && check < 0) return false;
        }else{
            setMinusActive(true);
        }
        
        max = (max)? max : 9999999999;
        if (value >= max) {
            setAddActive(false);
            if (check !== undefined && check > 0) return false;
        }else{
            setAddActive(true);
        }
        if (check !== undefined) return true;
    };

    const task = () => {
        if (!update(add)) {
            return false;
        }
        onButtonChange(add);
    };

    const timer = () => {
        if (!enableTimer) return;
        if (disabled) return;
        task();
        setTimeout(() => {
            setTime(time / 2);
        }, time);
    }

    const start = (e, val) => {
        if ((isMobileOrSmall && e.type === "mousedown") || (!isMobileOrSmall && e.type === "touchstart")) return;
        setAdd(val);
        setEnableTimer(true);
        setTime(timerInterval);
    }

    const stop = () => {
        setEnableTimer(false);
    }

    useEffect(() => {
        update();
    }, [value]);

    useEffect(() => {
        timer();
    }, [time]);

    let btnAdd = (
        <div className={ 'add' + ((addActive)? ' active' : '') }  onTouchStart={ (e) => { if (addActive) start(e, increment); } } onTouchEnd={ stop } onMouseDown={ (e) => { if (addActive) start(e, increment); } } onMouseUp={ stop } onMouseLeave={ stop }>
            {/* + */}
        </div>
    );

    let btnMinus = (
        <div className={ 'minus' + ((minusActive)? ' active' : '') } onTouchStart={ (e) => { if (minusActive) start(e, -increment); } } onTouchEnd={ stop } onMouseDown={ (e) => { if (minusActive) start(e, -increment); } } onMouseUp={ stop } onMouseLeave={ stop }>
            {/* - */}
        </div>
    );

    return (<div className='input-add-minus'>
        {minusBtnFirst && btnMinus}
        {!minusBtnFirst && btnAdd}
        {CustomInput ? (
            <CustomInput />
        ) : (
            <input type='text' onChange={ onChange } value={ value } disabled={ disabled } readOnly={ readOnly } />
        )}
        {!minusBtnFirst && btnMinus}
        {minusBtnFirst && btnAdd}
    </div>);
}

InputAddMinus.prototype = {
    isMobileOrSmall: PropTypes.bool.isRequired,
    onButtonChange: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
    disabled: PropTypes.bool,
    increment: PropTypes.number.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    readOnly: PropTypes.bool,
    minusBtnFirst: PropTypes.bool,
    customInput: PropTypes.elementType
};

export default InputAddMinus;