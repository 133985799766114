import React from 'react'

import { Col, Row } from 'antd';
import _ from 'lodash';
import classNames from 'classnames';

import { getTranslation } from '../../../../common/helpers/locale';
import { CAR_COLOR } from '../../../../common/constants/keys';
import ImgIcon from '../../../common/ImgIcon';

const BetOptions = ({ gameContainerThemes, chips, handleBetOptionClick, selectOption, getChipValueByColor, suitOdds, colorOdds, suitDisable, colorDisable }) => {
    return (
        <div className="bet-options__container">
            <Row justify="space-between" align="middle">
                <Col className="bet-options__suit-container">
                    <Row justify="space-between" align="middle">
                    {
                        _.map(gameContainerThemes.SUITS, (suit, index)=>{
                            var totalAmount = _.find(chips, (chip, chipIndex) => {return chipIndex === index});

                            if(index !== "BACK"){
                                return  (
                                <Col 
                                    className={classNames("bet-options__background bet-options__suit", 
                                        {active: selectOption === index || totalAmount},
                                        {disabled: _.isEmpty(totalAmount) && suitDisable }
                                        )} key={index} 
                                    onClick={()=>handleBetOptionClick(index)}> 
                                    <ImgIcon icon={suit} width={25} height={25}/>
                                    <div className="bet-chips__display">
                                        {totalAmount ? <div className="bet-chips__total-amount"> {totalAmount.amount} </div> : null}
                                        {
                                            _.map(chips, (chip, chipIndex) => {
                                                var chips = [];
                                                if(chipIndex !== index){
                                                    return;
                                                }

                                                for(var key in chip){
                                                    if(key === 'amount'){
                                                        continue;
                                                    }
                                                    for(var i = 0; i < chip[key]; i++){
                                                        chips.push(
                                                            <ImgIcon 
                                                                icon={gameContainerThemes.CHIPS[key.toLocaleUpperCase()]} 
                                                                width={25} 
                                                                children={
                                                                    <span className="bet-chips__amount">
                                                                        {getChipValueByColor(key.toLocaleUpperCase())}
                                                                    </span>
                                                                }
                                                                key={key+i}
                                                                containerStyle={{bottom: `${-15 + (chips.length * 2)}px`}}/>
                                                                
                                                            );
                                                    }
                                                }
                                                return chips;
                                            })
                                        }
                                    </div>
                                </Col>)
                            }
                        })
                    }     
                    </Row>
                    <Row justify="center" align="middle" className="bet-options__payout-info">
                        {getTranslation("PAYOUT").toLocaleUpperCase()} &nbsp; 1 : {suitOdds}
                    </Row>
                </Col>
                <Col span={8} className="bet-options__color-container">
                    <Row justify="space-between" align="middle">
                        {
                            _.map(CAR_COLOR, (color) => {
                                var totalAmount = _.find(chips, (chip, chipIndex) => {return chipIndex === color});

                                return (
                                        <Col className={classNames("bet-options__background bet-options__color",
                                            {
                                                "bet-options__black": color === CAR_COLOR.BLACK
                                            },
                                            {
                                                "bet-options__red" : color === CAR_COLOR.RED
                                            },
                                            {
                                                "active" : color === selectOption || totalAmount
                                            },
                                            {
                                                "disabled": _.isEmpty(totalAmount) && colorDisable 
                                            })} onClick={()=>{handleBetOptionClick(color)}}
                                            key={color} >
                                            <div className="bet-chips__display">
                                                {totalAmount ? <div className="bet-chips__total-amount"> {totalAmount.amount} </div> : null}
                                                {
                                                    _.map(chips, (chip, chipIndex) => {
                                                        if(chipIndex !== color){
                                                            return;
                                                        }
                                                        var chips = [];
                                                        for(var key in chip){
                                                            if(key === 'amount'){
                                                                continue;
                                                            }
                                                            for(var i = 0; i < chip[key]; i++){
                                                                chips.push(
                                                                    <ImgIcon 
                                                                        icon={gameContainerThemes.CHIPS[key.toLocaleUpperCase()]} 
                                                                        width={25} 
                                                                        children={
                                                                            <span className="bet-chips__amount">
                                                                                {getChipValueByColor(key.toLocaleUpperCase())}
                                                                            </span>
                                                                        }
                                                                        containerStyle={{bottom: `${-15 + (chips.length * 2)}px`}}
                                                                        key={key+i} />
                                                                    );
                                                            }
                                                        }

                                                        return chips;
                                                    })
                                                }
                                            </div>
                                        </Col>
                                )
                            })
                        }
                    </Row>
                    <Row justify="center" align="middle" className="bet-options__payout-info">
                    {getTranslation("PAYOUT").toLocaleUpperCase()} &nbsp; 1 : {colorOdds}
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default BetOptions
