import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { getHashVerifyLink } from '../../common/helpers/common';
import { getTranslation } from '../../common/helpers/locale';
import './Help.less';

const Help = ({
    theme,
    themeColor,
    engine,
    isModal = false
}) => {
    const [info, setInfo] = useState([]);
    const helps = [
        {
            title: getTranslation("WHAT_IS_CARD_RACING"),
            content: [
                {
                    font: 'regular',
                    data: getTranslation("WHAT_IS_CARD_RACING_CONTENT")
                }
            ]
        },
        {
            title: getTranslation("HOW_TO_PLAY"),
            content: [
                {
                    font: 'regular',
                    data: getTranslation("HOW_TO_PLAY_CONTENT")
                },
                {
                    font: 'italic',
                    data: getTranslation("HOW_TO_PLAY_CONTENT_2")
                }
            ]
        },
        {
            title: getTranslation("HOW_DO_I_KNOW_FAIR"),
            content: [
                {
                    font: 'regular',
                    data: getTranslation("HOW_DO_I_KNOW_FAIR_CONTENT", {link: `${getHashVerifyLink()}`})
                },
            ]
        },
        {
            title: getTranslation("HOW_DO_I_KNOW"),
            content: [
                {
                    font: 'regular',
                    data: getTranslation("HOW_DO_I_KNOW_CONTENT", {rate: engine.data.storage.suitOdds})
                },
                {
                    font: 'italic',
                    data: getTranslation("HOW_DO_I_KNOW_CONTENT_2", {amount: 5 * parseFloat(engine.data.storage.suitOdds)})
                }
            ]
        },
        {
            title: getTranslation("WHAT_HAPPENS"),
            content: [
                {
                    font: 'regular',
                    data: getTranslation("WHAT_HAPPENS_CONTENT")
                }
            ]
        }
    ]
    
    useEffect(() => {
        var rows = helps.map((game, i) => {
            var contents = game.content.map((gam, i) => {
                let cls = gam.font;
                return (
                    <div className={cls} key={i}>
                        <span dangerouslySetInnerHTML={{__html: gam.data}}></span>
                    </div>
                )
            })
            return (
                <div className='item' key={i}>
                    <div className='title'>
                        {game.title}
                    </div>
                    <div className={classNames({main: !isModal}, 'content')}>
                        {contents}
                    </div>
                </div>
            )
        });
        setInfo(rows);
    }, []);

    return (
        <div className='results__container help'>
            {
                isModal ? info: 
                (
                    <>
                    <div className='head'>
                        {getTranslation("HELP")}
                    </div>
                    <div className='main'>
                        <div className='container'>
                            {info}
                        </div>
                    </div>
                    </>
                )
            }
            
        </div>
    );
};

export default Help;