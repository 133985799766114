import React, { useState } from 'react';
import { ROUTES } from '../../common/constants/routing';
import { ERROR } from '../../common/constants/keys';

import { Loading } from './loading/Loading';
import { Maintenance } from './maintenance/Maintenance';
import { Error } from './error/Error';
import Header from '../app/layouts/Header';
import { getTranslation } from '../../common/helpers/locale';
import { useIntlProvider } from '../provider/internationalisation/IntlProvider';

const ErrorHandler = ({ 
    topBar=false, 
    theme, 
    language='en-US',
    status, 
    location 
}) => {
    let path = (!location) ? '' : location.pathname;
    const [initDone, setInitDone] = useState(false);

    const handler = (res) => {
        setInitDone(true);
    }
    useIntlProvider(true, { lang: language }, handler);

    return (
        <>
        { initDone && topBar? (
            <div className="wrapper">
                <div className='layout layout_no-footer'>
                    <div className="layout__header">
                        <Header 
                            title={getTranslation("CARD_RACING")}
                            backUrl={'/'}
                        />
                    </div>
                    <div id="content" className='layout__content-with-header-only'>
                        <div className="error-redirect-statuses__container">
                            { (status === ERROR.LOADING) && <Loading theme={theme} /> }
                            { (status === ERROR.MAINTENANCE || path === ROUTES.MAINTENANCE) && <Maintenance theme={theme} /> }
                            { (status === ERROR.ERROR || path === ROUTES.ERROR) && <Error theme={theme} /> }
                        </div>
                    </div>
                </div>
                <div id="handheld-detection"></div>
            </div>
        ) : (
            <div className="error-redirect-statuses__container">
                { (status === ERROR.LOADING) && <Loading theme={theme} /> }
                { (status === ERROR.MAINTENANCE || path === ROUTES.MAINTENANCE) && <Maintenance theme={theme} /> }
                { (status === ERROR.ERROR || path === ROUTES.ERROR) && <Error theme={theme} /> }
            </div>
        )}
        </>
    );
};

export default ErrorHandler;