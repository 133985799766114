import React, { useEffect, useState, useRef } from 'react';
import Graphics from '../../../engine/Graphics';
import './GraphicsMain.less';
import { useStore } from '../../../store/StateProvider';
import { GAME } from '../../../common/constants/game/engine';
import classNames from 'classnames';
import { showCountDown } from '../../../common/actions/gameActions';
import GameModal from '../../common/GameModal';
import ImgIcon from '../../common/ImgIcon';
import { ICONS } from '../../../common/constants/iconWrapper';
import { getTranslation } from '../../../common/helpers/locale';
import { SETTINGS } from '../../../common/constants/keys';
import Event from '../../../common/helpers/events';
import { isDesktop } from '../../../common/helpers/common';
import { TRAFFIC_LIGHT_SIZE } from '../../../common/constants/misc';

const GraphicsMain = ({
    theme,
    themeColor
}) => {
    const graphics = Graphics.getInstance();
    const parentRef = useRef(null);
    const canvasRef = useRef(null);
    const [store, dispatch] = useStore();
    const [active, setActive] = useState(false);
    const [messageModal, setMessageModal] = useState(null);
    const [countDownActive, setCountDownActive] = useState(false);

    useEffect(() => {
        graphics.start(canvasRef.current, parentRef.current);

        return () => {
            graphics.stop();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!theme || !themeColor) return;
        graphics.changeTheme(theme, themeColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [theme, themeColor]);

    useEffect(() => {
        if (!("gameState" in store.game)) return;
        let { gameState } = store.game;
        if (gameState === GAME.GAME_STATE.STARTED) {
            const countdown = Date.now() - store.game.startTime;
            if (countdown < SETTINGS.GAME.COUNTDOWN_TIME) {
                setActive(true);
                if (countDownActive) {
                    setCountDownActive(false);
                    activateCountDown(1, 2000);
                    setTimeout(() => {
                        Event.broadcast(GAME.PLAY_SOUND, {
                            name: "COUNTDOWN",
                            loop: false,
                            cancel: "",
                            simultaneous: 1
                        });
                    }, 2000);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.game.gameState]);

    useEffect(() => {
        if (!store.settings) return;
        if ("panelEnable" in store.settings) {
            setActive(!store.settings.panelEnable);
            setCountDownActive(store.settings.panelEnable);
        }
    }, [store.settings]);

    useEffect(() => {
        if (!("currentResult" in store.game)) return;
        setActive(true);
        setMessageModal(<></>);

        return () => {
            setMessageModal(<></>);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.game.currentResult]);

    const activateCountDown = (step, interval=1000) => {
        if (step > 4 || !store.game.isConnected) {
            return false;
        }
        setTimeout(() => {
            var payload = { step: step };
            showCountDown(dispatch, payload);
            const DEVICE = isDesktop() ? 'desktop' :  'mobile';
            const showDisplay = () => {
                switch (step) {
                    case 1:
                        return (
                            <>
                            <ImgIcon
                            icon={ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.TRAFFIC.STEP_1}
                            alt={step}
                            width={TRAFFIC_LIGHT_SIZE.WIDTH[DEVICE]}
                            height={TRAFFIC_LIGHT_SIZE.HEIGHT[DEVICE]}
                            />
                            <div className={classNames('txt', DEVICE)}>
                                {4-step}
                            </div>
                            </>
                        );
                    case 2:
                        return (
                            <>
                            <ImgIcon
                            icon={ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.TRAFFIC.STEP_2}
                            alt={step}
                            width={TRAFFIC_LIGHT_SIZE.WIDTH[DEVICE]}
                            height={TRAFFIC_LIGHT_SIZE.HEIGHT[DEVICE]}
                            />
                            <div className={classNames('txt', DEVICE)}>
                                {4-step}
                            </div>
                            </>
                        );
                    case 3:
                        return (
                            <>
                            <ImgIcon
                            icon={ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.TRAFFIC.STEP_3}
                            alt={step}
                            width={TRAFFIC_LIGHT_SIZE.WIDTH[DEVICE]}
                            height={TRAFFIC_LIGHT_SIZE.HEIGHT[DEVICE]}
                            />
                            <div className={classNames('txt', DEVICE)}>
                                {4-step}
                            </div>
                            </>
                        );
                    case 4:
                        return (
                            <>
                            <ImgIcon
                            icon={ICONS.THEME.CAR.GAME.GRAPHICS_CONTAINER.GRAPHICS_MAIN.TEXTURES.TRAFFIC.STEP_GO}
                            alt={step}
                            width={TRAFFIC_LIGHT_SIZE.WIDTH[DEVICE]}
                            height={TRAFFIC_LIGHT_SIZE.HEIGHT[DEVICE]}
                            />
                            <div className={classNames('txt green', DEVICE)}>
                                {getTranslation("GO")}!
                            </div>
                            </>
                        );
                    default:
                        return ('');
                }
            }
            var display = showDisplay();
            setMessageModal(
                <GameModal
                    containerClassName='countdown'
                    closable={false}
                    >
                        {display}
                    </GameModal>
            );

            step++;
            activateCountDown(step);
        }, interval);
    }

    return (
        <div className='graphicsInner__container'>
            <div ref={parentRef} className={
                classNames(
                    'graphicsInner__main',
                    active ? 'active' : ''
                )
            }>
                {messageModal && messageModal}
                <div className='canvas'>
                    <canvas ref={canvasRef} className='canvas'></canvas>
                </div>
            </div>
        </div>
    );
};

export default GraphicsMain;