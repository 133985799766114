import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { useStore } from '../../../../store/StateProvider';
import './GraphicsTopContainer.less';
import { getThemeGameContainer } from '../../../../common/helpers/theme';
import { THEME_CLASS } from '../../../../common/constants/keys';
import { getTranslation } from '../../../../common/helpers/locale';
import { GAME } from '../../../../common/constants/game/engine';
import CarTopContainer from './CarTopContainer';
import CarDesktopTopContainer from './CarDesktopTopContainer';
import { isDesktop } from '../../../../common/helpers/common';
import DesktopTopContainer from './CarDesktopTopContainer';
import './GraphicsTopContainer.less';

const GraphicsTopContainer = ({
    theme,
    themeColor
}) => {
    const [store] = useStore();
    const [sessionId, setSessionId] = useState(0);
    const [balance, setBalance] = useState(0);
    const [soundEnable, setSoundEnable] = useState(false);
    const [panel, setPanel] = useState('');
    const [funPlay, setFunPlay] = useState('');

    useEffect(() => {
        const themes = getThemeGameContainer(theme, themeColor);

        if (theme === THEME_CLASS.DEFAULT) {
            setPanel(<CarDesktopTopContainer themes={themes} sessionId={sessionId} balance={balance} soundEnable={soundEnable} />);
        } else if (theme === THEME_CLASS.CAR || theme === THEME_CLASS.OKBET) {
            if (!themeColor) return;

            if(isDesktop()){
                setPanel(<DesktopTopContainer sessionId={sessionId} balance={balance} funPlay={funPlay}/>)
            }
            else
                setPanel(<CarTopContainer themes={themes} sessionId={sessionId} balance={balance} />)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, balance, theme, themeColor]);

    useEffect(() => {
        if(store.game.gameState === GAME.GAME_STATE.STARTING){
            setSessionId('-');    
        }
        else{
            let sessionId = store.game.sessionIndex;
            let date = moment.utc(store.game.gameCreated).format('yyyyMMDD');
            setSessionId(date+''+sessionId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.game.gameState]);

    useEffect(() => {
        if (!store.hasOwnProperty("game")) return;
        if ("balance" in store.game) setBalance(store.game.balance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.game]);

    useEffect(() => {
        if (!store.settings) return;
        if ("soundEnable" in store.settings) setSoundEnable(store.settings.soundEnable);
    }, [store.settings]);

    useEffect(() => {
        if (!store.game || !("anonymous" in store.game.storage)) return;
        let { anonymous } = store.game.storage;
        if (anonymous) {
            setFunPlay(
                <div className='notice-fun-play'>
                    {getTranslation("FUN_PLAY_MODE")}
                </div>
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.game.storage.anonymous]);

    return (
        <div className="graphicsTop__container">
            { panel }
            {funPlay && !isDesktop() && funPlay}
        </div>
    )
}

export default GraphicsTopContainer;