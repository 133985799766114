import React, { useEffect, useState } from 'react'
import { API } from '../../../common/constants/api';
import { STORAGE } from '../../../common/constants/keys';
import { HTTP_HEADER } from '../../../common/constants/misc';
import { isChina as checkStorageIsChina } from '../../../common/helpers/common';
import { deleteFromLocalStorage, setLocalStorage } from '../../../common/helpers/localStorage';

export const ImageProvider = (props) => {
    const [isChina, setIsChina] = useState(null);
    const CdnImage = React.lazy(() => import('./CdnImage'));
    const LocalImage = React.lazy(() => import('./LocalImage'));

    const checkIsChina = () => {
        try {
            var req = new XMLHttpRequest();
            req.open('GET', API.HOST, false);
            req.send(null);
            if (req.getResponseHeader(HTTP_HEADER.CHINA_IDENTIFIER.toLowerCase())) {
                setLocalStorage(STORAGE.IS_CHINA, true);
                setIsChina(true);
            } else {
                deleteFromLocalStorage(STORAGE.IS_CHINA);
                setIsChina(false);
            }
        } catch (err) {
            setIsChina(checkStorageIsChina());
        }
    }

    useEffect(() => {
        checkIsChina();
    }, []);

    // ============================================
    // RENDER
    // ============================================

    return isChina !== null &&
        (<>
            <React.Suspense fallback={<></>}>
                {isChina ? <CdnImage /> : <LocalImage />}
            </React.Suspense>
            {props.children}
        </>);
}