import { useEffect, useState } from 'react';
import { setGameStorage } from '../common/actions/gameActions';
import { setSettingsStorage } from '../common/actions/settingsActions';
import { STORAGE, STORAGE_SETTINGS } from '../common/constants/keys';
import { useDispatch } from '../store/StateProvider';
import PropTypes from 'prop-types';

export const useStorageService = (initDone, stor) => {
    const dispatch = useDispatch();
    const [storage, setStorage] = useState({});
    const [settings, setSettings] = useState({});
    const letStorage = () => {
        for (var x in stor) {
            var which = '';
            for (var s in STORAGE) {
                if (STORAGE[s] === x) {
                    which = 'storage';
                }
            }
            for (var s in STORAGE_SETTINGS) {
                if (STORAGE_SETTINGS[s] === x) {
                    which = 'settings';
                }
            }
            if (which === 'storage') {
                storage[x] = stor[x];
                setStorage({
                    ...storage
                });
            } else if (which === 'settings') {
                settings[x] = stor[x];
                setSettings({
                    ...settings
                });
            }
        }
    }

    useEffect(() => {
        if (!settings) return;
        setSettingsStorage(dispatch, settings);
    }, [settings]);

    useEffect(() => {
        if (!storage) return;
        setGameStorage(dispatch, {storage});
    }, [storage]);

    useEffect(() => {
        letStorage();
    }, [, initDone]);
}

useStorageService.PropTypes = {
    initDone: PropTypes.bool
}