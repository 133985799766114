import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from './Button';
import { getThemeCommon } from '../../common/helpers/theme';
import './Pagination.less';
import ImgIcon from './ImgIcon';

const Pagination = ({
    theme,
    themeColor,
    onClick,
    page,
    total
}) => {
    const themes = getThemeCommon(theme, themeColor);
    const [prevActive, setPrevActive] = useState(false);
    const [nextActive, setNextActive] = useState(true);

    useEffect(() => {
        var prevBool = (page <= 1)? false : true;
        var nextBool = (page >= total)? false : true;
        if (prevActive !== prevBool) {
            setPrevActive(prevBool);
        }
        if (nextActive !== nextBool) {
            setNextActive(nextBool);
        }
    }, [, page, total]);

    const clicked = (pg) => {
        let pgNow = page + pg;
        if (pgNow < 1 || pgNow > total) return;
        onClick(pgNow);
    }

    return (
        <div className='pagination__container'>
            <Button
                containerClassName={classNames(
                    'prev',
                    prevActive? 'active' : ''
                )}
                onClick={() => clicked(-1) }
                icon={themes.PAGINATION_BACKGROUND}
                size={30}
                >
                    <ImgIcon
                        icon={themes.LEFT_ARROW}
                        alt="<"
                        size={15}
                        />
                </Button>
            <div>
                {page}&nbsp;/&nbsp;{total}
            </div>
                <Button
                    containerClassName={classNames(
                        'next',
                        nextActive? 'active' : ''
                    )}
                    onClick={() => clicked(1) }
                    icon={themes.PAGINATION_BACKGROUND}
                    size={30}
                    >
                    <ImgIcon
                        icon={themes.RIGHT_ARROW}
                        alt=">"
                        size={15}
                        />
                    </Button>
        </div>
    )
}

Pagination.prototype = {
    theme: PropTypes.string.isRequired,
    themeColor: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
}

export default Pagination;