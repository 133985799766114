import React from 'react'

import { Col, Row } from 'antd'

import { getTranslation } from '../../../../common/helpers/locale'

const CarDesktopTopContainer = ({ sessionId, balance, funPlay }) => {
    return (
        <Row justify='space-between' className="desktop__graphic-top-container">
            <Col className="desktop__graphic-top-container__outer-section">
                <div className="desktop__graphic-top-container__inner-section">
                    <label className="title">{getTranslation("GAME_ID")}</label>
                    <label className="content">{ sessionId }</label>
                </div>
            </Col>
            <Col>{ funPlay }</Col>
            <Col className="desktop__graphic-top-container__outer-section">
                <div className="desktop__graphic-top-container__inner-section">
                    <label className="title">{getTranslation("BALANCE")}</label>
                    <label className="content">{Number(balance).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label>
                </div>
            </Col>
        </Row>
    )
}

export default CarDesktopTopContainer
